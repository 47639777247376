import { Button, Checkbox, Grid, InputLabel, Stack, Switch, Typography } from '@mui/material'
import { ErrorMessage, Field, FieldArray, Formik, connect } from 'formik'
import React, { useState } from 'react'
import FormikInput from '../../shared/material-ui-formik/FormikInput'
import FormikSelect from '../../shared/material-ui-formik/FormikSelect'
import { useTranslation } from 'react-i18next'
import { EditCancellationPolicyValidator } from '../../shared/validations/AdminValidator'
import FormikInput1 from '../../shared/material-ui-formik/FormikInput1'
import ShowInputError from '../../shared/components/ShowInputError'
import { createRecord } from '../../apis/services/CommonApiService'
import { ApiEndPoints } from '../../apis/ApiEndPoints'
import { toast } from 'react-toastify'
import Loader from '../../layout/Loader'
import { useDispatch } from 'react-redux'
import { refreshPolicySuccess } from '../../Reducer/roomSlice'


const AddCancellation = (props) => {


  const criteriasObj = {
    time_period: 1,
    time_period_value: 0,
    refund_type:1,
    refund_type_value:0,
  };
  
  const { t, i18n } = useTranslation();
  const initialState = {
    name: "",
    description: "",
    cancellation_policy_criterias: [criteriasObj]
  };

  const [loading, setLoading] = useState(false);
  const [cancellationCri, setCancellationCri] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    // eslint-disable-next-line no-console
    setLoading(true);
    let bodyFormData = {
      name: values?.name,
      description: values?.description,
      cancellation_policy_criterias: cancellationCri? values?.cancellation_policy_criterias : [],
    };
    const result = await createRecord(
      bodyFormData,
      ApiEndPoints.GET_CANCELLATION_POLICY
    );
    if (result?.status === 200) {
      toast.success(t(result.message));
      props?.handleCloseDialog();
      setLoading(false);
      dispatch(refreshPolicySuccess());
    } else {
      toast.error(t(result.message));
      setLoading(false);

    }
  };


  return (
  <>
  {loading && <Loader />}
    <div className='p-2'>

      <Formik
        initialValues={initialState}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={()=>EditCancellationPolicyValidator(t)}
      >
          {(props) => {
              const { handleSubmit } = props;
              return (
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={12}>
                        
                        <Field
                          label={t("Name")}
                          name="name"
                          id="name"
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name="name"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        
                        <Field
                          label={t("Description")}
                          name="description"
                          id="description"
                          type="textarea"
                          component={FormikInput1}
                          multiline
                          noMaxLength
                          borderNone
                          rows={4}
                        />
                        <ErrorMessage
                          name="description"
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Typography
                      id="font-600"
                        style={{
                          marginBottom: "11px",
                          color: "rgb(30,30,30)",
                          fontSize: "16px",
                        }}
                      >
                        {t("Cancellation Criteria")}
                      </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch
                  // {...label}
                  checked={cancellationCri}
                  onChange={(e)=>e.target.checked?setCancellationCri(true):setCancellationCri(false)}
                />
                <Typography>
                  {cancellationCri ? t("Active") : t("Not Active (100% Refund)")}
                </Typography>
              </Stack>
            </Grid>
                      {cancellationCri && (<FieldArray name="cancellation_policy_criterias">
                      {({push, remove}) => (
                        <>
                        {
                          props?.values?.cancellation_policy_criterias?.map((_, index)=>(
                            <React.Fragment key={index}>
                              {index !== 0 && (
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginRight: "20px",
                                        marginBottom: "20px",
                                        borderTop: "2px solid",
                                        marginTop: "20px",
                                      }}
                                    ></div>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                     <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Time Period")}
                                    </Typography>
                                    <Field
                                    label={t("Time Period")}
                                    name={`cancellation_policy_criterias.${index}.time_period`}
                                    id={`cancellation_policy_criterias.${index}.time_period`}
                                    component={FormikSelect}
                                    options={[
                                      {
                                      title: t("Hours"),
                                      value: 1,
                                    },
                                      {
                                      title: t("Days"),
                                      value: 2,
                                    },
                                  ]}
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.time_period`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                    <Field
                                    label={props?.values?.cancellation_policy_criterias[index]?.time_period === 1 ? t("Hours") : t("Days")}  
                                    name={`cancellation_policy_criterias.${index}.time_period_value`}
                                    id={`cancellation_policy_criterias.${index}.time_period_value`}
                                    component={FormikInput1}
                                    type="number"
                                  
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.time_period_value`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                     <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Refund Type")}
                                    </Typography>
                                    <Field
                                    label={t("Refund Type")}
                                    name={`cancellation_policy_criterias.${index}.refund_type`}
                                    id={`cancellation_policy_criterias.${index}.refund_type`}
                                    component={FormikSelect}
                                    options={[
                                      {
                                      title: t("In Percentage"),
                                      value: 1,
                                    },
                                      {
                                      title: t("By Nights"),
                                      value: 2,
                                    },
                                  ]}
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.refund_type`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                  <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                    <Field
                                    label={props?.values?.cancellation_policy_criterias[index]?.refund_type === 1 ? t("Refund Value(In Percentage)") : t("Refund Value(By Nights)")}
                                    name={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    id={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    component={FormikInput1}
                                    type="number"
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      marginRight: "20px",
                                      // marginBottom: "20px",
                                      // borderTop: "2px solid",
                                      // marginTop: "20px",
                                    }}
                                  >
                                    {index ===
                                      props?.values?.cancellation_policy_criterias?.length - 1 && (
                                      <Button
                                        className="mt-1 comman-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => push(criteriasObj)}
                                        color="primary"
                                      >
                                        {t("Add")}
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        className="mt-1 ml-1 comman-cancel-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => remove(index)}
                                        color="primary"
                                      >
                                        {t("Remove")}
                                      </Button>
                                    )}
                                  </div>
                                </Grid>
                            </React.Fragment>
                          ))
                        }
                        </>
                      )}
                      </FieldArray>)}
                    <Grid item xs={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "20px",
                        marginBottom: "20px",
                        borderTop: "2px solid",
                        marginTop: "20px",
                      }}
                    >
                     
                        <Button
                          className="mt-1 comman-button"
                          size="large"
                          variant="contained"
                          disableElevation
                          type="submit"
                          color="primary"
                          
                        >
                          {t("Save")}
                        </Button>
                     
                    </div>
                    </Grid> 
                    
                    </Grid>
                    
                  </form>
              
              );
            }}
      </Formik>


        {/* (<Formik>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputLabel className=''>{t("Name")}</InputLabel>
                  <Field
                    // label="Ema
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    placeHolder="Enter Name"
                    maxLength={150}
                    component={FormikInput}
                    // onClick={() => dispatch(setProps({"name":"nskn"}))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className=''>{t("Prepayment Option")}</InputLabel>
                  <Field
                    // label="Ema
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    maxLength={150}
                    component={FormikSelect}
                    // onClick={() => dispatch(setProps({"name":"nskn"}))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox /> {t("Is there a period when the guest can cancel a reservation without any charge?")}
                
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className=''>{t("How much is the guest charged for cancellation if they cancel after reservation?")}</InputLabel>
                  <Field
                    // label="Ema
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    maxLength={150}
                    component={FormikSelect}
                    // onClick={() => dispatch(setProps({"name":"nskn"}))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className=''>{t("If guest cancel until...?")}</InputLabel>
                  <Field
                    // label="Ema
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    maxLength={150}
                    component={FormikSelect}
                    // onClick={() => dispatch(setProps({"name":"nskn"}))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel className=''>{t("… they must be charged…?")}</InputLabel>
                  <Field
                    // label="Ema
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    maxLength={150}
                    component={FormikSelect}
                    // onClick={() => dispatch(setProps({"name":"nskn"}))}
                  />
                </Grid>
            </Grid>
        </Formik>
        <div className=' p-2 mt-2' style={{width:"95%",background:"#80808059"}}>
             <h6>{t("Up to until 1 day before arrival, the first night will be charged in case of cancellation. After that, the full price is due.")}</h6>
             <h6>{t("No Prepayment Required.")}</h6>
             <h6 style={{color:"gray"}}>{t("Cancellation policy summary")}</h6>
        </div>

        <div className='w-100 d-flex justify-content-center'>
          <Button className="mt-1 comman-cancel-button"
              size="large"
              variant="contained"
              disableElevation
              color="primary"
              // style={{
              //     justifyContent: "center",
              //     marginRight:"10px",
              //     width: "98px",
              //     height: "44px",
              //     textTransform: "capitalize",
              //     background:
              //     "gray",
              // }}
              >
              {t("Cancel")}
          </Button>
          <Button className="mt-1 ml-1 comman-cancel-button"
              size="large"
              variant="contained"
              disableElevation
              color="primary"
              // style={{
              //     justifyContent: "center",
              //     width: "98px",
              //     height: "44px",
              //     textTransform: "capitalize",
              //     background:
              //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
              // }}
              >
              {t("Save")}
          </Button>
        </div>) */}
    </div>
  </>
  )
}

export default AddCancellation;