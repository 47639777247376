import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelList: [],
  hotelDetail: null,
  destinationListForSelect: [],
};
const hotelSlice = createSlice({
  name: "hotel",
  initialState: initialState,
  reducers: {
    hotelListSuccess: (state, action) => {
      state.hotelList = action.payload;
    },
    hotelListFailed: (state, action) => {
      state.hotelList = [];
    },
    hotelDetailSuccess: (state, action) => {
      state.hotelDetail = action.payload;
    },
    hotelDetailFailed: (state, action) => {
      state.hotelDetail = null;
    },
    destinationListForSelectSuccess: (state, action) => {
      state.destinationListForSelect = action.payload;
    },
    destinationListForSelectFailed: (state, action) => {
      state.destinationListForSelect = [];
    },
  },
});

export const {
  hotelListSuccess,
  hotelListFailed,
  hotelDetailSuccess,
  hotelDetailFailed,
  destinationListForSelectSuccess,
  destinationListForSelectFailed,
} = hotelSlice.actions;
export default hotelSlice.reducer;
