import { createSlice } from "@reduxjs/toolkit";

const localUserData = JSON.parse(localStorage.getItem("userDetails") || "{}");
// const localOwnerUserData = JSON.parse(localStorage.getItem("userOwnerDetails") || "{}");
const initialState = {
  arrival: {
    datagridPrintOptions:[],
    datagridColumns: [],
    searchFilters: {

    }
  },
  // userOwnerInfo: localOwnerUserData ? localOwnerUserData : null,
  departure: {
    datagridPrintOptions:[],
    datagridColumns: [],
    searchFilters: {

    }
  },
};
const ownerReportSlice = createSlice({
  name: "ownerReport",
  initialState: initialState,
  reducers: {
    arrivalSuccess: (state, action) => {
      state.arrival = action.payload
    },
    arrivalFailed: (state) => {
      state.arrival = {
        datagridPrintOptions:[],
        datagridColumns: [],
        searchFilters: {
    
        }
      }
    },
    
    departureSuccess: (state, action) => {
      state.departure = action.payload
    },
    departureFailed: (state) => {
      state.departure = {
        datagridPrintOptions:[],
        datagridColumns: [],
        searchFilters: {
    
        }
      }
    },
    
  },
});

export const { 
    arrivalSuccess,
    arrivalFailed,
    departureSuccess,
    departureFailed
} = ownerReportSlice.actions;
export default ownerReportSlice.reducer;
