import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import English from "../src/languages/English.json";
import Japanese from "../src/languages/Japanese.json";
import Spanish from "../src/languages/Spanish.json";
import Mandarin from "../src/languages/Mandarin.json";
import German from "../src/languages/German.json";
import Dutch from "../src/languages/Dutch.json";


i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
      translations: English,
      },
      ja: {
        translation: Japanese,
      },
      es: {
        translation: Spanish,
      },
      "zh-CN": {
        translation: Mandarin,
      },
      de: {
        translation: German,
      },
      nl: {
        translation: Dutch,
      }
    },
    lng: localStorage.getItem("lng") || "en", 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
    parseMissingKeyHandler: (key) => {
      return key;
    },
  });

export default i18next;
