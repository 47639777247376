import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createRoomRate = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateRoomRate = async (data: object, rateId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE + `/${rateId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteRoomRateApiCall = async (rateId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE + `/${rateId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const fetchRoomRateList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomRateDetail = async (rateId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE + `/${rateId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAccommodationsList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.ACCOMMODATION_LIST,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
