import { CircularProgress } from "@mui/material";
import React from "react";

interface ISpinnerProps {
  color?: "primary" | "secondary" | "inherit";
}

const SpinnerGlobal: React.FC<ISpinnerProps> = ({ color = "primary" }) => {
  return (
    <div className="loader-wrapper-main">
      <CircularProgress color={"primary"}  thickness={2} size={50} />
    </div>
  );
};

export default SpinnerGlobal;



