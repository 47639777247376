import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";


export const createProperty = async (data: object, url) => {
  try {
    const result = await instance({
      url: url,
      method: "POST",
      data,
    });
    // return result;
    return result;
  } catch (error) {
    throw error;
  }
};

export const createRoomType = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_ROOM_TYPE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createRatePlan = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_RATE_PLAN,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createRateAccordingOTA = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_RATES_ACCORDING_OTA,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeRateAccordingOTA = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.REMOVE_RATES_ACCORDING_OTA,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createAvailibilityAccordingOTA = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_AVAILIBILITY_ACCORDING_OTA,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeAvailibilityAccordingOTA = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.REMOVE_AVAILIBILITY_ACCORDING_OTA,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createRatesAndAvailability = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.CREATE_RATES_AND_AVAILABILITY,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createSingleOta = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OTA_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
}

export const updateSingleOta = async (data: object, id:number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.OTA_RESOURCE_ROUTE + `/${id}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
}