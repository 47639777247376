
// import React from "react";
import {
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from 'react-redux'
import { setProps } from "../../Reducer/formData";

const useStyles = makeStyles({
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label_shrinked: {
    minWidth: "200px",
    display: "flex",
  },
  textField: {
    border: "1px solid #B4ADAD !important",
    borderRadius: "6px !important",
  },
});

const FormikInput = function ({
  field: { ...fields },
  ...props
}) {
  // console.log(props)
  const styles = useStyles();
  const {
    id,
    maxLength,
    isDefaultValue,
    multiline,
    className,
    // hasObject = false,
    controlClassName,
    sx,
    noMaxLength,
    ...rest
  } = props;

  // const formData = useSelector((state) => state.formData)
  const dispatch = useDispatch()

  // const handleOnChange = (event, name) => {
  //     // console.log(event.target.value)
  //     var temp = {
  //        name : event.target.value
  //     }
  //     dispatch(setProps(temp))
  // }

  return (
    <FormControl fullWidth className={controlClassName} sx={sx}>
      <Typography
        id="font-600"
        style={{
          marginBottom: "11px",
          color: "#8A92A6",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        {props?.label}
      </Typography>
    
      {isDefaultValue ? (
        <TextField
          {...fields}
          {...rest}
          id={id}
          label={""}
          className={className}
          multiline={multiline}
          //   error={error}
          onChange={(e) => dispatch(setProps(fields.name))}
          autoComplete="off"
          inputProps={{
            maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
            className: styles.textField,
          }}
        />
      ) : (
        <TextField
          {...fields}
          {...rest}
          id={id}
          className={className}
          label={""}
          value={props.type === "number" ? fields?.value || "" : fields?.value}
          multiline={multiline}
          onChange={(e) => props.handleOnChange(e, fields.name)}
          //   error={error}
          // onBlur={(e) => dispatch(setProps(e.target.value))}
          autoComplete="off"
          inputProps={{
            maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
            className: styles.textField,
          }}
        />
      )}
    </FormControl>
  );
};

export default FormikInput;



