import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import DialogForm from "../../shared/components/DialogForm";
import NewBooking from "../NewBooking/NewBooking";
import AddRoomRate from "./AddRoomRate";
import { connect } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  roomsRateListFailed,
  roomsRateListSuccess,
} from "../../Reducer/roomRateSlice";
import {
  deleteRoomRateApiCall,
  fetchRoomRateList,
} from "../../apis/services/RoomRateService";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { fetchList } from "../../apis/services/CommonApiService";
import { rowsPerPageJsonData } from "../../utils/JsonData";
import Loader from "../../layout/Loader";
import { useTranslation } from "react-i18next";
import { getCancellationPolicy, getLanguageCode } from "../../utils/helper";

const Roomrates = (props) => {
  const { roomsRateList, roomsRateListSuccess, roomsRateListFailed } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [ratesDatagridOptions, setRatesDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });

  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    rateActivityStatus: "",
  });

  const updateRatesDatagridOptions = (k, v) =>
    setRatesDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const getRoomRateList = useCallback(async () => {
    // console.log("Api Call");
    updateRatesDatagridOptions("loading", true);
    // const result = await fetchRoomRateList(
    //   ratesDatagridOptions.page,
    //   ratesDatagridOptions.pageSize
    // );
    const result = await fetchList(
      ApiEndPoints.ROOM_RATES_RESOURCE_ROUTE +
        `?page_no=${ratesDatagridOptions.page}&items_per_page=${ratesDatagridOptions.pageSize}&search_text=${searchFilters.searchText}&rate_status=${searchFilters.rateActivityStatus}`
    );
    if (result?.status === 200) {
      updateRatesDatagridOptions("totalRows", result.data.totalRows);
      roomsRateListSuccess(result.data.rows);
      updateRatesDatagridOptions("loading", false);
    } else {
      // setTotalRowCount(0);
      updateRatesDatagridOptions("totalRows", 0);
      roomsRateListFailed();
      updateRatesDatagridOptions("loading", false);
    }
  }, [
    ratesDatagridOptions.page,
    ratesDatagridOptions.pageSize,
    searchFilters.searchText,
    searchFilters.rateActivityStatus,
  ]);

  useEffect(() => {
    getRoomRateList();
  }, [getRoomRateList]);

  const setRatesData = (data) => {
    const ratesDatagridRows = data?.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        // meal_price: item.meal_price,
        cancelation_policy: item.cancellationPolicyDetails?.name,
        actions: "Actions",
      };
    });
    updateRatesDatagridOptions("rows", ratesDatagridRows);
  };

  useEffect(() => {
    setRatesData(roomsRateList);
  }, [roomsRateList]);

  const [alignment, setAlignment] = React.useState("left");

  // const handleActiveInactiveChange = (event, newAlignment) => {
  //   console.log(event.target.value);
  //   setAlignment(newAlignment);
  // };

  const handleActiveInactiveChange = (event, newAlignment) => {
    const { value } = event.target;
    if (value && value != "") {
      setSearchFilters((prev) => ({
        ...prev,
        rateActivityStatus: value,
      }));
    }
    setAlignment(newAlignment);
  };

  const handleRateSearch = (event) => {
    const { value } = event.target;
    if (value.length > 2) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    } else if (value.length == 0) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  const getCurrentState = () => {
    return props.state;
  };

  const editRate = React.useCallback(
    (id) => () => {
      navigate(`/edit-room-rates/${id}`);
    },
    []
  );
  const discountedRate = React.useCallback(
    (id) => () => {
      navigate(`/discounted-rate/${id}`);
    },
    []
  );

  //delete room rate api
  const [loading, setLoading] = React.useState(false);
  const [openDeleteRoomRateDialog, setOpenDeleteRoomRateDialog] =
    React.useState(false);
  const handleCloseDeleteRoomRateDialog = () =>
    setOpenDeleteRoomRateDialog(false);
  const [deleteRoomRateId, setDeleteRoomRateId] = useState(0);
  const handleConfirmDeleteRoomRateAction = async () => {
    setLoading(true);
    setOpenDeleteRoomRateDialog(false);
    const result = await deleteRoomRateApiCall(deleteRoomRateId);
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      getRoomRateList();
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };
  const deleteRate = React.useCallback(
    (id) => () => {
      setDeleteRoomRateId(id);
      setOpenDeleteRoomRateDialog(true);
    },
    []
  );

  const ratesDatagridColumns = [
    { field: "name", headerName: t("Rate Name"), flex: 1 },
    // { field: "meal_price", headerName: t("Meals"), flex: 1 },
    {
      field: "cancelation_policy",
      headerName: t("Cancellation policy"),
      flex: 1,
      // renderCell: (params) => (
      //   <>
      //     <Typography variant="subtitle2">
      //       {/* {t("Free cancellation policy")} */}
      //       {getCancellationPolicy(+params.value)}
      //     </Typography>
      //   </>
      // ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("Actions"),
      flex: 1,
      getActions: (params) => [
        // <GridActionsCellItem
        //   label="Discounted Rates"
        //   onClick={discountedRate(params.id)}
        //   showInMenu
        // />,
        <GridActionsCellItem
          // icon={<SecurityIcon />}
          label={t("Edit")}
          onClick={editRate(params.id)}
          showInMenu
        />,
        <GridActionsCellItem
          // icon={<FileCopyIcon />}
          label={t("Delete")}
          onClick={deleteRate(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const data = [
    {
      Booking: "Standard",
      Room: "No meals",
      Guests: "Free cancellation policy",
      Chat: "Reception",
      Nights: "2",
    },
  ];

  const [checked, setChecked] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [tabName, setTabName] = React.useState("general");

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handletabs = (e) => {
    document.getElementById("general").classList.remove("active-tab");
    document.getElementById("roomrates").classList.remove("active-tab");
    document.getElementById("virtualrates").classList.remove("active-tab");
    document.getElementById("cancellation").classList.remove("active-tab");
    document.getElementById("meals").classList.remove("active-tab");
    document.getElementById("cleaningfees").classList.remove("active-tab");
    document.getElementById("earlycheckin").classList.remove("active-tab");
    document.getElementById("pricing").classList.remove("active-tab");
    document.getElementById("restriction").classList.remove("active-tab");
    document.getElementById("citytax").classList.remove("active-tab");

    document.getElementById(e).classList.add("active-tab");
  };

  return (
    <div className="main-layout-background h-auto">
      {loading && <Loader />}
      <div className="d-flex mb-2 pr-2 justify-content-between align-items-center">
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item lg={4}>
            <FormControl fullWidth>
              <TextField
                label={t("Search Rate Name")}
                onChange={(event) => {
                  handleRateSearch(event);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4}>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleActiveInactiveChange}
              aria-label="Platform"
            >
              <ToggleButton style={{ marginTop: "0px" }} value="1" xs={4}>
                {t("Active")}
              </ToggleButton>
              <ToggleButton style={{ marginTop: "0px" }} value="0">
                {t("Not Active")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        {/* <FormControl className="form-field">
          <Select
            defaultValue={30}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={10}>Deo</MenuItem>
            <MenuItem value={20}>john</MenuItem>
            <MenuItem value={30}>James</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-field">
          <Select
            defaultValue={20}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={10}>Meals</MenuItem>
            <MenuItem value={20}>No Meals</MenuItem>
            <MenuItem value={30}>Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-field">
          <Select
            defaultValue={10}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={10}>Cancellation Fee</MenuItem>
            <MenuItem value={20}>No Cancellation Fee</MenuItem>
          </Select>
        </FormControl>
        <FormControl className="form-field">
          <Select
            defaultValue={10}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={10}>Source</MenuItem>
            <MenuItem value={20}>Source 1</MenuItem>
            <MenuItem value={30}>Source 2</MenuItem>
            <MenuItem value={40}>Source3</MenuItem>
          </Select>
        </FormControl> */}
        {/* <Grid item lg={4} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <TextField
              label="Search Rate Name"
              onChange={(event) => {
                handleRateSearch(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleActiveInactiveChange}
          aria-label="Platform"
        >
          <ToggleButton value="1" xs={4}>
            Active
          </ToggleButton>
          <ToggleButton value="0">Not Active</ToggleButton>
        </ToggleButtonGroup> */}
      </div>
      <div>
        {/* <div className="pl-3 pr-3"> */}
        {/* <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeading.map((heading) => {
                    return (
                      <>
                        <TableCell
                          key={heading.name}
                          className="text-center"
                          maxWidth="10"
                        >
                          {heading.name}
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {data.map((data) => {
                    return (
                      <>
                        <TableCell className="text-center">
                          {data.Booking}
                        </TableCell>
                        <TableCell className="text-center">
                          {data.Room}
                        </TableCell>
                        <TableCell className="text-center">
                          {data.Guests}
                        </TableCell>
                        <TableCell className="text-center">
                          {data.Chat}
                        </TableCell>
                        <TableCell className="text-center">
                          <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </TableCell>
                      </>
                    );
                  })}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}

        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={ratesDatagridOptions.loading}
          rowCount={ratesDatagridOptions.totalRows}
          rowsPerPageOptions={ratesDatagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={ratesDatagridOptions.rows}
          columns={ratesDatagridColumns}
          page={ratesDatagridOptions.page - 1}
          pageSize={ratesDatagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setRatesDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            // console.log("page size", pageSize);
            updateRatesDatagridOptions("page", 1);
            updateRatesDatagridOptions("pageSize", pageSize);
          }}
        />
        {/* </div> */}
      </div>

      <DialogForm
        scroll="paper"
        maxWidth="xl"
        title={t("Create room rate")}
        openDialog={openDialog}
        handleDialogClose={handleDialogClose}
        bodyContent={<AddRoomRate handleDialogClose={handleDialogClose} />}
      />

      {/* Delete Room Rate Alert */}
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to delete the room rate data?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeleteRoomRateDialog}
        handleDialogClose={handleCloseDeleteRoomRateDialog}
        handleDialogAction={handleConfirmDeleteRoomRateAction}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roomsRateList: state.roomRate.roomsRateList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomsRateListSuccess: (data) => dispatch(roomsRateListSuccess(data)),
    roomsRateListFailed: () => dispatch(roomsRateListFailed()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Roomrates);
