import  { Suspense, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/dev-style.css";
import "./assets/css/spacer.css";
import "./assets/css/style.css";
import { ThemeProvider } from "@mui/material/styles";
import { AppRouting } from "./shared/AppRouting";
import portalTheme from "./themes/App.theme";
import { useDispatch } from "react-redux";
import { loginSuccess } from "./Reducer/authSlice";
import instance from "./apis/ApiConfig";
import packageJson from "../package.json";

// const App: React.FC = () => (
//   <Suspense fallback={<Spinner />}>
//     <Loader />
//     <ThemeProvider theme={portalTheme}>
//       {/* <ErrorBoundary> */}
//       <AppRouting />
//       {/* </ErrorBoundary> */}
//     </ThemeProvider>
//   </Suspense>
// );

function App() {
  const dispath = useDispatch();
  useEffect(() => {
    async function setUserState(params) {
      // console.log("params?.token", params?.token);
      instance.defaults.headers.common["Authorization"] = params?.token;
      await dispath(loginSuccess(params));
      // console.log(params);
    }
    // const data = await Storage.retrieveItem("userDetails");
    const data = localStorage.getItem("userDetails");
    if (data) {
      const userDetails = JSON.parse(data);
      setUserState(userDetails);
    }

    

    // Storage.retrieveItem("userDetails").then((params) => {
    //   console.log("Localstorage usetdata form app.tsx params", params);
    //   if (params === null || params === undefined || params === "") {
    //   } else {
    //     const userDetails = JSON.parse(params);
    //     console.log("Localstorage usetdata form app.tsx", userDetails);
    //     setUserState(JSON.parse(userDetails));
    //     // token = userDetails?.token;
    //   }
    // });

    // console.log("Localstorage usetdata", data);
    // setUserState(JSON.parse(data));
  }, [dispath]);

  useEffect(() => {
    // export default function emptyCache(){
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload();
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
    // }
  }, []);

  // useEffect(() => {
    // if (process.env.NODE_ENV === "development") {
      // console.warn = () => {};
      // console.error = () => {};
    // }
  // }, []);

  return (
    <Suspense
    // fallback={<Spinner />}
    >
      {/* <Loader /> */}
      <ThemeProvider theme={portalTheme}>
        {/* <ErrorBoundary> */}
        <AppRouting />
        {/* </ErrorBoundary> */}
      </ThemeProvider>
    </Suspense>
  );
}
export default App;
