import {
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
  } from "@mui/material";
  import Loader from "../../layout/Loader";
  import ShowInputError from "../../shared/components/ShowInputError";
  import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
  import { ErrorMessage, Field, FieldArray, Formik } from "formik";
  import React, { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
  import moment from "moment";
  import { createRecord, fetchList } from "../../apis/services/CommonApiService";
  import { ApiEndPoints } from "../../apis/ApiEndPoints";
  import { useTheme } from "@emotion/react";
  import { createAvailibilityAccordingOTA } from "../../apis/services/ChannelManagerApiService";
  import { toast } from "react-toastify";
  import { CreateAvailibilityAccordingOtasValidator } from "../../shared/validations/AdminValidator";
  import CancelIcon from "@mui/icons-material/Cancel";
  

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  
  const AvailabilityAccordingToOtas = (props) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [hotelID, setHotelID] = useState("");
    const [roomIdData, setRoomIdData] = useState([]);
    // const [roomRateIdData, setRoomRateIdData] = useState([]);
    const [otaCodeList, setOtaCodeList] = useState([]);

  
    //   ////////
  
    const ruleType = ["Fixed", "Increase", "Decrease"
    //  "Multiply"
    ];
  
    // const otaCodeList = [
    //   { name: "Airbnb", value: 244 },
    //   { name: "Booking.com", value: 19 },
    //   { name: "Expedia", value: 9 },
    //   { name: "VRBO", value: 253 },
    //   { name: "BookingDirect", value: 185 },
    // ];
  
    const theme = useTheme();
    // const [personName, setPersonName] = useState([]);
    const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
    const handleChange = (event, setFieldValue, index, dIndex, oIndex) => {
      const {
        target: { value },
      } = event;
      // setPersonName(
      //   // On autofill we get a stringified value.
      //   typeof value === "string" ? value.split(",") : value
      // );
      setFieldValue(
        `inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`,
        typeof value === "string" ? value.split(",") : value
      );
    handleClose();

    };

    
  const handleDeleteOta = (event,value, setFieldValue, index, dIndex, oIndex) => {
    // const {
    //   target: { value },
    // } = event;
    let otaList = event.filter((item)=> item !== value);

    // console.log(otaList, event, value, 93)
    setFieldValue(
      `inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`,
      otaList
    );
  };
  
    ///////////////
  
    const roomControlObj = {
      roomid: "",
    //   rateid: "",
      date: [
        {
          from: moment().format("YYYY-MM-DD"),
          to: moment().endOf("month").format("YYYY-MM-DD"),
          OTARule: [
            {
              OTACode: [],
              rule: {
                type: "",
                value: "",
              },
            },
          ],
        },
      ],
    };
  
    const initialState = {
      hotelid: hotelID,
      inventorycontrol: [roomControlObj],
    };
  
    useEffect(() => {
      getRoomTypes();
      getOtaCodeList()
    }, []);

    const getOtaCodeList = async () => {
      const result = await fetchList(
        ApiEndPoints.OTA_LIST_FOR_SELECT
      );
      if (result?.status === 200) {
        let arry = result?.data?.map((item)=>({name:item?.ota_name, value:item?.ota_code}));
        // console.log(arry, 189)
          setOtaCodeList(arry);
      }
      else{
        console.log(result.message + ", please check!");
        setOtaCodeList([])
      }
    };
  
    const getRoomTypes = async () => {
      const userHotelID = localStorage.getItem("userDetails")
        ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
        : 0;
      const result = await fetchList(
        ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
      );
  
      if (result?.status === 200) {
        setHotelID(result.data.property_id);
  
        const data = {
          hotelid: result.data.property_id,
        };
        const listing_result = await createRecord(
          data,
          ApiEndPoints.GET_ROOM_TYPE
        );
  
        // const RoomTypeList = [];
  
        if (listing_result?.status === 200) {
          const orgResult = listing_result.data.rooms;
          const RoomType = [];
  
          for (var j in orgResult) {
            RoomType.push({
              id: orgResult[j].roomid,
              roomname: orgResult[j].roomname,
              // status: orgResult[j].status,
              // roomtype: orgResult[j].roomtype,
              // maximumoccupancy: orgResult[j].maximumoccupancy,
              // quantity: orgResult[j].quantity,
            });
          }
          setRoomIdData(RoomType);
        } else {
          console.log(result.message.response + ", please check!");
        }
        // const listing_rate_result = await createRecord(
        //   data,
        //   ApiEndPoints.GET_RATE_PLAN
        // );
        // // const RatePlanList = [];
  
        // if (listing_rate_result?.status === 200) {
        //   const orgResult = listing_rate_result.data.rateplans;
        //   const RatePlanObj = [];
  
        //   for (var i in orgResult) {
        //     RatePlanObj.push({
        //       id: orgResult[i].rateplanid,
        //       name: orgResult[i].rateplanname,
        //       // status: orgResult[j].status,
        //       // mealplanid: orgResult[j].mealplanid,
        //     });
        //   }
  
        //   setRoomRateIdData(RatePlanObj);
        // } else {
        //   console.log(listing_rate_result.message.response + ", please check!");
        // }
      } else {
        console.log(result.message.response + ", please check!");
      }
    };
  
    const handleSubmit = async (values, resetForm) => {
      // console.log(values, 135);
  
      setLoading(true);
  
      const result = await createAvailibilityAccordingOTA(values);
  
      if (result?.status === 200) {
        toast.success(t(result.message));
  
        setLoading(false);
        resetForm();
        if(props?.handleAvailabilityAccorOtaDialog){
          props?.handleAvailabilityAccorOtaDialog();
        }
        // setPersonName([]);
      } else {
        // console.log(result?.message?.Errors[0]?.ShortText, 238);
        if (result?.message?.Errors?.length > 0) {
          setLoading(false);
          toast.error(t(result?.message?.Errors[0]?.ShortText));
        } else {
          setLoading(false);
          toast.error(t(result?.message));
        }
      }
    };
  
    return (
      <>
        {loading && <Loader />}
        <div className=" pl-2 pr-2 pb-2 mt-3" style={{ height: "auto" }}>
          <Formik
            initialValues={initialState}
            onSubmit={async (values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
            validateOnBlur={false}
            validateOnChange={true}
            enableReinitialize={true}
            validationSchema={()=>CreateAvailibilityAccordingOtasValidator(t)}
          >
            {(props) => {
              const { handleSubmit } = props;
              return (
            
                  <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={2}>
                      <Grid item lg={12} sx={{ paddingX: 2 }}>
                        <Field
                          label={t("Hotel ID")}
                          name="hotelid"
                          id="hotelid"
                          component={FormikInput1}
                          type="text"
                          readOnly={true}
                        />
                        <ErrorMessage name="hotelid" component={ShowInputError} />
                      </Grid>
                      <FieldArray name="inventorycontrol">
                        {({ push, remove }) => (
                          <>
                            {props?.values.inventorycontrol.map((_, index) => (
                              <React.Fragment key={index}>
                                {
                                  <>
                                    {index !== 0 && (
                                      <Grid item lg={12} md={12}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                            marginRight: "20px",
                                            marginBottom: "20px",
                                            borderTop: "2px solid",
                                            marginTop: "20px",
                                          }}
                                        ></div>
                                      </Grid>
                                    )}
                                    <Grid item lg={4} md={4}>
                                      <Typography
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Room Category")}
                                      </Typography>
                                      <Field
                                        label={t("Room Category")}
                                        placeholder={t("Enter Room Category")}
                                        name={`inventorycontrol.${index}.roomid`}
                                        id={`inventorycontrol.${index}.roomid`}
                                        component={FormikSelect}
                                        type="text"
                                        options={roomIdData?.map((data) => ({
                                          title: data?.roomname,
                                          value: data?.id,
                                        }))}
                                      />
                                      <ErrorMessage
                                        name={`inventorycontrol.${index}.roomid`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    {/* <Grid item lg={6}>
                                      <Typography
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Rate Plan ID")}
                                      </Typography>
                                      <Field
                                        label={t("Rate Plan ID")}
                                        placeHolder={t("Rate Plan ID")}
                                        name={`inventorycontrol.${index}.rateid`}
                                        id={`inventorycontrol.${index}.rateid`}
                                        component={FormikSelect}
                                        type="text"
                                        options={roomRateIdData?.map((data) => ({
                                          title: data?.name,
                                          value: data?.id,
                                        }))}
                                      />
                                      <ErrorMessage
                                        name={`inventorycontrol.${index}.rateid`}
                                        component={ShowInputError}
                                      />
                                    </Grid> */}
  
                                    <FieldArray
                                      name={`inventorycontrol.${index}.date`}
                                    >
                                      {({ push, remove }) => (
                                        <>
                                          {props?.values.inventorycontrol[
                                            index
                                          ].date.map((_, dIndex) => (
                                            <React.Fragment key={dIndex}>
                                              <Grid item lg={4} md={4}>
                                                <Field
                                                  label={t("From Date")}
                                                  placeholder="From Date"
                                                  name={`inventorycontrol.${index}.date.${dIndex}.from`}
                                                  type="date"
                                                  component={FormikInput1}
                                                />
                                                <ErrorMessage
                                                  name={`inventorycontrol.${index}.date.${dIndex}.from`}
                                                  component={ShowInputError}
                                                />
                                              </Grid>
                                              <Grid item lg={4} md={4}>
                                                <Field
                                                  label={t("To Date")}
                                                  placeholder="To Date"
                                                  name={`inventorycontrol.${index}.date.${dIndex}.to`}
                                                  type="date"
                                                  component={FormikInput1}
                                                />
                                                <ErrorMessage
                                                  name={`inventorycontrol.${index}.date.${dIndex}.to`}
                                                  component={ShowInputError}
                                                />
                                              </Grid>
                                              <FieldArray
                                                name={`inventorycontrol.${index}.date.${dIndex}.OTARule`}
                                              >
                                                {({ push, remove }) => (
                                                  <>
                                                    {props?.values.inventorycontrol[
                                                      index
                                                    ].date[dIndex].OTARule.map(
                                                      (_, oIndex) => (
                                                        <React.Fragment key={oIndex}>
                                                          <Grid item lg={6} md={6}>
                                                            <Typography
                                                              style={{
                                                                marginBottom:
                                                                  "11px",
                                                                color:
                                                                  "rgb(30, 30, 30)",
                                                                fontSize: "16px",
                                                              }}
                                                            >
                                                              {t("OTA Code")}
                                                            </Typography>
                                                            <FormControl
                                                              sx={{
                                                                //   m: 1,
                                                                width: "100%",
                                                              }}
                                                            >
                                                              <InputLabel
                                                                id={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`}
                                                              >
                                                                {t("OTA")}
                                                              </InputLabel>
                                                              <Select
                                                                name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`}
                                                                label={"OTA Code"}
                                                                open={open}
                                                                onClose={handleClose}
                                                                onOpen={handleOpen}
                                                                //   labelId="demo-multiple-chip-label"
                                                                id={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`}
                                                                multiple
                                                                sx={{
                                                                  height: "auto",
                                                                }}
                                                                value={
                                                                  props.values
                                                                    .inventorycontrol[
                                                                    index
                                                                  ].date[dIndex]
                                                                    .OTARule[
                                                                    oIndex
                                                                  ].OTACode
                                                                }
                                                                onChange={(e) =>
                                                                  handleChange(
                                                                    e,
                                                                    props.setFieldValue,
                                                                    index,
                                                                    dIndex,
                                                                    oIndex
                                                                  )
                                                                }
                                                                input={
                                                                  <OutlinedInput
                                                                    id="select-multiple-chip"
                                                                    label="Chip"
                                                                  />
                                                                }
                                                                renderValue={(
                                                                  selected
                                                                ) => (
                                                                  <Box
                                                                    sx={{
                                                                      display:
                                                                        "flex",
                                                                      flexWrap:
                                                                        "wrap",
                                                                      gap: 0.5,
                                                                    }}
                                                                  >
                                                                    {selected?.map(
                                                                      (value) => (
                                                                        <Chip
                                                                          key={
                                                                            value
                                                                          }
                                                                          label={
                                                                            (otaCodeList?.filter(
                                                                              (
                                                                                item
                                                                              ) =>
                                                                                item?.value ===
                                                                                value
                                                                            ))[0]
                                                                              .name
                                                                          }
                                                                          clickable
                                                                          deleteIcon={
                                                                            <CancelIcon
                                                                              onMouseDown={(event) => event.stopPropagation()}
                                                                            />
                                                                          }
                                                                          onDelete={(e) => handleDeleteOta(selected, value,
                                                                            props.setFieldValue,
                                                                            index,
                                                                            dIndex,
                                                                            oIndex)}
                                                                          onClick={() => console.log("clicked chip")}
                                                                        />
                                                                      )
                                                                    )}
                                                                  </Box>
                                                                )}
                                                                MenuProps={
                                                                  MenuProps
                                                                }
                                                              >
                                                                {otaCodeList.map(
                                                                  (item) => (
                                                                    <MenuItem
                                                                      key={
                                                                        item.value
                                                                      }
                                                                      value={
                                                                        item.value
                                                                      }
                                                                      style={getStyles(
                                                                        item.value,
                                                                        props
                                                                          .values
                                                                          .inventorycontrol[
                                                                          index
                                                                        ].date[
                                                                          dIndex
                                                                        ].OTARule[
                                                                          oIndex
                                                                        ].OTACode,
                                                                        theme
                                                                      )}
                                                                    >
                                                                      {item.name}
                                                                    </MenuItem>
                                                                  )
                                                                )}
                                                              </Select>
                                                            </FormControl>
                                                            <ErrorMessage
                                                              name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.OTACode`}
                                                              component={
                                                                ShowInputError
                                                              }
                                                            />
                                                          </Grid>
                                                          <Grid item lg={3} md={3}>
                                                            <Typography
                                                              style={{
                                                                marginBottom:
                                                                  "11px",
                                                                color:
                                                                  "rgb(30, 30, 30)",
                                                                fontSize: "16px",
                                                              }}
                                                            >
                                                              {t("Rule Type")}
                                                            </Typography>
                                                            <Field
                                                              label={t(
                                                                "Rule Type"
                                                              )}
                                                              placeholder={t(
                                                                "Rule Type"
                                                              )}
                                                              name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.rule.type`}
                                                              id={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.rule.type`}
                                                              component={
                                                                FormikSelect
                                                              }
                                                              type="text"
                                                              options={ruleType?.map(
                                                                (data) => ({
                                                                  title: data,
                                                                  value: data,
                                                                })
                                                              )}
                                                            />
                                                            <ErrorMessage
                                                              name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.rule.type`}
                                                              component={
                                                                ShowInputError
                                                              }
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            lg={3}
                                                            md={3}
                                                            className=""
                                                          >
                                                            <Field
                                                              label={t("Rule Value")}
                                                              placeholder="Rule Value"
                                                              name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.rule.value`}
                                                              type="number"
                                                              component={
                                                                FormikInput1
                                                              }
                                                            />
                                                            <ErrorMessage
                                                              name={`inventorycontrol.${index}.date.${dIndex}.OTARule.${oIndex}.rule.value`}
                                                              component={
                                                                ShowInputError
                                                              }
                                                            />
                                                          </Grid>
                                                        </React.Fragment>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </FieldArray>
                                            </React.Fragment>
                                          ))}
                                        </>
                                      )}
                                    </FieldArray>
                                  </>
                                }
                                <Grid item lg={12} md={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      marginRight: "20px",
                                      marginBottom: "20px",
                                      // borderTop: "2px solid",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {index ===
                                      props.values?.inventorycontrol?.length - 1 && (
                                      <Button
                                        className="mt-1"
                                        size="large"
                                        variant="contained"
                                        onClick={() => push(roomControlObj)}
                                        color="primary"
                                        style={{
                                          justifyContent: "center",
                                          width: "98px",
                                          height: "44px",
                                          textTransform: "capitalize",
                                          background:
                                            "linear-gradient(180deg, #255480 0%, #173450 100%)",
                                        }}
                                      >
                                        {t("Add")}
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        className="mt-1"
                                        size="large"
                                        variant="contained"
                                        onClick={() => remove(index)}
                                        color="primary"
                                        style={{
                                          justifyContent: "center",
                                          width: "98px",
                                          height: "44px",
                                          marginLeft: "18px",
                                          textTransform: "capitalize",
                                          background:
                                            "linear-gradient(180deg, #255480 0%, #173450 100%)",
                                        }}
                                      >
                                        {t("Remove")}
                                      </Button>
                                    )}
                                  </div>
                                </Grid>
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "20px",
                        marginBottom: "20px",
                        borderTop: "2px solid",
                        marginTop: "20px",
                      }}
                    >
                      <Grid item xs={2}>
                        <Button
                          className="mt-1"
                          size="large"
                          variant="contained"
                          type="submit"
                          color="primary"
                          style={{
                            justifyContent: "center",
                            width: "98px",
                            height: "44px",
                            textTransform: "capitalize",
                            background:
                              "linear-gradient(180deg, #255480 0%, #173450 100%)",
                          }}
                        >
                          {t("Save")}
                        </Button>
                        <Button
                          className="mt-1"
                          disabled={!props?.dirty}
                          onClick={() => props?.resetForm()}
                          size="large"
                          variant="contained"
                          disableElevation
                          color="primary"
                          style={{
                            justifyContent: "center",
                            width: "fit-content",
                            height: "44px",
                            textTransform: "capitalize",
                            background: "#757474",
                            marginLeft: "15px",
                          }}
                        >
                          {t("Clear All")}
                        </Button>
                      </Grid>
                    </div>
                  </form>
             
              );
            }}
          </Formik>
        </div>
      </>
    );
  };
  
  export default AvailabilityAccordingToOtas;
  