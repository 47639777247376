import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";


export const createVendor = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.VENDORS_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateVendorDetail = async (data: object, vendorId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.VENDORS_RESOURCE_ROUTE + `/${vendorId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProductList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.PRODUCTS_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchVendorDetail = async (vendorId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.VENDORS_RESOURCE_ROUTE + `/${vendorId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAvailableRoomsByCat = async (catId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.AVAILABLE_ROOM_BY_CAT + `/${catId}`,
      method: "GET",
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVendorApiCall = async (vendorId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.VENDORS_RESOURCE_ROUTE + `/${vendorId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetcProductTypeSelectList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.PRODUCT_TYPES_SELECT_RESOURCE_ROUTE,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchProductbyTypesDetail = async (productTypeId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.PRODUCT_BY_TYPES_RESOURCE_ROUTE + `/${productTypeId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const createPOSSales = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.POS_SALES_SAVE_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};