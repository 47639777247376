import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

const items = JSON.parse(localStorage.getItem('userDetails'));

export const permissionDetail = async (role_id) => {
    try {
        const result = await instance({
            url: ApiEndPoints.ROLES_PERMISSION + `/${role_id}`,
            method: "GET",
        });
        // return result;
        return result.data;
    } catch (error) {
        throw error;
    }
};