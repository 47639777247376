import { Autocomplete, Box, Button, Checkbox, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import FormikInput1 from "../../../shared/material-ui-formik/FormikInput1";
import ShowInputError from "../../../shared/components/ShowInputError";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import FormikSelect from "../../../shared/material-ui-formik/FormikSelect";
import DialogForm from "../../../shared/components/DialogForm";
import { useState } from "react";
import { fetchList } from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";


const GroupBookingAddInfoDialog = (subProps) => {
    const {t} = useTranslation();
    const CustomPaper = (props) => {
        return <Paper elevation={8} {...props} />;
      };
const {props, idProofTypes, index, bookingDetailsInitialState,
  onSearchInputChangeTaxt,
  searchTaxResult,
  handleTaxSelected,
  taxRateData,
  setBookingDetails,
  setBookingDetailsStats,
  bookingDetails,
  bookingDetailsStats,
  exemptTaxData

} = subProps;
const [selectedGuest, setSelectedGuest] = useState({
    user_id: "",
    name: "",
  });

const [guestInfo,setGuestInfo] = useState({
    user_id: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.user_id,
    name: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.name,
    phone:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.phone,
    phone_code:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.phone_code,
    email:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.email,
    id_number:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.id_number,
    id_proof_type:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.id_proof_type,
    id_proof:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.id_proof,
    image:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.image,
    address:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.address,
    tax_exempt:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_exempt,
    iata_number:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.iata_number,
    country_id:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.country_id,
    state_id:props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.state_id,
    city_id: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.city_id,
    postal_code: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.postal_code,
    tax_id_data: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_id_data,
    tax_exempt_num: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_exempt_num,
    tax_exempt_data: props?.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_exempt_data,
  })  

  const [searchUserResult, setSearchUserResult] = useState([]);

  const searchUserApi = async (searchUserText) => {
    const result = await fetchList(
      ApiEndPoints.USER_AUTOCOMPLETE + `?search_text=${searchUserText}`
    );
    if (result?.status === 200) {
      setSearchUserResult(result.data);
    } else {
      setSearchUserResult([]);
    }
  };

  const onSearchInputChange = async (event) => {
    if (event === null) {
      setSearchUserResult([]);
    } else {
      const { value } = event.target;
      // console.log(value, 61)
      if (value) {
        searchUserApi(value);
      } else {
        setSearchUserResult([]);
      }
    }
  };
  const handleUserSelected = (user) => {
    if (user !== null) {
      setSelectedGuest({
        user_id: user?.id,
        name: user?.name,
      });
      // console.log(user, 2788);
      setGuestInfo((prev)=>({
        ...prev,
        user_id: user?.id,
        name: user?.name,
        phone: user?.mobile,
        email: user?.email,
        phone_code: user?.phone_code,
        id_number: user?.idNumber,
        id_proof_type: user?.id_proof_type,
        id_proof: "",
        image: user.idProofImage?.image,
        address: user?.address,
        country_id: user?.userDetail?.country_id,
        state_id: user?.userDetail?.state_id,
        city_id: user?.userDetail?.city_id,
        postal_code: user?.userDetail?.postal_code,
      }));
      // console.log(user?.country_id, 97)
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.name`, user?.name);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.user_id`, user?.user_id);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone`, user?.mobile);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.email`, user?.email);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone_code`, user?.phone_code);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_number`, user?.idNumber);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_proof_type`, user?.id_proof_type);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_proof`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.image`, user?.idProofImage?.image);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.address`, user?.address);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.country_id`,  user?.userDetail?.country_id);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.state_id`, user?.userDetail?.state_id);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.city_id`, user?.userDetail?.city_id);
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.postal_code`, user?.userDetail?.postal_code);
    } else {
      
      setSelectedGuest({
        user_id: "",
        name: "",
      });
      setGuestInfo((prev)=>({
        ...prev,
        user_id: "",
        name: "",
        phone: "",
        phone_code: "+1",
        email: "",
        id_number: "",
        id_proof_type: "",
        id_proof: "",
        image: "",
        address: "",
        tax_exempt: 0,
        iata_number: "",
        company_profile_name: "",
        corporate_id: 0,
        country_id: "",
        state_id: "",
        city_id: "",
        postal_code: "",
      
      }));
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.name`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.user_id`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.email`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone_code`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_number`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_proof_type`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_proof`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.image`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.address`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.country_id`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.state_id`, "");
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.city_id`, "");
    }
  };  

  const handleGuestInfoDone = () => {
    const data1 = bookingDetailsInitialState?.bookingDetailsInfo?.find(
        (item, indexs) => index === indexs
      );
      if(props?.values?.bookingDetailsInfo[index]?.primaryGuest !== 1){
        props?.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo`,guestInfo);
        data1.groupGuestInfo = guestInfo;
        data1.primaryGuest = props?.values?.bookingDetailsInfo[index]?.primaryGuest;
        props?.setFieldValue(`bookingDetailsInfo.${index}.guestDialog`, false);
        var exemptSum = 0;
        if(guestInfo?.tax_exempt === 1){

          guestInfo?.tax_id_data?.forEach((item) => {
            exemptSum += +item.tax_rate;
          });
        }
        
        data1.discountedTotalPriceWithTax = +(((+data1.discountedTotalPrice/100) * (+taxRateData - exemptSum)) + +data1.discountedTotalPrice).toFixed(2)
        props?.setFieldValue(`bookingDetailsInfo.${index}.discountedTotalPriceWithTax`, data1.discountedTotalPriceWithTax);
        props?.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt_data`, guestInfo?.tax_exempt ===1 ? 
          guestInfo?.tax_id_data?.map((tax)=>({
            tax_exempt_id: tax?.id,
            tax_exempt_amount : ((+data1.discountedTotalPrice/100)* +tax?.tax_rate)
          })): []);
        data1.groupGuestInfo.tax_exempt_data = guestInfo?.tax_id_data?.map((tax)=>({
          tax_exempt_id: tax?.id,
          tax_exempt_amount : ((+data1.discountedTotalPrice/100)* +tax?.tax_rate)
        }));
        
        let newBookingDetail = bookingDetails?.map((item,sIndex)=>(sIndex === index ? {
          ...item,
          groupGuestInfo: {...guestInfo,
          tax_exempt_data: guestInfo?.tax_exempt ===1 ? guestInfo?.tax_id_data?.map((tax)=>({
            tax_exempt_id: tax?.id,
            tax_exempt_amount : ((+data1.discountedTotalPrice/100)* +tax?.tax_rate)
          })) : [],
          },
          primaryGuest: props?.values?.bookingDetailsInfo[index]?.primaryGuest,
          discountedTotalPriceWithTax: +(((+data1.discountedTotalPrice/100) * (+taxRateData - exemptSum)) + +data1.discountedTotalPrice).toFixed(2),
  
        }: {...item}));
        setBookingDetails(newBookingDetail);
  
        let sum = 0;
        newBookingDetail?.forEach((item) => {
          item?.groupGuestInfo?.tax_exempt_data?.forEach((tax)=>{
            sum += +tax?.tax_exempt_amount
          })
        })
  
          let totalCalTax =
        ((bookingDetailsStats?.totalAmount +
          bookingDetailsStats?.extraAdult +
          bookingDetailsStats?.addOnTotal +
          bookingDetailsStats?.extraChild -
          bookingDetailsStats?.discountedTotal) /
          100) *
          Number(taxRateData) -
        sum;
        setBookingDetailsStats((prev) => ({ ...prev, taxAmount: sum }));
        setBookingDetailsStats((prev) => ({
          ...prev,
          totalTax: totalCalTax < 0 ? 0 : totalCalTax,
        }));
        setBookingDetailsStats((prev) => ({
          ...prev,
          finslTotal:
            bookingDetailsStats.totalAmount +
            bookingDetailsStats.extraAdult +
            bookingDetailsStats.addOnTotal +
            bookingDetailsStats.extraChild -
            bookingDetailsStats.discountedTotal +
            (totalCalTax < 0 ? 0 : totalCalTax),
        }));
      } else {
        var exemptSum = 0;
        exemptTaxData?.forEach((item) => {
          exemptSum += +item.tax_rate;
        });

        let newBookingDetail = bookingDetails?.map((item,sIndex)=>(sIndex === index ? {
          ...item,
          groupGuestInfo: {...item?.groupGuestInfo,
          tax_id_data: exemptTaxData,
          tax_exempt_data: exemptTaxData?.map((tax)=>({
            tax_exempt_id: tax?.id,
            tax_exempt_amount : ((+data1.discountedTotalPrice/100)* +tax?.tax_rate)
          })),
          },
          primaryGuest: props?.values?.bookingDetailsInfo[index]?.primaryGuest,
          discountedTotalPriceWithTax: +(((+data1.discountedTotalPrice/100) * (+taxRateData - exemptSum)) + +data1.discountedTotalPrice).toFixed(2),
  
        }: {...item}));
        setBookingDetails(newBookingDetail);
  
        let sum = 0;
        newBookingDetail?.forEach((item) => {
          item?.groupGuestInfo?.tax_exempt_data?.forEach((tax)=>{
            sum += +tax?.tax_exempt_amount
          })
        })
  
          let totalCalTax =
        ((bookingDetailsStats?.totalAmount +
          bookingDetailsStats?.extraAdult +
          bookingDetailsStats?.addOnTotal +
          bookingDetailsStats?.extraChild -
          bookingDetailsStats?.discountedTotal) /
          100) *
          Number(taxRateData) -
        sum;
        setBookingDetailsStats((prev) => ({ ...prev, taxAmount: sum }));
        setBookingDetailsStats((prev) => ({
          ...prev,
          totalTax: totalCalTax < 0 ? 0 : totalCalTax,
        }));
        setBookingDetailsStats((prev) => ({
          ...prev,
          finslTotal:
            bookingDetailsStats.totalAmount +
            bookingDetailsStats.extraAdult +
            bookingDetailsStats.addOnTotal +
            bookingDetailsStats.extraChild -
            bookingDetailsStats.discountedTotal +
            (totalCalTax < 0 ? 0 : totalCalTax),
        }));
      }
  }

  const handleGuestInfoClose = () => {
    const data1 = bookingDetailsInitialState?.bookingDetailsInfo?.find(
        (item, indexs) => index === indexs
      );
      props?.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo`,data1.groupGuestInfo);
      props?.setFieldValue(`bookingDetailsInfo.${index}.guestDialog`, false);
      props?.setFieldValue(`bookingDetailsInfo.${index}.primaryGuest`, data1?.primaryGuest);

  }  

  // console.log(bookingDetailsInitialState?.bookingDetailsInfo, 180);
   

  const [address, setAddress] = useState("");
  const handlePostalSearch = async (e) => {
    setGuestInfo((prevState) => ({
      ...prevState,
      postal_code: e.target.value,
    }));
    props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.postal_code`,  e.target.value);

    if (e.target.value?.length < 4) {
      return;
    } else {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            e.target.value
          )}&key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}`
        );

        const data = await response.json();

        if (data.results && data.results.length > 0) {
          const result = data.results[0];

          // Extract city, state, and country from address components
          result.address_components.forEach((component) => {
            if (component.types.includes("locality")) {
              setGuestInfo((prevState) => ({
                ...prevState,
                city_id: component.long_name,
              }));
            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.city_id`, component.long_name);

              // setCity(component.long_name);
            } else if (
              component.types.includes("administrative_area_level_1")
            ) {
              setGuestInfo((prevState) => ({
                ...prevState,
                state_id: component.long_name,
              }));
            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.state_id`, component.long_name);

              // setState(component.long_name);
            } else if (component.types.includes("country")) {
              setGuestInfo((prevState) => ({
                ...prevState,
                country_id: component.long_name,
              }));
            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.country_id`, component.long_name);

              // setCountry(component.long_name);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching geocoding data:", error);
      }
    }
  };

  const handleSelectAddress = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      // const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);

      // Extract city, state, country, and postal code from address components
      results[0].address_components.forEach((component) => {
        // console.log(component, 171);

        if (component.types.includes("locality")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            city_id: component.long_name,
          }));
          
          props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.city_id`, component.long_name);
        } else if (component.types.includes("administrative_area_level_1")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            state_id: component.long_name,
          }));
          props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.state_id`, component.long_name);
        } else if (component.types.includes("country")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            country_id: component.long_name,
          }));
          props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.country_id`, component.long_name);
   
        } else if (component.types.includes("postal_code")) {
          setGuestInfo((prevState) => ({
            ...prevState,
            postal_code: component.long_name,
          }));
          props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.postal_code`, component.long_name);
        }
      });
      setGuestInfo((prevState) => ({
        ...prevState,
        address: selectedAddress,
      }));
      props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.address`, selectedAddress);

    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

    return (
        <DialogForm
        scroll="paper"
        maxWidth="lg"
        // maxWidth="mb"
        title={t("Add Secondary Guest Info")}
        openDialog={props?.values?.bookingDetailsInfo[index]?.guestDialog}
        handleDialogClose={handleGuestInfoClose}
        bodyContent={<div className="p-2">
            <Box className="d-flex align-items-center mb-1">
              <Checkbox
                onClick={() => {props?.setFieldValue(`bookingDetailsInfo.${index}.primaryGuest`, 
                  props.values?.bookingDetailsInfo[index]?.primaryGuest === 1 ? 0 : 1)}}
                checked={props?.values?.bookingDetailsInfo[index]?.primaryGuest === 1}
              />
              <Typography sx={{pt:"2px"}}>{t("Is Primary Guest")}</Typography>
            </Box>
            {props?.values?.bookingDetailsInfo[index]?.primaryGuest === 0 && (<Grid container spacing={2} alignItems="flex-start">
                            {searchUserResult && (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl fullWidth>
                              <Autocomplete
                              id="combo-box-demo"
                              options={searchUserResult}
                              value={selectedGuest}
                              onInputChange={onSearchInputChange}
                              onChange={(event, value) =>
                                handleUserSelected(value)
                              }
                              getOptionLabel={(option) => (option?.name || "") }
                              renderOption={(props, option) => (
                                // Use a unique key but don't display option.id
                                <li {...props} key={option.id}>
                                {option.name + " - " + (option?.email || "") + " - " + (option?.mobile || "")}

                                </li>
                              )}
                              PaperComponent={CustomPaper}
                              renderInput={(params, i) => (
                                <TextField
                                  {...params}
                                  label={t("Search by name, email, mobile")}
                                  variant="outlined"
                                  key={params?.id || i}
                                />)}
                                />
                          </FormControl>
                        </Grid>
                              )}
                              <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Full Name")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.name`}
                          placeHolder={t("Firstname Lastname")}
                          maxLength={150}
                          style={{ width: "100%" }}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              name: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.name`, e.target.value)
                          }}
                          component={FormikInput1}
                          
                        />
                        <ErrorMessage name={`bookingDetailsInfo.${index}.groupGuestInfo.name`}  component={ShowInputError} />
                      </Grid>
                      <Grid
                        item
                        lg={2}
                        md={3}
                        sm={12}
                        xs={12}
                        style={{ top: "-3px" }}
                      >
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",
                          }}
                        >
                          {t("Phone")}
                        </Typography>
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={guestInfo.phone_code}
                          onChange={(phone) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              phone_code: `+${phone}`,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone_code`, `+${phone}`)

                          }}
                          specialLabel=""
                          inputStyle={{
                            width: "100%",
                            border: "1px solid #B4ADAD !important",
                            borderRadius: "12px !important",
                            height: "50px",
                          }}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={3} sm={12} xs={12}>
                        <Field
                          style={{ width: "100%",  }}
                          className={"mt-md-2"}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.phone`}
                          type="number"
                          placeHolder={t("Phone")}
                          maxLength={150}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              phone: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.phone`, e.target.value);

                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage name={`bookingDetailsInfo.${index}.groupGuestInfo.phone`} component={ShowInputError} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Email")}
                          type="email"
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.email`}
                          placeHolder={t("Email")}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.email`, e.target.value)

                          }}
                          maxLength={150}
                          component={FormikInput1}
                        />
                        <ErrorMessage name={`bookingDetailsInfo.${index}.groupGuestInfo.email`} component={ShowInputError} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("Id Number")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.id_number`}
                          placeHolder="KC 00000"
                          maxLength={150}
                          onBlur={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              id_number: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_number`, e.target.value)

                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.id_number`}
                          component={ShowInputError}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",

                          }}
                        >
                          {t("ID Proof Type")}
                        </Typography>
                        <Field
                          label={t("ID Proof Type")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.id_proof_type`}
                          defaultOption
                          options={idProofTypes?.map((type) => ({
                            title: t(type.value),
                            value: type.id,
                          }))}
                          onChange={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              id_proof_type: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.id_proof_type`, e.target.value)

                          }}
                          component={FormikSelect}
                        />
                        <ErrorMessage
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.id_proof_type`}
                          component={ShowInputError}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Field
                          label={t("IATA")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.iata_number`}
                          placeHolder="IATA"
                          maxLength={150}
                          onChange={async (e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              iata_number: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.iata_number`, e.target.value)

                          }}
                          component={FormikInput1}
                        />
                        <ErrorMessage name={`bookingDetailsInfo.${index}.groupGuestInfo.iata_number`} component={ShowInputError} />
                      </Grid>
                      <Grid
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          style={{ marginTop: "30px" }}
                        >
                          <Field
                            label={t("Tax Exempt")}
                            name={`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt`}
                            id="tax_exempt"
                            type="checkbox"
                            onChange={async (e) => {
                              // setTax_exempt_num(0);
                              const { checked } = e.target;
                              if (checked) {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt: 1,
                                }));
                                props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt`, 1);
                              } else {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt: 0,
                                }));
                                props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt`, 0);
                                props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_id_data`, []);
                                props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt_data`, []);
                                // handleTaxSelected([]);
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_id_data: [],
                                  tax_exempt_data: []
                                }));
                              }
                            }}
                            checked={
                              props.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_exempt === 1 ? true : false
                            }
                            component={Checkbox}
                          />
                          {t("Tax Exempt")}
                        </Grid>
                    
                      {props.values?.bookingDetailsInfo[index]?.groupGuestInfo?.tax_exempt === 1 && (
                        <>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Field
                              label={t("Tax Exempt code")}
                              name={`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt_num`}
                              placeHolder="Tax Exempt Code"
                              // maxLength={150}
                              onBlur={async (e) => {
                                setGuestInfo((prevState) => ({
                                  ...prevState,
                                  tax_exempt_num: e.target.value,
                                }));
                              }}
                              component={FormikInput1}
                              readOnly
                            />
                            <ErrorMessage
                              name={`bookingDetailsInfo.${index}.groupGuestInfo.tax_exempt_num`}
                              component={ShowInputError}
                            />
                          </Grid>
                          <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography
                            id="font-600"
                              style={{
                                marginBottom: "11px",
                                color: "rgb(30, 30, 30)",
                                fontSize: "16px",
                                lineHeight: "24px",
                              }}
                            >
                              {t("Tax ID")}
                            </Typography>
                            <FormControl fullWidth>
                              <Autocomplete
                                id="combo-box-demo"
                                size="small"
                                sx={{ padding: "3px" }}
                                // name= "tax_id"
                                options={searchTaxResult.filter(
                                  (item) => item?.is_exempt_tax === 1
                                )}
                                onClick={onSearchInputChangeTaxt}
                                multiple={true}
                                // readOnly={tax_exempt_num === 123456 ? false : true}
                                onChange={(event, value) => {
                                  // handleTaxSelected(value);
                                  setGuestInfo((prevState) => ({
                                    ...prevState,
                                    tax_id_data: value,
                                  }));
                                props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.tax_id_data`, value);

                                  // console.log(1791,'v', value)
                                }}
                                value={guestInfo?.tax_id_data  || []}
                                getOptionLabel={(option) => option?.name || ''}
                                PaperComponent={CustomPaper}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t("Exempt Tax")}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </FormControl>

                            <ErrorMessage
                              name={`bookingDetailsInfo.${index}.groupGuestInfo.Tax Id`}
                              component={ShowInputError}
                            />
                          </Grid>
                          {/* } */}
                        </>
                      )}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            // marginRight: "-90px",
                            borderTop: "2px solid",
                          }}
                        ></div>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Typography
                        id="font-600"
                          style={{
                            marginBottom: "11px",
                            color: "rgb(30, 30, 30)",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 500,
                          }}
                        >
                          {t("Search By Google")}
                        </Typography>
                        <PlacesAutocomplete
                          value={address}
                          onChange={setAddress}
                          onSelect={handleSelectAddress}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: t("Search Places ..."),
                                  className: "location-search-input",
                                })}
                                style={{
                                  padding: '14px',
                                  border: "1px solid #B4ADAD",
                                  color: '#255480',
                                  borderRadius: '6px',
                                  width: '100%',
                                  position: 'relative'
                                }}
                              />
                              <div
                                className="autocomplete-dropdown-container"
                                style={{
                                  position: "absolute",
                                  outline: "none",
                                  zIndex: "1",
                                  listStyle: "none",
                                  padding: "0",
                                  margin: "10px 0 10px -1px",
                                  boxShadow: "1px 2px 18px rgba(0,0,0,0.25)",
                                  backgroundColor: "white",
                                  width: "100%",
                                  maxHeight: "220px",
                                  overflowY: "scroll",
                                  borderRadius: "7px",
                                }}
                              >
                                {loading && (
                                  <div
                                    style={{
                                      padding: "5px",
                                      borderBottom: "1px solid #fafafa",
                                    }}
                                  >
                                    {t("Loading...")}
                                  </div>
                                )}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                        padding: "5px",
                                        borderBottom: "1px solid #fafafa",
                                      }
                                    : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                        padding: "5px",
                                        borderBottom: "1px solid #fafafa",
                                      };
                                  return (
                                    <div
                                      key={suggestion.placeId}
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                      
                        <Field
                          label={t("Address")}
                          placeHolder={t("Enter Address")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.address`}
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              address: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.address`, e.target.value)
                            
                          }}
                          id="address"
                          component={FormikInput1}
                        />
                        
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("Country")}
                          placeHolder={t("Enter Country")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.country_id`}
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              country_id: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.country_id`, e.target.value)

                          }}
                          id="country_id"
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("State")}
                          placeHolder={t("Enter State")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.state_id`}
                          id="state_id"
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              state_id: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.state_id`, e.target.value)

                          }}
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("City")}
                          placeHolder={t("Enter City")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.city_id`}
                          onChange={(e) => {
                            setGuestInfo((prevState) => ({
                              ...prevState,
                              city_id: e.target.value,
                            }));
                            props.setFieldValue(`bookingDetailsInfo.${index}.groupGuestInfo.city_id`, e.target.value)

                          }}
                          id="city_id"
                          component={FormikInput1}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Field
                          label={t("Postal Code")}
                          placeHolder={t("Enter Postal Code")}
                          name={`bookingDetailsInfo.${index}.groupGuestInfo.postal_code`}
                          onChange={(e) => handlePostalSearch(e)}
                          id="postal_code"
                          component={FormikInput1}
                        />
                      </Grid>
                      
                     </Grid>)}
                     <Box className="d-flex mt-1 justify-content-end">
                      <Button
                      onClick={handleGuestInfoDone}
                      className="comman-button">
                        {t("Done")}
                      </Button>
                      </Box>
        </div>}
        />
        
    )
};

export default GroupBookingAddInfoDialog