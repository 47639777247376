import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkInCheckOutList: [],
  bookingInfo: null,
};
const bookingSlice = createSlice({
  name: "booking",
  initialState: initialState,
  reducers: {
    checkInCheckOutListSuccess: (state, action) => {
      state.checkInCheckOutList = action.payload;
    },
    checkInCheckOutListFailed: (state, action) => {
      state.checkInCheckOutList = [];
    },
    bookingInfoSuccess: (state, action) => {
      state.bookingInfo = action.payload;
    },
    bookingInfoFailed: (state, action) => {
      state.bookingInfo = null;
    },
  },
});

export const {
  checkInCheckOutListSuccess,
  checkInCheckOutListFailed,
  bookingInfoSuccess,
  bookingInfoFailed,
} = bookingSlice.actions;
export default bookingSlice.reducer;
