import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CustomSwitchSelector = ({
  options,
  onChange,
  selectedBackgroundColor,
  selectedFontColor,
  value,
  backgroundColor,
  fontColor,
  border,
  borderRadius,
  padding,
  outerBorder,
  innerMargin,
  disabled,
}) => {
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOptionChange = (value) => {
    if(disabled){
      return;
    }
    setSelectedOption(value);
    onChange(value);
  };

  useEffect(()=>{
    setSelectedOption(value);
  },[value])

  // console.log(value, 25)
  //   console.log(selectedOption, 11)
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: backgroundColor?backgroundColor :"#ecf0f1",
        borderRadius: borderRadius ? borderRadius : "12px",
        justifyContent: "space-between",
        border: outerBorder?outerBorder:"",
      }}
    >
      {options.map((option) => (
        <div
          key={option.value}
          onClick={() => handleOptionChange(option.value)}
          style={{
            cursor: "pointer",
            backgroundColor:
              option.value === selectedOption ? selectedBackgroundColor : "",
            color: option.value === selectedOption ? selectedFontColor : fontColor? fontColor : "#000",
            padding: padding? padding : "5px 10px",
            margin: innerMargin? innerMargin :"1px",
            borderRadius: borderRadius? borderRadius: "12px",
            border:
              option.value === selectedOption ? (border? border : "1px solid #ffffff" ): "0px",
            wordBreak: "break-word",
            userSelect: "none",
            minWidth: "50px",
          }}
        >
          {t(option.label)}
        </div>
      ))}
    </div>
  );
};

export default CustomSwitchSelector;


