import {
  Button,
  Grid,
  InputLabel,
} from "@mui/material";
import { Field, Formik } from "formik";
// import { connect } from 'http2';
import React from "react";
import { connect } from "react-redux";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { useTranslation } from "react-i18next";

const General = (props) => {
  const [alignment, setAlignment] = React.useState("left");
  const { t, i18n } = useTranslation();

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const getCurrentState = () => {
    return props.state;
  };

  const tableHeading = [
    {
      name: "Rate name",
    },
    {
      name: "Meals",
    },
    {
      name: "Cancellation policy",
    },
    {
      name: "Source",
    },
    {
      name: "Sales status",
    },
  ];

  const data = [
    {
      Booking: "Standard",
      Room: "No meals",
      Guests: "Free cancellation policy",
      Chat: "Reception",
      Nights: "2",
    },
  ];

  const [checked, setChecked] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [tabName, setTabName] = React.useState("general");

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  return (
    <div
      className="d-block p-2"
      style={{ background: "#fff", border: "1px solid gray" }}
    >
      <h5 className="mb-1" style={{ color: "gray" }}>
        {t("CHECK-IN TIMES")}
      </h5>
      <div className="d-flex mb-3">
        {/* <div className='d-block'> */}
        <Formik>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel>{t("Check in time")}</InputLabel>
              <Field
                // label="Email"
                type="text"
                name="userName"
                id="userName"
                autoComplete="off"
                maxLength={150}
                component={FormikSelect}
                // onClick={() => dispatch(setProps({"name":"nskn"}))}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t("Check Out time")}</InputLabel>
              <Field
                // label="Email"
                type="text"
                name="userName"
                id="userName"
                autoComplete="off"
                maxLength={150}
                component={FormikSelect}
                // onClick={() => dispatch(setProps({"name":"nskn"}))}
              />
            </Grid>
          </Grid>
        </Formik>
        {/* </div> */}
      </div>
      {/* <h5 className='mb-1' style={{color:"gray"}}>RATES FOR CHILDREN</h5>
        <div className='d-flex'>
            <Formik>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputLabel>Are there different room rates for children than for adults?</InputLabel>
                  <Field
                    // label="Email"
                    type="text"
                    name="userName"
                    id="userName"
                    autoComplete="off"
                    maxLength={150}
                    component={FormikSelect}

                  />
                </Grid>
              </Grid>
            </Formik>
        </div> */}
      <div className="w-100 d-flex justify-content-center">
        <Button
          className="mt-1 mr-1 comman-cancel-button"
          size="large"
          variant="contained"
          disableElevation
          color="primary"
          // style={{
          //   justifyContent: "center",
          //   marginRight: "10px",
          //   width: "98px",
          //   height: "44px",
          //   textTransform: "capitalize",
          //   background: "gray",
          // }}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="mt-1 comman-button"
          size="large"
          variant="contained"
          disableElevation
          color="primary"
          // style={{
          //   justifyContent: "center",
          //   width: "98px",
          //   height: "44px",
          //   textTransform: "capitalize",
          //   background: "linear-gradient(180deg, #255480 0%, #173450 100%)",
          // }}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(General);
