export enum BookingTabs {
  BookRoom = "book-a-room",
  AddInfo = "add-info",
  Confirm = "confirm",
}


export enum CheckInTabs {
  EnterName = "enter-name",
  VerifyDetail = "verify-detail",
  SelectBooking = "select-booking",
  // checkIn = "check-in"
}