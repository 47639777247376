import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productTypesList: [],
  productTypeDetail: null,
  posTableList: [],
  refreshTable: false,
  posKitchenTypesList: [],
  refreshKitchenTypes: false,

};
const posSlice = createSlice({
  name: "pos",
  initialState: initialState,
  reducers: {
    productTypesListSuccess: (state, action) => {
      state.productTypesList = action.payload;
    },
    productTypesListFailed: (state, action) => {
      state.productTypesList = [];
    },
    productTypeDetailSuccess: (state, action) => {
      state.productTypeDetail = action.payload;
    },
    productTypeDetailFailed: (state, action) => {
      state.productTypeDetail = null;
    },
    posTableListSuccess: (state, action) => {
      state.posTableList = action.payload;
    },
    posTableListFailed: (state, action) => {
      state.posTableList = [];
    },
    refreshTableSuccess: (state, action) => {
      state.refreshTable = true;
    },
    refreshTableFailed: (state, action) => {
      state.refreshTable = false;
    },
    posKitchenTypesListSuccess: (state, action) => {
      state.posKitchenTypesList = action.payload;
    },
    posKitchenTypesListFailed: (state, action) => {
      state.posKitchenTypesList = [];
    },
    refreshposKitchenTypesuccess: (state, action) => {
      state.refreshKitchenTypes = true;
    },
    refreshposKitchenTypesFailed: (state, action) => {
      state.refreshKitchenTypes = false;
    },
  },
});

export const {
  productTypesListSuccess,
  productTypesListFailed,
  productTypeDetailSuccess,
  productTypeDetailFailed,
  posTableListSuccess,
  posTableListFailed,
  refreshTableSuccess,
  refreshTableFailed,
  posKitchenTypesListSuccess,
  posKitchenTypesListFailed,
refreshposKitchenTypesuccess,
refreshposKitchenTypesFailed,
} = posSlice.actions;
export default posSlice.reducer;
