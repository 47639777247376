import { CircularProgress } from '@mui/material';
import React from 'react';

const SmallLoader: React.FC = () => {
    return (
        <div id="loader" className="small-loader-wrapper">
            <CircularProgress color="primary" thickness={3} size={44} />
        </div>
    );
};

export default SmallLoader;