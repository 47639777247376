import {
  Box,
  Typography,
  Button,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  TextField,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Card,
  CardMedia,
  CircularProgress,
  Chip,
} from "@mui/material";
import "react-phone-input-2/lib/material.css";
import React, { useEffect, useState } from "react";
import { BookingTabs } from "../../shared/enums/new-booking";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { Field, FieldArray, Formik } from "formik";
import { getCancellationPolicy } from "../../utils/helper";
import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
import moment from "moment";
import { useTranslation } from "react-i18next";
// import { useTheme } from "@emotion/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DialogForm from "../../shared/components/DialogForm";
import Loader from "../../layout/Loader";
import SmallLoader from "../../layout/SmallLoader";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { CancelationPolicyDialog } from "./BookingDialog/CancelationPolicyDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }} className="pb-0">
          {children}
        </Box>
      )}
    </div>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export const BookARoom = (props) => {
  const { t } = useTranslation();

  // const theme = useTheme();
  // const images = [
  //   "https://images.unsplash.com/photo-1524419986249-348e8fa6ad4a?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c2FtcGxlfGVufDB8fDB8fHww",
  //   "https://unsplash.com/photos/sliced-lemon-beside-silver-knife-on-brown-wooden-table-PQewPJqNKwQ",
  //   "https://images.unsplash.com/photo-1561336313-0bd5e0b27ec8?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8c2FtcGxlfGVufDB8fDB8fHww",
  //   // Add more image URLs here
  // ];

  const [openImageShowDialog, setOpenImageShowDialog] = useState(0);
  const handleCloseImageShowDialog = () => {
    setOpenImageShowDialog(0);
  };

  const [hotelIndex, setHotelIndex] = useState(0);

  const [loading, setLoading] = useState(false);

  const nextImage = (data, setFieldValue, index) => {
    setLoading(true);
    setHotelIndex(index + 1);
    setFieldValue(
      `bookingRateInfo.${index}.currentImage`,
      (data?.currentImage + 1) % +data?.roomCategoryImages?.length
    );
  };

  const prevImage = (data, setFieldValue, index) => {
    setLoading(true);
    setHotelIndex(index + 1);
    setFieldValue(
      `bookingRateInfo.${index}.currentImage`,
      (data?.currentImage - 1 + +data?.roomCategoryImages?.length) %
        +data?.roomCategoryImages?.length
    );
  };

  const handleImageLoaded = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
  };

  const [openCancelation, setOpenCancelation] = useState(false);

  let {
    tabValue,
    bookingType,
    handleChangeBookingType,
    walkin,
    searchFilters,
    handleCheckInDate,
    handleCheckOutDate,
    handleGuestChange,
    bookingDetailsStats,
    handleChangeBooking,
    handleClearBookings,
    bookRoomInitialState,
    bookRoomHandleSubmit,
    handleRoomRateDropdownChange,
    handleMaleOption,
    addOnList,
    handleDiscountRate,
    discountedRate,
    handledefaultSelectedRoom,
    handleExtraAdult,
    handleDefaultAdult,
    handleExtraChild,
    handleDefaultChild,
    handleAddRoomItem,
    roomAvailableData,
    onlyCard,
    handlePreviousTabChange,
    handleNextTabChange,
    MinStayLength,
    roomRateId,
    handleChangeRoomRate,
    bookingRates,
    bookingDetails
  } = props;

  const { state } = useLocation();

  // console.log(bookRoomInitialState,handleDiscountRate, 127)
  // const mediaMatch = window.matchMedia("(min-width: 500px)");
  // const [matches, setMatches] = useState(mediaMatch.matches);

  const [selectedRoomType, setSelectedRoomType] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeRoomType = (e) => {
    setSelectedRoomType(
      typeof e?.target?.value === "string"
        ? e?.target?.value.split(",")
        : e?.target?.value
    );
    handleClose();
  };

  const handleDeleteOta = (e, value) => {
    setSelectedRoomType((oldValues) => {
      return oldValues.filter((item) => item !== value);
    });
  };

  useEffect(() => {
    if (bookRoomInitialState?.bookingRateInfo?.length > 0) {
      if (state?.selectedDate?.length > 0) {
        setSelectedRoomType([state?.selectedDate[0]?.type_id]);
      } else if(bookRoomInitialState?.bookingRateInfo?.filter((avl)=>+avl?.available_rooms > 0)?.length > 0){
        setSelectedRoomType([
          bookRoomInitialState?.bookingRateInfo?.filter((avl)=>+avl?.available_rooms > 0)[0]?.category_id,
        ]);
      }
    }
  }, [bookRoomInitialState?.bookingRateInfo?.length, state]);

  // console.log(bookRoomInitialState?.bookingRateInfo, 192);

  return (
    <TabPanel value={tabValue} index={BookingTabs.BookRoom}>
      {/* <BookARoom handleTabChangeAction={handleTabChange} /> */}
      {/* <BookARoom /> */}
      {/* Book Room Component Start Here */}
      <>
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          style={{ marginBottom: "20px" }}
        >
          <Grid item md={2} sm={12} xs={12}>
            <Typography
              style={{
                marginBottom: "5px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: 500,
              }}
            >
              {t("Booking Type")}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={bookingType}
              exclusive
              onChange={!onlyCard ? handleChangeBookingType : null}
              aria-label="Platform"
            >
              <ToggleButton size="small" value={1} disabled={
                bookingType === 1 || bookingDetails?.length > 1
                }>
                {t("Single")}
              </ToggleButton>
              {!onlyCard && (
                <ToggleButton
                  size="small"
                  value={2}
                  disabled={bookingType === 2}
                >
                  {t("Group")}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Grid>
          <Grid item  md={3} xs={12}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Check In Date")}
              </Typography>
              <TextField
                type="date"
                inputProps={{ min: moment().format("YYYY-MM-DD") }}
                disabled={walkin ? true : false}
                value={searchFilters.startDate}
                onChange={handleCheckInDate}
              />
            </FormControl>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Check Out Date")}
              </Typography>
              <TextField
                type="date"
                inputProps={{
                  min: moment(
                    moment(searchFilters.startDate, "YYYY-MM-DD").add({
                      days: MinStayLength ? +MinStayLength : 1,
                    })
                  ).format("YYYY-MM-DD"),
                 
                }}
                value={searchFilters?.endDate}
                onChange={handleCheckOutDate}
              />
            </FormControl>
          </Grid>

          <Grid item lg={3} xs={12}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("No Of Guest")}
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={1}
                onChange={handleGuestChange}
                value={searchFilters?.noOfGuest}
              >
                {Array.from({ length: searchFilters?.noOfGuest > 10 ? searchFilters?.noOfGuest + 2 :10 }, (_, i) => i + 1).map((data) => (
                  <MenuItem key={data} value={data}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          alignItems="end"
          sx={{
            position: "sticky",
            top: handleDiscountRate ?{ sm:"61px", xs:"170px"  }: "-15px",
            zIndex: "1111",
          }}
        >
          <Grid item md={12} sm={12} xs={12}>
            <div
              style={{
                display: "flex",
                background: "#382DAE",
                border: "1px solid #F2F2F2",
                borderRadius: "6px",
                // width: "97%",
                height: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                // color: "#FFFFFF",
                padding: "10px 10px",
              }}
            >
              {tabValue !== BookingTabs.BookRoom ? (
                <ArrowBackIosIcon
                  onClick={() => handlePreviousTabChange(tabValue)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ffffff",
                    color: "#382DAE",
                    padding: "5px 0px 5px 10px",
                    // paddingLeft: "16px",
                    borderRadius: "6px",
                    boxSizing: "content-box",
                  }}
                />
              ) : (
                <div></div>
              )}
              <h1
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "unset",
                }}
              >
                {t("Booking details")}: {bookingDetailsStats?.noOfRooms}{" "}
                {t("room(s)")}| {bookingDetailsStats?.noOfAdults}{" "}
                {t("adult(s)")}, {bookingDetailsStats?.noOfChild}{" "}
                {t("child(ren)")} | $
                {Number(bookingDetailsStats?.finslTotal).toFixed(2)} |{" "}
                {Number(bookingDetailsStats?.noOfRooms) > 0 && (
                  <>
                    <span
                      onClick={handleChangeBooking}
                      style={{
                        color: "#2499D1",
                        cursor: "pointer",
                      }}
                    >
                      {t("Change")}
                    </span>{" "}
                    |{" "}
                    <span
                      onClick={handleClearBookings}
                      style={{
                        color: "#DD4B39",
                        cursor: "pointer",
                      }}
                    >
                      {t("Clear")}
                    </span>
                  </>
                )}
              </h1>
              {tabValue !== BookingTabs.Confirm ? (
                <ArrowForwardIosIcon
                  onClick={() => handleNextTabChange(tabValue)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#ffffff",
                    color: "#382DAE",
                    padding: "5px",
                    borderRadius: "6px",
                    boxSizing: "content-box",
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Grid>
        </Grid>
        {/*  Select Rate */}

        {/* room info */}

        <Formik
          initialValues={bookRoomInitialState}
          onSubmit={bookRoomHandleSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          // validationSchema={BookRoomsValidator}
        >
          {(props) => {
            // console.log(window.innerWidth, 301)
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container>
                  <FieldArray name="rooms_info">
                    {({ push, remove }) => (
                      <Grid
                        container
                        //   spacing={2}
                        sx={{ marginTop: 2 }}
                      >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography
                          id="font-600"
                            style={{
                              marginBottom: "11px",
                              color: "#000",
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {t("Select Room Type")}
                          </Typography>
                          <FormControl
                            sx={{
                              m: 0,
                              // marginRight: "10px",
                              // marginTop: "20px",
                              width: "100%",
                            }}
                            size="small"
                          >
                            <InputLabel
                              id="test-select-label"
                              style={{
                                color: "#1E1E1E",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {t("Room Type")}
                            </InputLabel>
                            <Select
                              value={selectedRoomType}
                              onChange={handleChangeRoomType}
                              style={{ height: "auto", width: "100%" }}
                              multiple
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    height: "fit-content",
                                  },
                                },
                              }}
                              className="multi-select-height"
                              open={open}
                              onClose={handleClose}
                              onOpen={handleOpen}
                              // label={"Room Type"}
                              labelId="test-select-label"
                              input={
                                <OutlinedInput
                                  id="select-multiple-chip"
                                  label={t("Room Type")}
                                />
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected?.map((value) => {
                                    return (
                                      <React.Fragment key={value}>
                                        {value !== 0 && (
                                          <Chip
                                            key={value}
                                            label={
                                              props.values.bookingRateInfo?.filter(
                                                (item) =>
                                                  item?.category_id === value
                                              )?.length > 0
                                                ? props.values.bookingRateInfo?.filter(
                                                    (item) =>
                                                      item?.category_id ===
                                                      value
                                                  )[0].category_name
                                                : ""
                                            }
                                            clickable
                                            deleteIcon={
                                              <CancelIcon
                                                onMouseDown={(event) =>
                                                  event.stopPropagation()
                                                }
                                              />
                                            }
                                            onDelete={(e) =>
                                              handleDeleteOta(e, value)
                                            }
                                            onClick={() =>
                                              console.log("clicked chip")
                                            }
                                          />
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </Box>
                              )}
                            >
                              {props.values.bookingRateInfo?.filter((avl)=>+avl?.available_rooms > 0)?.map((item) => (
                                <MenuItem
                                  key={item.category_id}
                                  value={item.category_id}
                                >
                                  {item?.category_name +
                                    " - " +
                                    item?.available_rooms +
                                    " Rooms Available"}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          alignItems="flex-start"
                          className="mt-1"
                        >
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box>
                              <Typography
                                // id={"font-600"}
                                style={{
                                  marginBottom: "11px",
                                  color: "rgb(30, 30, 30)",
                                  fontSize: "16px",
                                  fontWeight: "700",
                                }}
                              >
                                {t("Select Rate")}
                              </Typography>
                              <FormControl
                                sx={{ m: 0, marginRight: "10px" }}
                                size="small"
                              >
                                <InputLabel
                                  id="test-select-label"
                                  style={{
                                    color: "#1E1E1E",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {t("Select Rate")}
                                </InputLabel>
                                <Select
                                  value={roomRateId}
                                  onChange={handleChangeRoomRate}
                                  sx={{
                                    height: "34px",
                                    borderRadius: "12px",
                                    minWidth: "140px",
                                  }}
                                  // displayEmpty
                                  label={t("Select Rate")}
                                  // inputProps={{ "aria-label": "Without label" }}
                                  input={
                                    <OutlinedInput
                                      id="select-multiple-chip"
                                      label={t("Select Rate")}
                                    />
                                  }
                                >
                                  <MenuItem disabled value={0}>
                                    {t("Select Room Rate")}
                                  </MenuItem>
                                  {bookingRates?.length > 0 &&
                                    bookingRates[0]?.rateDetails?.map(
                                      (item) => {
                                        return (
                                          <MenuItem
                                            value={item?.category_rate_type_id}
                                            key= {item?.category_rate_type_id}
                                          >
                                            {item?.name}
                                            {/* {t('nameD', { name: item?.name })} */}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Select>
                                {/* <FormHelperText>Without label</FormHelperText> */}
                              </FormControl>
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography
                              style={{
                                marginBottom: "11px",
                                color: "rgb(30, 30, 30)",
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              {t("Cancellation policy")}
                            </Typography>
                            {props.values.bookingRateInfo?.length > 0 && (
                              <div className="d-flex">
                                <Typography variant="subtitle2">
                                  {
                                    props.values.bookingRateInfo[0]
                                      ?.cancelation_policy
                                  }
                                </Typography>
                                <InfoIcon
                                  onClick={() => setOpenCancelation(true)}
                                  style={{
                                    cursor: "pointer",
                                    color: "#B5BAC6",
                                  }}
                                  className="ml-1 w-fit"
                                />
                                <CancelationPolicyDialog
                                  setOpenCancelation={setOpenCancelation}
                                  openCancelation={openCancelation}
                                  description={
                                    props.values.bookingRateInfo[0]
                                      ?.cancelation_policy_desc
                                  }
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>

                        {props.values.bookingRateInfo.map(
                          (data: any, index) => (
                            <div
                              key={index}
                              style={{
                                display: !selectedRoomType.includes(
                                  data?.category_id
                                )
                                  ? "none"
                                  : "",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",

                                  marginBottom: "40px",
                                  borderTop: "2px solid",
                                  marginTop: "40px",
                                }}
                              ></div>
                              {/* <div > */}
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  // width: "90%",
                                  // borderRight: "1px solid",
                                  bottom: "-15px",
                                  marginLeft: "-10px",
                                }}
                                id="BookingContainer"
                              >
                                {!handleDiscountRate &&
                                  data?.roomCategoryImages?.length > 0 && (
                                    <Grid
                                      item
                                      md={3}
                                      sm={11.5}
                                      lg={3}
                                      sx={{ height: "100%" }}
                                    >
                                      <Card sx={{ position: "relative" }}>
                                        <CardMedia
                                          component="img"
                                          alt="Image"
                                          // height="250px"
                                          // width="100%"
                                          height="370px"
                                          sx={{ cursor: "pointer" }}
                                          image={
                                            data?.roomCategoryImages[
                                              data?.currentImage
                                            ]?.image
                                          }
                                          onClick={() =>
                                            setOpenImageShowDialog(index + 1)
                                          }
                                          onLoad={handleImageLoaded}
                                          onError={handleImageError}
                                        />
                                        {loading &&
                                          hotelIndex === index + 1 && (
                                            <SmallLoader />
                                          )}

                                        <Box
                                          textAlign="center"
                                          className="d-flex justify-content-between"
                                          // mt={-10} mb={8}
                                          sx={{
                                            position: "absolute",
                                            top: "50%",
                                            width: "100%",
                                          }}
                                        >
                                          {/* <Button onClick={prevImage} className={'mr-1'} sx={{width:"fit-content"}} variant="contained" color="primary"> */}
                                          <ArrowBackIosIcon
                                            onClick={() =>
                                              prevImage(
                                                data,
                                                props?.setFieldValue,
                                                index
                                              )
                                            }
                                            className={"ml-1"}
                                            sx={{
                                              cursor: "pointer",
                                              paddingLeft: "10px",
                                              color: "#f1f1f1",
                                              background: "black",
                                              borderRadius: "5px",
                                            }}
                                          />
                                          {/* </Button> */}
                                          {/* <Button onClick={nextImage} className={'ml-1'} variant="contained" color="primary"> */}
                                          <ArrowForwardIosIcon
                                            onClick={() =>
                                              nextImage(
                                                data,
                                                props?.setFieldValue,
                                                index
                                              )
                                            }
                                            className={"mr-1 "}
                                            sx={{
                                              cursor: "pointer",
                                              paddingX: "7px",
                                              color: "#f1f1f1",
                                              background: "black",
                                              borderRadius: "5px",
                                            }}
                                          />
                                          {/* </Button> */}
                                        </Box>
                                      </Card>
                                      <Box textAlign="center">
                                        <Typography
                                          variant="caption"
                                          align="center"
                                        >
                                          {data?.currentImage + 1} /{" "}
                                          {data?.roomCategoryImages?.length}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  )}
                                <Grid item xs={12} spacing={2} sm container>
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Room Type")}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {data?.category_name}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {+data?.available_rooms <= 2 ||
                                      handleDiscountRate
                                        ? data?.available_rooms +
                                          " " +
                                          t("Rooms")
                                        : ""}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Dates")}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {moment(data?.start_date).format("ll")} -
                                      {moment(data?.end_date).format("ll")}
                                    </Typography>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Occupancy")}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {t("Default Occupancy - ") +
                                        data?.min_Occupancy}
                                      <br />
                                      {t("Max Occupancy - ") + data?.max_occupancy}
                                    </Typography>
                                  </Grid>
                                  {/* {handleDiscountRate && (
                                    <Grid
                                      item
                                      lg={2.3}
                                      md={3.8}
                                      sm={5.9}
                                      xs={11.8}
                                    >
                                      <Typography
                                        style={{
                                          marginBottom: "11px",
                                          color: "#8A92A6",
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        {t("Room Rate")}
                                      </Typography>
                                      <Field
                                      name={`bookingRateInfo.${index}.rateTypeId`}
                                      onChange={(e) => {
                                        handleRoomRateDropdownChange(
                                          e,
                                          index,
                                          props.setFieldValue,
                                          data
                                        );
                                      }}
                                      // defaultOption
                                      options={data?.rateDetails?.map(
                                        (item, index) => ({
                                          title: item?.name,
                                          value: item?.category_rate_type_id,
                                        })
                                      )}
                                      component={FormikSelect}
                                    />
                                     
                                    </Grid>
                                  )} */}
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("No of Adults")}
                                    </Typography>
                                    <Field
                                      // name={`bookingRateInfo.${index}.selectDefaultAdult`}
                                      name={`bookingRateInfo.${index}.selectCommonAdult`}
                                      onChange={(e) => {
                                        handleDefaultAdult(
                                          e,
                                          props.setFieldValue,
                                          index,
                                          data
                                        );
                                      }}
                                      defaultOption
                                      defaultOptionValue = {0}
                                      options={[...data?.defaultAdult?.map(
                                        (_item, index) => ({
                                          title: index + 1,
                                          value: index + 1,
                                          disabled: +data?.selectChild > 0 && index < data?.defaultAdultArr?.length-1,
                                        })
                                      ),
                                      ...data?.extraAdult?.map(
                                        (_item, index) => ({
                                          title: (index + 1 + data?.defaultAdult?.length) + ' (Extra ' + (index + 1) + ')',
                                          value: 'E' + (index + 1),
                                        })
                                      ),
                                    ]}
                                      component={FormikSelect}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("No Of Children")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.selectCommonChild`}
                                      // name={`bookingRateInfo.${index}.selectDefaultChild`}
                                      onChange={(e) => {
                                        handleDefaultChild(
                                          e,
                                          props.setFieldValue,
                                          index,
                                          data
                                        );
                                      }}
                                      defaultOption
                                      options={[...data?.defaultChild?.map(
                                        (_item, index) => ({
                                          title: index + 1,
                                          value: index + 1,
                                          disabled: +data?.selectAdult > 0 && index < data?.defaultChildArr?.length-1,
                                        })
                                      ), ...data?.extraChild?.map(
                                        (_item, index) => ({
                                          title: (index + 1 + data?.defaultChild?.length) + ' (Extra ' + (index + 1) + ')',
                                          value: 'E' + (index + 1),
                                        })
                                      ),]}
                                      component={FormikSelect}
                                    />
                                  </Grid>
                                  {/* <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("No Of Extra Adults")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.selectAdult`}
                                      onChange={(e) => {
                                        handleExtraAdult(
                                          e,
                                          props.setFieldValue,
                                          index,
                                          data
                                        );
                                      }}
                                      defaultOption
                                      options={data?.extraAdult?.map(
                                        (item, index) => ({
                                          title: index + 1,
                                          value: index + 1,
                                        })
                                      )}
                                      component={FormikSelect}
                                    />
                                  </Grid> */}
                                  {/* <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("No Of Extra Children")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.selectChild`}
                                      onChange={(e) => {
                                        handleExtraChild(
                                          e,
                                          props.setFieldValue,
                                          index,
                                          data
                                        );
                                      }}
                                      defaultOption
                                      options={data?.extraChild?.map(
                                        (item, index) => ({
                                          title: index + 1,
                                          value: index + 1,
                                        })
                                      )}
                                      component={FormikSelect}
                                    />
                                  </Grid> */}
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Add - On")}
                                    </Typography>
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="demo-multiple-addon-label">
                                        {t("Add On")}
                                      </InputLabel>
                                      <Select
                                        labelId="demo-multiple-addon-label"
                                        name={`bookingRateInfo.${index}.mealOption`}
                                        inputProps={{
                                          MenuProps: {
                                            disableScrollLock: true,
                                          },
                                        }}
                                        multiple
                                        value={data.mealOption}
                                        onChange={(e) =>
                                          handleMaleOption(
                                            e,
                                            props.setFieldValue,
                                            index,
                                            data
                                          )
                                        }
                                        input={
                                          <OutlinedInput label={t("Add On")} />
                                        }
                                        renderValue={(selected) =>
                                          selected.join(", ")
                                        }
                                        MenuProps={MenuProps}
                                      >
                                        {addOnList.map((item) => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.name}
                                          >
                                            <Checkbox
                                              checked={
                                                data?.mealOption.indexOf(
                                                  item.name
                                                ) > -1
                                              }
                                            />
                                            <ListItemText
                                              primary={`${item?.name} - ${item?.amount}`}
                                            />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  {/* <Grid item lg={6}>
                                        <Typography
                                          style={{
                                            marginBottom: "11px",
                                            color: "rgb(30, 30, 30)",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {t(" OTA Code")}
                                        </Typography>
                                        <FormControl
                                          sx={{
                                            //   m: 1,
                                            width: "100%",
                                          }}
                                        >
                                          <InputLabel
                                            id={`bookingRateInfo.${index}.mealOption`}
                                          >
                                            OTA
                                          </InputLabel>
                                          <Select
                                            name={`bookingRateInfo.${index}.mealOption`}
                                            label={"OTA Code"}
                                            //   labelId="demo-multiple-chip-label"
                                            id={`bookingRateInfo.${index}.mealOption`}
                                            multiple
                                            sx={{
                                              height: "auto",
                                            }}
                                            value={
                                              props.values.bookingRateInfo[
                                                index
                                              ].mealOption
                                            }
                                            onChange={(e) =>
                                              handleMaleOption(
                                                e,
                                                props.setFieldValue,
                                                index
                                              )
                                            }
                                            input={
                                              <OutlinedInput
                                                id="select-multiple-chip"
                                                label="Chip"
                                              />
                                            }
                                            renderValue={(selected) => (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                  gap: 0.5,
                                                }}
                                              >
                                                {selected.map((value) => (
                                                  <Chip
                                                    key={value}
                                                    label={
                                                      (addOnList?.filter(
                                                        (item) =>
                                                          item?.name === value
                                                      ))[0].name
                                                    }
                                                  />
                                                ))}
                                              </Box>
                                            )}
                                            MenuProps={MenuProps}
                                          >
                                            {addOnList.map((item) => (
                                              <MenuItem
                                                key={item.value}
                                                value={item.value}
                                                style={getStyles(
                                                  item.value,
                                                  props.values.bookingRateInfo[
                                                    index
                                                  ].mealOption,
                                                  theme
                                                )}
                                              >
                                                {item.name}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      </Grid> */}
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Quantity")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.RoomQuantity`}
                                      onChange={(e) =>
                                        handledefaultSelectedRoom(
                                          e,
                                          props.setFieldValue,
                                          index,
                                          data
                                        )
                                      }
                                      // defaultOption = {`bookingRateInfo.${index}.RoomQuantity`}
                                      options={[
                                        ...Array(data?.available_rooms),
                                      ]?.map((item, index) => ({
                                        title: index + 1,
                                        value: index + 1,
                                      }))}
                                      component={FormikSelect}
                                    />
                                  </Grid>
                                  {handleDiscountRate && (
                                    <Grid
                                      item
                                      lg={2.3}
                                      md={3.8}
                                      sm={5.9}
                                      xs={11.8}
                                    >
                                      <Typography
                                      id="font-600"
                                        style={{
                                          marginBottom: "11px",
                                          color: "#8A92A6",
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        {t("Discounted Rate")}
                                      </Typography>
                                      <Field
                                        name={`bookingRateInfo.${index}.discounted_selected`}
                                        onChange={(e) => {
                                          handleDiscountRate(
                                            e,
                                            props.setFieldValue,
                                            index,
                                            data
                                          );
                                        }}
                                        defaultOption
                                        customOption
                                        customValues={
                                          data.discounted_rate_id === 0 &&
                                          +data.discount_type === 2
                                            ? (+data.discount).toFixed(0)
                                            : data.discounted_rate_id === 0
                                            ? +data.discount_percentage
                                            : 0
                                        }
                                        options={discountedRate?.map(
                                          (item) => ({
                                            title: `${item.combine_text}`,
                                            value: item.id,
                                          })
                                        )}
                                        component={FormikSelect}
                                      />
                                    </Grid>
                                  )}

                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Avg. Price Per Room")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.price`}
                                      placeHolder="Price"
                                      readOnly
                                      component={FormikInput1}
                                    />
                                  </Grid>
                                  {!onlyCard && (
                                    <Grid
                                      item
                                      lg={2.3}
                                      md={3.8}
                                      sm={5.9}
                                      xs={11.8}
                                    >
                                      <Typography
                                      id="font-600"
                                        style={{
                                          color: "#8A92A6",
                                          fontSize: "16px",
                                          lineHeight: "24px",
                                        }}
                                      >
                                        {t("Discounted price")}
                                      </Typography>
                                      <Field
                                        name={`bookingRateInfo.${index}.discountPrice`}
                                        placeHolder={t("DiscountPrice")}
                                        readOnly
                                        maxLength={150}
                                        component={FormikInput1}
                                      />
                                    </Grid>
                                  )}

                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Add On Price")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.addOnTotal`}
                                      readOnly
                                      maxLength={150}
                                      component={FormikInput1}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Total Price")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.totalRateOfRoom`}
                                      placeHolder={t("Total Price")}
                                      readOnly
                                      maxLength={150}
                                      component={FormikInput1}
                                      value={100}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    lg={2.3}
                                    md={3.8}
                                    sm={5.9}
                                    xs={11.8}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Total Price With Tax")}
                                    </Typography>
                                    <Field
                                      name={`bookingRateInfo.${index}.totalRateWithTax`}
                                      placeHolder={t("Total Price With Tax")}
                                      readOnly
                                      maxLength={150}
                                      component={FormikInput1}
                                      value={100}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  marginBottom: "30px",
                                }}
                                id={"BookinButtonContainer"}
                              >
                                {data.available_rooms > 0 ? (
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleAddRoomItem(
                                        data,
                                        index,
                                        props.setFieldValue,
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["price"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountPrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["defaultSelectedRoom"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["defaultSelectedRateId"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["mealOption"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountType"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discount"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountValue"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountOnePrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discounted_rate"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["addOnTotal"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountedPrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["addOnPrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discounted_selected"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["selectAdult"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["selectDefaultAdult"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["selectChild"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["selectDefaultChild"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountAdult"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountChild"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["QtPrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["PriceAdult"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["AdultOnePrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["ChildOnePrice"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountOneAdult"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discountOneChild"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["min_Occupancy"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["availableRooms"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["discount_percentage"],
                                        props.values.bookingRateInfo[
                                          `${index}`
                                        ]["rateTypeId"]
                                      )
                                    }
                                  >
                                    {t("Add")}
                                  </Button>
                                ) : null}
                              </Grid>
                              {/* </div> */}

                              {!handleDiscountRate &&
                                data?.roomCategoryImages?.length > 0 && (
                                  <DialogForm
                                    scroll="paper"
                                    // maxWidth="lg"
                                    maxWidth="md"
                                    title={data?.category_name}
                                    openDialog={
                                      openImageShowDialog === index + 1
                                    }
                                    handleDialogClose={
                                      handleCloseImageShowDialog
                                    }
                                    bodyContent={
                                      <div className="p-2">
                                        <Grid
                                          container
                                          spacing={2}
                                          alignItems="flex-start"
                                        >
                                          <Grid item lg={12} md={12}>
                                            <Card sx={{ position: "relative" }}>
                                              {loading && <SmallLoader />}

                                              <CardMedia
                                                component="img"
                                                alt="Image"
                                                width="100%"
                                                image={
                                                  data?.roomCategoryImages[
                                                    data?.currentImage
                                                  ]?.image
                                                }
                                                onLoad={handleImageLoaded}
                                                onError={handleImageError}
                                              />
                                              <Box
                                                textAlign="center"
                                                className="d-flex justify-content-between"
                                                // mt={-10} mb={8}
                                                sx={{
                                                  position: "absolute",
                                                  top: "50%",
                                                  width: "100%",
                                                }}
                                              >
                                                <ArrowBackIosIcon
                                                  onClick={() =>
                                                    prevImage(
                                                      data,
                                                      props?.setFieldValue,
                                                      index
                                                    )
                                                  }
                                                  className={"ml-1"}
                                                  sx={{
                                                    cursor: "pointer",
                                                    paddingLeft: "10px",
                                                    color: "#f1f1f1",
                                                    background: "black",
                                                    borderRadius: "5px",
                                                  }}
                                                />

                                                <ArrowForwardIosIcon
                                                  onClick={() =>
                                                    nextImage(
                                                      data,
                                                      props?.setFieldValue,
                                                      index
                                                    )
                                                  }
                                                  className={"mr-1"}
                                                  sx={{
                                                    cursor: "pointer",
                                                    paddingX: "7px",
                                                    color: "#f1f1f1",
                                                    background: "black",
                                                    borderRadius: "5px",
                                                  }}
                                                />
                                              </Box>
                                            </Card>
                                            <Box textAlign="center">
                                              <Typography
                                                variant="caption"
                                                align="center"
                                              >
                                                {data?.currentImage + 1} /{" "}
                                                {
                                                  data?.roomCategoryImages
                                                    ?.length
                                                }
                                              </Typography>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    }
                                  />
                                )}
                            </div>
                          )
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
                <div
                  className="d-flex"
                  style={{
                    minHeight: "70px",
                    marginLeft: "25px",
                  }}
                ></div>
              </form>
            );
          }}
        </Formik>

        {!roomAvailableData && (
          <Typography
            variant="h4"
            style={{
              fontSize: "20px",
              lineHeight: "30px",
              maxWidth: "305px",
              margin: "0 auto",
              fontWeight: "600 !important",
              padding: "178px 0 264px",
            }}
          >
            {t("There are no vacant rooms matching the chosen criteria.")}
          </Typography>
        )}
      </>

      {/* Book Room Component End Here Here */}
    </TabPanel>
  );
};

// import {
//   Button,
//   Grid,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   ToggleButtonGroup,
//   Typography,
// } from "@mui/material";
// import MuiToggleButton from "@mui/material/ToggleButton";
// import { ErrorMessage, Field, FieldArray, Formik } from "formik";
// import React, { useCallback, useEffect, useState } from "react";
// import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
// import SearchIcon from "@mui/icons-material/Search";
// import { styled } from "@mui/material/styles";
// import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
// import { fetchRatesForBooking } from "../../apis/services/RoomCategoryApiService";
// import ShowInputError from "../../shared/components/ShowInputError";
// import {
//   DisplayFormikState,
//   getCancellationPolicy,
//   getRateByDay,
// } from "../../utils/helper";
// import moment from "moment";
// const ToggleButton = styled(MuiToggleButton)({
//   "&.Mui-selected, &.Mui-selected:hover": {
//     color: "white",
//     backgroundColor: "#1C3A66 !important",
//     border: "1px solid #1C3A66",
//   },
//   "& .css-1gjgmky-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped": {
//     backgroundColor: "#1C3A66 !important",
//     marginTop: "0px !important",
//   },
// });

// const BookARoom = () => {
//   const [alignment, setAlignment] = React.useState("web");

//   const [bookingRates, setBookingRates] = useState([]);
//   const [bookingDetails, setBookingDetails] = useState<any[]>([]);

//   const [bookingDetailsStats, setBookingDetailsStats] = useState({
//     noOfRooms: 0,
//     noOfAdults: 0,
//     noOfChild: 2,
//     totalAmount: 0,
//   });

//   const getBookingRates = useCallback(async () => {
//     const result = await fetchRatesForBooking();
//     if (result?.status === 200) {
//       setBookingRates(result.data);
//     } else {
//       setBookingRates([]);
//     }
//   }, []);

//   useEffect(() => {
//     getBookingRates();
//   }, [getBookingRates]);

//   const [categoryList, setCategoryList] = useState([]);
//   const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
//   const [endDate, setEndDate] = useState(
//     moment().add(1, "days").format("YYYY-MM-DD")
//   );
//   useEffect(() => {
//     const roomListDet: any = bookingRates?.map((category: any, index) => {
//       console.log("category", category.rateDetails[0].cancelation_policy);
//       console.log("category", category.rateDetails);
//       return {
//         category_id: category.id,
//         category_name: category.roomcategory_name,
//         max_occupancy: category.max_occupancy,
//         available_rooms: category.availableRooms,
//         start_date: startDate,
//         end_date: endDate,
//         cancelation_policy: category?.rateDetails[index]?.cancelation_policy,
//         // price: category.rateDetails[0]?.categoryRateDetail[0]?.monday_price,
//         price: getRateByDay(category.rateDetails[index]?.categoryRateDetail),
//         // price: 100,
//         rateDetails: category?.rateDetails,
//         defaultSelectedRateId: category.rateDetails[index]?.id,
//         defaultSelectedRoom: 1,
//       };
//     });
//     setCategoryList(roomListDet);
//   }, [bookingRates]);

//   const tableHeading = [
//     {
//       name: "Room Type",
//     },
//     {
//       name: "Dates",
//     },
//     {
//       name: "Room rate",
//     },
//     {
//       name: "Cancellation policy",
//     },
//     {
//       name: "Price for room",
//     },
//     {
//       name: "Quantity",
//     },
//     {
//       name: "",
//     },
//   ];

//   const bookRoomInitialState = {
//     bookingRateInfo: categoryList,
//   };

//   console.log("bookRoomInitialState", bookRoomInitialState);
//   const bookRoomHandleSubmit = (values) => {};
//   const roomAvailableData = [
//     {
//       Booking: "King",
//       Room: "Oct 28, 2022 - Oct 30, 2022",
//       Guests: "2",
//       Chat: "Standard",
//       Nights: "No meals",
//       Roomrate: "Free cancellation policy",
//       Cancellationpolicy: "$200.00",
//       Priceforroom: "1",
//       RoomNo: "2",
//     },
//   ];

//   const handleChange = (
//     event: React.MouseEvent<HTMLElement>,
//     newAlignment: string
//   ) => {
//     setAlignment(newAlignment);
//   };
//   const initialState = {
//     email: "JohnMark123@gmail.com",
//     password: "kwp@123",
//   };
//   const handleSubmit = () => {
//     // eslint-disable-next-line no-console
//     console.log("login");
//   };
//   const data = [
//     { title: "one", value: 1 },
//     { title: "two", value: 2 },
//     { title: "two", value: 2 },
//   ];

//   const handleAddRoomItem = (data, price, quantity, rateId) => {
//     console.log("data", data);
//     console.log("price", price);
//     console.log("quantity", quantity);
//     console.log("rateId", rateId);
//     const item: any = {
//       roomQty: quantity,
//       noOfAdult: 2,
//       noOfChild: 0,
//       startDate: startDate,
//       endDate: endDate,
//       nights: 1,
//       roomRateName: "dssdf",
//       rateId: rateId,
//       price: price,
//       cancelationPolicy: 1,
//       roomType: "cs fes ",
//       roomTypeId: 2,
//       roomNo: 0,
//     };
//     setBookingDetails((oldArray) => [...oldArray, item]);
//     // setBookingDetails(item);
//     // updateCalculations();
//   };

//   const getRateById = (rateArr, id) => {
//     const arr = rateArr.filter((item, index) => {
//       return item.id == id;
//     });
//     // console.log(arr);
//     let price = getRateByDay(arr[0]["categoryRateDetail"]);
//     return price;
//   };

//   // const updateCalculations = () => {
//   //   var noOfRoom = 0;
//   //   var noOfAdults = 0;
//   //   var noOfChild = 0;
//   //   var totalAmount = 0;
//   //   console.log("bookingDetails", bookingDetails);
//   //   bookingDetails.forEach((item: any) => {
//   //     noOfRoom += item.roomQty;
//   //     noOfAdults += item.noOfAdult;
//   //     noOfChild += item.noOfChild;
//   //     totalAmount += item.roomQty * item.price;
//   //   });
//   //   setBookingDetailsStats((prev) => ({ ...prev, noOfRooms: noOfRoom }));
//   //   setBookingDetailsStats((prev) => ({ ...prev, noOfAdults: noOfAdults }));
//   //   setBookingDetailsStats((prev) => ({ ...prev, noOfChild: noOfChild }));
//   //   setBookingDetailsStats((prev) => ({ ...prev, totalAmount: totalAmount }));
//   // };

//   useEffect(() => {
//     var noOfRoom = 0;
//     var noOfAdults = 0;
//     var noOfChild = 0;
//     var totalAmount = 0;
//     console.log("bookingDetails", bookingDetails);
//     bookingDetails.forEach((item: any) => {
//       noOfRoom += item.roomQty;
//       noOfAdults += item.noOfAdult;
//       noOfChild += item.noOfChild;
//       totalAmount += item.roomQty * item.price;
//     });
//     setBookingDetailsStats((prev) => ({ ...prev, noOfRooms: noOfRoom }));
//     setBookingDetailsStats((prev) => ({ ...prev, noOfAdults: noOfAdults }));
//     setBookingDetailsStats((prev) => ({ ...prev, noOfChild: noOfChild }));
//     setBookingDetailsStats((prev) => ({ ...prev, totalAmount: totalAmount }));
//   }, [bookingDetails]);

//   return (
//     <>
//       <Formik
//         initialValues={initialState}
//         onSubmit={handleSubmit}
//         validateOnBlur={false}
//         validateOnChange={true}
//         enableReinitialize={true}
//       >
//         {(props: any) => {
//           const { handleSubmit } = props;
//           return (
//             <>
//               <Grid container spacing={2} alignItems="end">
//                 <Grid item lg={1.5}>
//                   <Field
//                     label=" Search Name"
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item xl="auto">
//                   <Typography
//                     style={{
//                       marginBottom: "11px",
//                       color: "#1E1E1E",
//                       fontSize: "16px",
//                       fontWeight: "500",
//                     }}
//                   >
//                     Search Type
//                   </Typography>
//                   <ToggleButtonGroup
//                     color="primary"
//                     value={alignment}
//                     exclusive
//                     onChange={handleChange}
//                     aria-label="Platform"
//                     style={{ border: "1px solid #1C3A66" }}
//                   >
//                     <ToggleButton style={{ marginTop: "0px" }} value="web">
//                       Accurate
//                     </ToggleButton>
//                     <ToggleButton style={{ marginTop: "0px" }} value="android">
//                       Upcoming
//                     </ToggleButton>
//                   </ToggleButtonGroup>
//                 </Grid>
//                 <Grid item xl="auto">
//                   <Typography
//                     style={{
//                       marginBottom: "11px",
//                       color: "#1E1E1E",
//                       fontSize: "16px",
//                       fontWeight: "500",
//                     }}
//                   >
//                     Booking Type
//                   </Typography>
//                   <ToggleButtonGroup
//                     color="primary"
//                     value={alignment}
//                     exclusive
//                     onChange={handleChange}
//                     aria-label="Platform"
//                     style={{ border: "1px solid #1C3A66" }}
//                   >
//                     <ToggleButton style={{ marginTop: "0px" }} value="web">
//                       Single
//                     </ToggleButton>
//                     <ToggleButton style={{ marginTop: "0px" }} value="android">
//                       Group
//                     </ToggleButton>
//                   </ToggleButtonGroup>
//                 </Grid>
//                 <Grid item lg={2}>
//                   <Field
//                     label="Guest type"
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item xl="auto">
//                   <Typography
//                     style={{
//                       marginBottom: "11px",
//                       color: "#1E1E1E",
//                       fontSize: "16px",
//                       fontWeight: "500",
//                     }}
//                   >
//                     Occupancy
//                   </Typography>
//                   <ToggleButtonGroup
//                     color="primary"
//                     value={alignment}
//                     exclusive
//                     onChange={handleChange}
//                     aria-label="Platform"
//                     style={{
//                       border: "1px solid #1C3A66",
//                     }}
//                   >
//                     <ToggleButton style={{ marginTop: "0px" }} value="web">
//                       Default
//                     </ToggleButton>
//                     <ToggleButton style={{ marginTop: "0px" }} value="android">
//                       Max
//                     </ToggleButton>
//                   </ToggleButtonGroup>
//                 </Grid>
//                 <Grid item lg={2}>
//                   <Field
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Additional filters"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item lg={1.5} alignItems="flex-end">
//                   <Button
//                     size="large"
//                     variant="contained"
//                     disableElevation
//                     // type="submit"
//                     color="primary"
//                     style={{
//                       width: "119px",
//                       height: "49px",
//                       textTransform: "capitalize",
//                       alignItems: "center",
//                       background: "#1C3A66",
//                       borderRadius: "6px",
//                     }}
//                     startIcon={<SearchIcon />}
//                   >
//                     Search
//                   </Button>
//                 </Grid>
//               </Grid>
//               <Grid container spacing={2} alignItems="end" className="mt-1">
//                 <Grid item lg={1.2}>
//                   <Field
//                     label="Dates from "
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item lg={1.2}>
//                   <Field
//                     label="Nights"
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item lg={1.2}>
//                   <Field
//                     label="Guests"
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item lg={1.2}>
//                   <Field
//                     label="Rooms"
//                     type="email"
//                     name="userName"
//                     id="userName"
//                     placeHolder="Eg: Mark Doe"
//                     maxLength={150}
//                     component={FormikInput1}
//                   />
//                 </Grid>
//                 <Grid item lg={2.1}>
//                   <Field
//                     label="Accommodation"
//                     name="roleId"
//                     id="roleId"
//                     defaultOption
//                     options={data?.map((role) => ({
//                       title: role.title,
//                       value: role.value,
//                     }))}
//                     component={FormikSelect}
//                   />
//                 </Grid>
//                 <Grid item lg={3.4}>
//                   <Field
//                     label="Room Type"
//                     name="roleId"
//                     id="roleId"
//                     defaultOption
//                     options={data?.map((role) => ({
//                       title: role.title,
//                       value: role.value,
//                     }))}
//                     component={FormikSelect}
//                   />
//                 </Grid>

//                 <Grid item md={12}>
//                   <div
//                     style={{
//                       display: "flex",
//                       background: "#F2F2F2",
//                       border: "1px solid #F2F2F2",
//                       borderRadius: "6px",
//                       width: "97%",
//                       height: "49px",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       marginBottom: "20px",
//                       padding: "0 15px",
//                     }}
//                   >
//                     <h1
//                       style={{
//                         color: "#1C3A66",
//                         fontSize: "18px",
//                         fontWeight: "500",
//                       }}
//                     >
//                       Booking details: {bookingDetailsStats.noOfRooms} room(s) |{" "}
//                       {bookingDetailsStats.noOfAdults} adult(s),{" "}
//                       {bookingDetailsStats.noOfChild} child(ren) | €
//                       {bookingDetailsStats.totalAmount} |{" "}
//                       <span style={{ color: "#2499D1" }}>Change</span> |{" "}
//                       <span style={{ color: "#DD4B39" }}>Clear</span>
//                     </h1>
//                   </div>
//                 </Grid>
//               </Grid>
//             </>
//           );
//         }}
//       </Formik>

//       <Formik
//         initialValues={bookRoomInitialState}
//         onSubmit={bookRoomHandleSubmit}
//         validateOnBlur={false}
//         validateOnChange={true}
//         enableReinitialize={true}
//         // validationSchema={BookRoomsValidator}
//       >
//         {(props) => {
//           const { handleSubmit } = props;
//           return (
//             <>
//               <form onSubmit={handleSubmit} noValidate>
//                 <Grid container>
//                   <FieldArray name="rooms_info">
//                     {({ push, remove }) => (
//                       <Grid
//                         container
//                         //   spacing={2}
//                         sx={{ marginTop: 2 }}
//                       >
//                         <TableContainer component={Paper}>
//                           <Table
//                             sx={{ minWidth: 650 }}
//                             aria-label="simple table"
//                           >
//                             <TableHead>
//                               <TableRow>
//                                 {tableHeading.map((heading) => {
//                                   return (
//                                     <>
//                                       <TableCell
//                                         key={heading.name}
//                                         className="text-center"
//                                       >
//                                         {heading.name}
//                                       </TableCell>
//                                     </>
//                                   );
//                                 })}
//                               </TableRow>
//                             </TableHead>
//                             {/* <TableHead>
//                               <TableRow>
//                                 <TableCell style={{ minWidth: "140px" }}>
//                                   NAME
//                                 </TableCell>
//                                 <TableCell style={{ minWidth: "250px" }}>
//                                   ROOM TYPE
//                                 </TableCell>
//                                 <TableCell style={{ minWidth: "100px" }}>
//                                   FLOOR NUMBER
//                                 </TableCell>
//                               </TableRow>
//                             </TableHead> */}
//                             <TableBody>
//                               {props.values.bookingRateInfo.map(
//                                 (data: any, index) => (
//                                   <>
//                                     {/* {rows.map((row) => ( */}
//                                     <TableRow
//                                       key={index}
//                                       sx={{
//                                         "&:last-child td, &:last-child th": {
//                                           border: 0,
//                                         },
//                                       }}
//                                     >
//                                       <TableCell
//                                         className="text-center"
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         <Typography variant="subtitle2">
//                                           {data.category_name}
//                                         </Typography>
//                                         <Typography variant="subtitle2">
//                                           {data.available_rooms} room(s)
//                                         </Typography>
//                                         <Typography variant="subtitle2">
//                                           {data.max_occupancy} Accommodation
//                                         </Typography>
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         <Typography variant="subtitle2">
//                                           {moment(data.start_date).format("ll")}{" "}
//                                           - <br />
//                                           {moment(data.end_date).format("ll")}
//                                         </Typography>
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         {/* <Grid item lg={12}> */}
//                                         <Field
//                                           label="Room Type"
//                                           style={{ width: "200px" }}
//                                           name={`bookingRateInfo.${index}.defaultSelectedRateId`}
//                                           options={data.rateDetails?.map(
//                                             (item) => ({
//                                               title: `${item.name}-${data.price}`,
//                                               // title: {getRateById(
//                                               //   data.rateDetails,
//                                               //   e.target.value
//                                               // )}`,
//                                               value: item.id,
//                                             })
//                                           )}
//                                           onChange={async (e) => {
//                                             // alert(e.target.value);
//                                             // console.log("xxxx", e.target.value);
//                                             props.setFieldValue(
//                                               `bookingRateInfo[${index}].price`,
//                                               getRateById(
//                                                 data.rateDetails,
//                                                 e.target.value
//                                               )
//                                             );
//                                           }}
//                                           component={FormikSelect}
//                                         />
//                                         <ErrorMessage
//                                           name={`bookingRateInfo.${index}.defaultSelectedRateId`}
//                                           component={ShowInputError}
//                                         />
//                                         {/* </Grid> */}
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         <Typography variant="subtitle2">
//                                           {getCancellationPolicy(1)}
//                                         </Typography>
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         {/* <Typography variant="subtitle2">
//                                           {data.price}
//                                         </Typography> */}
//                                         <Field
//                                           name={`bookingRateInfo.${index}.price`}
//                                           placeHolder="Price"
//                                           readOnly
//                                           maxLength={150}
//                                           component={FormikInput1}
//                                         />
//                                         <ErrorMessage
//                                           name={`bookingRateInfo.${index}.price`}
//                                           component={ShowInputError}
//                                         />
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         <Grid item lg={12}>
//                                           <Field
//                                             // label="Room Type"
//                                             style={{ width: "100px" }}
//                                             name={`bookingRateInfo.${index}.defaultSelectedRoom`}
//                                             options={[
//                                               ...Array(data.available_rooms),
//                                             ].map((item, index) => ({
//                                               title: index + 1,
//                                               value: index + 1,
//                                             }))}
//                                             component={FormikSelect}
//                                           />
//                                           <ErrorMessage
//                                             name={`bookingRateInfo.${index}.defaultSelectedRoom`}
//                                             component={ShowInputError}
//                                           />
//                                         </Grid>
//                                       </TableCell>
//                                       <TableCell
//                                         style={{
//                                           border: "1px solid #B4ADAD",
//                                         }}
//                                       >
//                                         <Button
//                                           variant="contained"
//                                           onClick={() =>
//                                             handleAddRoomItem(
//                                               data,
//                                               props.values.bookingRateInfo[
//                                                 `${index}`
//                                               ]["price"],
//                                               props.values.bookingRateInfo[
//                                                 `${index}`
//                                               ]["defaultSelectedRoom"],
//                                               props.values.bookingRateInfo[
//                                                 `${index}`
//                                               ]["defaultSelectedRateId"]
//                                             )
//                                           }
//                                         >
//                                           Add
//                                         </Button>
//                                       </TableCell>
//                                     </TableRow>
//                                   </>
//                                 )
//                               )}
//                             </TableBody>
//                           </Table>
//                         </TableContainer>
//                       </Grid>
//                     )}
//                   </FieldArray>
//                 </Grid>
//                 <div
//                   className="d-flex"
//                   style={{
//                     minHeight: "70px",
//                     marginLeft: "25px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       width: "100%",
//                       padding: "0px",
//                       alignItems: "center",
//                       justifyContent: "end",
//                     }}
//                     className="d-flex"
//                   >
//                     {/* <Button
//                       className="mt-1"
//                       size="large"
//                       variant="contained"
//                       disableElevation
//                       color="primary"
//                       style={{
//                         justifyContent: "center",
//                         width: "98px",
//                         height: "44px",
//                         textTransform: "capitalize",
//                         marginRight: "5px",
//                         background:
//                           "linear-gradient(180deg, #255480 0%, #173450 100%)",
//                       }}
//                     >
//                       Cancel
//                     </Button> */}
//                     <Button
//                       className="mt-1"
//                       size="large"
//                       variant="contained"
//                       disableElevation
//                       type="submit"
//                       color="primary"
//                       style={{
//                         justifyContent: "center",
//                         width: "98px",
//                         height: "44px",
//                         textTransform: "capitalize",
//                         background:
//                           "linear-gradient(180deg, #255480 0%, #173450 100%)",
//                       }}
//                     >
//                       Next
//                     </Button>
//                   </div>
//                 </div>
//                 <DisplayFormikState {...props} />
//               </form>
//             </>
//           );
//         }}
//       </Formik>

//       {!roomAvailableData && (
//         <Typography
//           variant="h4"
//           style={{
//             fontSize: "20px",
//             lineHeight: "30px",
//             maxWidth: "305px",
//             margin: "0 auto",
//             fontWeight: "600 !important",
//             padding: "178px 0 264px",
//           }}
//         >
//           There are no vacant rooms matching the chosen criteria.
//         </Typography>
//       )}
//     </>
//   );
// };

// export default BookARoom;
