import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  arrivalList: [],
  departureList: [],
  checkOutList: [],
  cancellationList: [],
  vacantRoomList: [],
  revenueByRoomTypeList: [],
  revenueBreakdownList: [],
  revenueAdrList: [],
  inHouseList: [],
  houseKeepingList: [],
  noShowList: [],
  guestList: [],
  averageDailyRateList: [],
  forecastList: [],
  visitorGuestList: [],
};
const reportSlice = createSlice({
  name: "pos",
  initialState: initialState,
  reducers: {
    arrivalListSuccess: (state, action) => {
      state.arrivalList = action.payload;
    },
    arrivalListFailed: (state, action) => {
      state.arrivalList = [];
    },
    departureListSuccess: (state, action) => {
      state.departureList = action.payload;
    },
    departureListFailed: (state, action) => {
      state.departureList = [];
    },
    checkOutListSuccess: (state, action) => {
      state.checkOutList = action.payload;
    },
    checkOutListFailed: (state, action) => {
      state.checkOutList = [];
    },
    cancellationListSuccess: (state, action) => {
      state.cancellationList = action.payload;
    },
    cancellationListFailed: (state, action) => {
      state.cancellationList = [];
    },
    vacantRoomListSuccess: (state, action) => {
      state.vacantRoomList = action.payload;
    },
    vacantRoomListFailed: (state, action) => {
      state.vacantRoomList = [];
    },
    revenueByRoomTypeListSuccess: (state, action) => {
      state.revenueByRoomTypeList = action.payload;
    },
    revenueByRoomTypeListFailed: (state, action) => {
      state.revenueByRoomTypeList = [];
    },
    revenueBreakdownListSuccess: (state, action) => {
      state.revenueBreakdownList = action.payload;
    },
    revenueBreakdownListFailed: (state, action) => {
      state.revenueBreakdownList = [];
    },
    revenueAdrListSuccess: (state, action) => {
      state.revenueAdrList = action.payload;
    },
    revenueAdrListFailed: (state, action) => {
      state.revenueAdrList = [];
    },
    inHouseListSuccess: (state, action) => {
      state.inHouseList = action.payload;
    },
    inHouseListFailed: (state, action) => {
      state.inHouseList = [];
    },
    houseKeepingListSuccess: (state, action) => {
      state.houseKeepingList = action.payload;
    },
    houseKeepingListFailed: (state, action) => {
      state.houseKeepingList = [];
    },
    noShowListSuccess: (state, action) => {
      state.noShowList = action.payload;
    },
    noShowListFailed: (state, action) => {
      state.noShowList = [];
    },
    guestListSuccess: (state, action) => {
      state.guestList = action.payload;
    },
    guestListFailed: (state, action) => {
      state.guestList = [];
    },
    visitorGuestListSuccess: (state, action) => {
      state.visitorGuestList = action.payload;
    },
    visitorGuestListFailed: (state, action) => {
      state.visitorGuestList = [];
    },
    averageDailyRateListSuccess: (state, action) => {
      state.averageDailyRateList = action.payload;
    },
    averageDailyRateListFailed: (state, action) => {
      state.averageDailyRateList = [];
    },
    forecastListSuccess: (state, action) => {
      state.forecastList = action.payload;
    },
    forecastListFailed: (state, action) => {
      state.forecastList = [];
    },
  },
});

export const {
  arrivalListSuccess,
  arrivalListFailed,
  departureListSuccess,
  departureListFailed,
  checkOutListSuccess,
  checkOutListFailed,
  cancellationListSuccess,
  cancellationListFailed,
  vacantRoomListSuccess,
  vacantRoomListFailed,
  revenueByRoomTypeListSuccess,
  revenueByRoomTypeListFailed,
  revenueBreakdownListSuccess,
  revenueBreakdownListFailed,
  revenueAdrListSuccess,
  revenueAdrListFailed,
  inHouseListSuccess,
  inHouseListFailed,
  houseKeepingListSuccess,
  houseKeepingListFailed,
  noShowListSuccess,
  noShowListFailed,
  guestListSuccess,
  guestListFailed,
  averageDailyRateListSuccess,
  averageDailyRateListFailed,
  forecastListSuccess,
  forecastListFailed,
  visitorGuestListSuccess,
  visitorGuestListFailed
} = reportSlice.actions;
export default reportSlice.reducer;
