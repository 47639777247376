import { CircularProgress } from "@mui/material";
import React from "react";

interface Props{
  colors : string;
}

const DirectLoader: React.FC<Props>= ({colors}) => {
  // console.log(colors, 5)
  return (
    <div id="loader" className="loader-wrapper">
      <CircularProgress sx={{position:"fixed", top:"60%", "& .MuiCircularProgress-svg" : {color: colors + "!important" || "#638184"}}}  thickness={3} size={44} />
    </div>
  );
};

export default DirectLoader;