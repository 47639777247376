import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Field, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import FormikInput from "../../shared/material-ui-formik/FormikInput";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  cancellationPolicyListFailed,
  cancellationPolicyListSuccess,
  refreshPolicyFailed,
} from "../../Reducer/roomSlice";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import { rowsPerPageJsonData } from "../../utils/JsonData";
import { deleteRecord, fetchList } from "../../apis/services/CommonApiService";
import { toast } from "react-toastify";
import ConfirmDialog from "../../shared/components/ConfirmDialog";
import SearchIcon from "@mui/icons-material/Search";
import EditCancellation from "./EditCancellation";
import DialogForm from "../../shared/components/DialogForm";
import { getLanguageCode } from "../../utils/helper";

const CancellationPolicy = (props) => {
  const {
    refreshPolicy,
    cancellationPolicyList,
    cancellationPolicyListSuccess,
    cancellationPolicyListFailed,
    refreshPolicyFailed,
  } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { state } = useLocation();

  const [datagridOptions, setDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    searchSpeciality: 0,
  });

  // const refreshPolicy =  localStorage.getItem("refreshPolicy");

  const updatedatagridOptions = (k, v) =>
    setDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const getCancellationPolicyList = useCallback(async () => {
    // console.log("Api Call");
    updatedatagridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.GET_CANCELLATION_POLICY +
        `?page_no=${datagridOptions.page}&items_per_page=${datagridOptions.pageSize}&search_text=${searchFilters.searchText}`
    );
    if (result?.status === 200) {
      updatedatagridOptions("totalRows", result.data.totalRows);
      cancellationPolicyListSuccess(result.data.rows);
      refreshPolicyFailed();
      updatedatagridOptions("loading", false);
      localStorage.setItem("refreshPolicy", JSON.stringify(false));
    } else {
      updatedatagridOptions("totalRows", 0);
      cancellationPolicyListFailed();
      refreshPolicyFailed();
      updatedatagridOptions("loading", false);
      localStorage.setItem("refreshPolicy", JSON.stringify(false));
    }
  }, [
    datagridOptions.page,
    datagridOptions.pageSize,
    searchFilters.searchText,
  ]);

  useEffect(() => {
    getCancellationPolicyList();
  }, [getCancellationPolicyList]);

  // console.log(refreshPolicy,81);

  useEffect(() => {
    // console.log(refreshPolicy,85);
    if (refreshPolicy) {
      getCancellationPolicyList();
    }
  }, [refreshPolicy]);

  const setDatagridRowsData = (data) => {
    const ratesDatagridRows = data?.map((item, index) => {
      return {
        id: item?.id,
        name: item?.name,
        description: item.description,
        actions: "Actions",
      };
    });
    updatedatagridOptions("rows", ratesDatagridRows);
  };

  useEffect(() => {
    setDatagridRowsData(cancellationPolicyList);
  }, [cancellationPolicyList]);

  //delete room rate api
  const [openDeleteRecordDialog, setOpenDeleteRecordDialog] = useState(false);
  const handleCloseDeleteRecordDialog = () => setOpenDeleteRecordDialog(false);
  const [deleteRecordId, setDeleteRecordId] = useState(0);
  const handleConfirmDeleteRoomRateAction = async () => {
    setOpenDeleteRecordDialog(false);
    const result = await deleteRecord(
      deleteRecordId,
      ApiEndPoints.GET_CANCELLATION_POLICY
    );
    if (result?.status === 200) {
      toast.success(t(result.message));
      getCancellationPolicyList();
    } else {
      toast.error(t(result.message));
    }
  };
  const deleteRate = useCallback(
    (id) => () => {
      setDeleteRecordId(id);
      setOpenDeleteRecordDialog(true);
    },
    []
  );

  const datagridColumns = [
    { field: "name", headerName: t("Name"), flex: 1 },
    { field: "description", headerName: t("Description"), flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label={t("Edit")}
          onClick={() => {
            setEditPolicy(true);
            setPolicyId(params.id);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("Delete")}
          onClick={deleteRate(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.length > 3) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    } else if (value.length == 0) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  const [openEditPolicy, setEditPolicy] = useState(false);
  const handleCloseEditPolicyDialog = () => {
    setEditPolicy(false);
    setPolicyId(null);
  };
  const [policyId, setPolicyId] = useState(null);

  return (
    <div className="main-layout-background h-100vh w-100">
      <div
        className="d-flex mb-2  justify-content-between align-items-center"
        style={{ gap: "12px" }}
      ></div>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item lg={4} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <TextField
              label={t("Search Policy")}
              onChange={(event) => {
                handleSearch(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className="w-100">
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={datagridOptions.loading}
          rowCount={datagridOptions.totalRows}
          rowsPerPageOptions={datagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={datagridOptions.rows}
          columns={datagridColumns}
          page={datagridOptions.page - 1}
          pageSize={datagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updatedatagridOptions("page", 1);
            updatedatagridOptions("pageSize", pageSize);
          }}
        />
        {/* </div> */}
      </div>

      {/* Delete Room Rate Alert */}
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to delete the Cancellation Policy?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeleteRecordDialog}
        handleDialogClose={handleCloseDeleteRecordDialog}
        handleDialogAction={handleConfirmDeleteRoomRateAction}
      />

      {/* Edit cancellation policy dialog */}
      <DialogForm
        scroll="paper"
        maxWidth="lg"
        title={t("Edit Cancellation policy")}
        openDialog={openEditPolicy}
        handleDialogClose={handleCloseEditPolicyDialog}
        bodyContent={
          <EditCancellation
            handleCloseDialog={handleCloseEditPolicyDialog}
            id={policyId}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cancellationPolicyList: state.room.cancellationPolicyList,
    refreshPolicy: state.room.refreshPolicy,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    cancellationPolicyListSuccess: (data) =>
      dispatch(cancellationPolicyListSuccess(data)),
    cancellationPolicyListFailed: () =>
      dispatch(cancellationPolicyListFailed()),
    refreshPolicyFailed: () => dispatch(refreshPolicyFailed()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CancellationPolicy);

//  (<div className="w-100 mb-2">
//     <div className="w-100">
//       {/* <Formik> */}
//       <Grid container spacing={3}>
//         <Grid item xs={2}>
//           <TextField
//             // label="Email"
//             type="text"
//             name="userName"
//             id="userName"
//             autoComplete="off"
//             // maxLength={150}
//             // component={FormikInput}
//             // onClick={() => dispatch(setProps({"name":"nskn"}))}
//           />
//         </Grid>
//         <Grid item xs={1.2}>
//           <Button
//             className="mt-1 comman-button"
//             size="medium"
//             variant="contained"
//             disableElevation
//             // type="submit"
//             color="primary"
//             // style={{
//             //   justifyContent: "center",
//             //   width: "98px",
//             //   height: "44px",
//             //   textTransform: "capitalize",
//             //   background:
//             //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
//             // }}
//             // onClick={() => history.push(`${Routing.LoginHotelDetails}`)}
//           >
//             {t("Search")}
//           </Button>
//         </Grid>
//       </Grid>
//       {/* </Formik> */}
//     </div>
//     <div
//       className="w-90 p-3 mt-2"
//       style={{ marginLeft: "15px", marginRight: "25px", background: "#fff" }}
//     >
//       <h3>
//         {t("Free cancellation, full refund - up to 24hr before checkin")}
//       </h3>
//       <div className="mt-2 p-2" style={{ background: "#80808052" }}>
//         <h6>
//           {t(
//             "Free cancellation policy and full refund - the guest can cancel their booking without charge up to 24 hrs before checkin date."
//           )}
//         </h6>
//         <h6>{t("Prepayment Required.")}</h6>
//         {/* <h6 style={{ color: "gray" }}>{t("Cancellation policy summary")}</h6> */}
//       </div>
//     </div>
//     <div
//       className="w-90 p-3 mt-2"
//       style={{ marginLeft: "15px", marginRight: "25px", background: "#fff" }}
//     >
//       <h3>
//         {t("Free cancellation, full refund - up to 72hr before checkin")}
//       </h3>
//       <div className="mt-2 p-2" style={{ background: "#80808052" }}>
//         <h6>
//           {t(
//             "Free cancellation policy and full refund - the guest can cancel their booking without charge up to 72 hrs before checkin date."
//           )}
//         </h6>
//         <h6>{t("Prepayment Required.")}</h6>
//         {/* <h6 style={{ color: "gray" }}>{t("Cancellation policy summary")}</h6> */}
//       </div>
//     </div>
//     <div
//       className="w-90 p-3 mt-2"
//       style={{ marginLeft: "15px", marginRight: "25px", background: "#fff" }}
//     >
//       <h3>{t("Non-refundable")}</h3>
//       <div className="mt-2 p-2" style={{ background: "#80808052" }}>
//         <h6>
//           {t(
//             "The guest will be charged the total price if they cancel at any time."
//           )}
//         </h6>
//         <h6>{t("Prepayment Required.")}</h6>
//         {/* <h6 style={{ color: "gray" }}>{t("Cancellation policy summary")}</h6> */}
//       </div>
//     </div>
//   </div>)
