import React from "react";
// import _ from "lodash";
import {
  FormControl,
  // InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  label_shrinked: {
    borderRadius: "6px !important",
  },
  textField: {
    border: "1px solid #B4ADAD !important",
    borderRadius: "6px !important",
  },
  borderNone: {
    border: "0px  !important",
    borderRadius: "6px !important",
  },
  textTransform: {
    textTransform: "uppercase",
  },
});

const FormikInput1 = function ({
  field: { ...fields },
  ...props
}): React.ReactNode {
  const styles = useStyles();
  const {
    id,
    maxLength,
    isDefaultValue,
    multiline,
    className,
    // hasObject = false,
    controlClassName,
    placeHolder,
    readOnly,
    textTransform,
    sx,
    sxx,
    rows,
    noMaxLength,
    labelN,
    borderNone,
    inputProps,
    ...rest
  } = props;
  //   const error = Boolean(
  //     _.get(touched, fields?.name) && _.get(errors, fields?.name)
  //   );
  //   const getError = () => {
  //     let errorString = errors;
  //     if (hasObject)
  //       fields?.name
  //         ?.split(".")
  //         .map((name: string) => (errorString = errorString[name]));
  //     return errorString;
  //   };
  return (
    <FormControl fullWidth className={controlClassName} sx={sx}>
      <Typography
        id="font-600"
        style={{
          marginBottom: "11px",
          color: "#1E1E1E",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {props?.label}
      </Typography>
      {/* {props?.label && (
        <InputLabel
          classes={{
            root: styles.label,
            shrink: styles.label_shrinked,
          }}
        >
          {props?.label}
        </InputLabel>
      )} */}
      {isDefaultValue ? (
        <TextField
          {...fields}
          {...rest}
          id={id}
          label={""}
          className={className}
          multiline={multiline}
          placeholder={placeHolder}
          rows={rows}
          sx={sxx}
          //   error={error}
          autoComplete="off"
          inputProps={{
            maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
            className: textTransform ? styles.textTransform : styles.textField,
            readOnly: readOnly,
          }}
        />
      ) : (
        <TextField
          {...fields}
          {...rest}
          id={id}
          className={className}
          placeholder={placeHolder ? placeHolder : ""}
          label={labelN ? labelN : ""}
          rows={rows}
          value={props.type === "number" ? fields?.value  : fields?.value || ''}
          multiline={multiline}
          // as={as}
          sx={sxx}
          //   error={error}
          autoComplete="off"
          InputLabelProps={{ shrink: !!fields.value }}
          inputProps={inputProps ?inputProps :{
            maxLength: noMaxLength ? -1 : maxLength ? maxLength : 100,
            className: textTransform
              ? styles.textTransform
              : labelN
              ? styles.label_shrinked
              : borderNone? styles.borderNone : styles.textField,
            readOnly: readOnly,
            
          }}
          // sx={{
            
          // }}
        />
      )}
      {/* {error && (
        <FormHelperText error>
          {error && (hasObject ? getError() : errors[fields?.name])}
        </FormHelperText>
      )} */}
    </FormControl>
  );
};

export default FormikInput1;
