import { createTheme } from "@mui/material/styles";

let portalTheme = createTheme({
  palette: {
    primary: {
      main: "#382DAE",
      // main: "#38E078",
      // dark: "#158d1a",
      dark: "#382DAE",
      light: "#FFFFFF",
    },
    // for table
    secondary: {
      contrastText: "#4087FC",
      main: "#B4ADAD",
      light: "#F4F7FA",
      dark: "#1c662c",
    },
    text: {
      primary: "#000",
      disabled: "#A3A3A3",
      secondary: "#232D42",
    },
    // info: {
    //   main: "#9BB6D5",
    //   contrastText: "#6AB7FF",
    // },
    // error: {
    //   main: "#FF5353",
    //   light: "#FF8A8A",
    // },
    // warning: {
    //   main: "#FDA53D",
    //   dark: "#F8824F",
    // },
    // success: {
    //   main: "#45D09E",
    // },
  },
});

portalTheme = createTheme(portalTheme, {
  // Typography
  typography: {
    h1: {
      fontSize: "33px",
      lineHeight: "50px",
      fontWeight: 400,
      letterSpacing: 0,
      color: [portalTheme.palette.text.secondary],
      [portalTheme.breakpoints.up("md")]: { fontSize: "33px" },
    },
    h2: {
      fontSize: "13px",
      lineHeight: "19.5px",
      fontWeight: 400,
      letterSpacing: 0,
      color: [portalTheme.palette.text.primary],
      [portalTheme.breakpoints.up("md")]: { fontSize: "13px" },
    },
    h3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      letterSpacing: 0,
      color: [portalTheme.palette.text.primary],
      [portalTheme.breakpoints.up("md")]: { fontSize: "16px" },
    },
    h4: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "700 !important",
      letterSpacing: 0,
      color: "#161736",
      [portalTheme.breakpoints.up("md")]: { fontSize: "16px" },
    },
    h5: {
      fontSize: "1.33333333333rem",
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: 0,
    },
    h6: {
      fontSize: "1.06666666667rem",
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: 0,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.4,
      fontWeight: 400,
      letterSpacing: 0,
    },
    body2: {
      fontSize: "0.933333333rem",
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: 0,
    },
    caption: {
      fontSize: "0.8rem",
      lineHeight: 1.06666667,
      fontWeight: 400,
      letterSpacing: 0,
    },
  },
  components: {
    MuiCircularProgress:{circle:{color:"green"},},
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: -2,
          color: [portalTheme.palette.text.disabled],
          transformOrigin: "left center",
          maxWidth: "calc(100% - 44px)",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          input: {
            padding: 10,
            height: 29,
            // color: [portalTheme.palette.primary.dark],
            borderRadius: "12px!important"  ,
          },
          "&.MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px!important"
          },
          fieldset: {
            borderRadius: "12px!important"
          },
          "&:hover": {
            fieldset: {
              borderColor: [portalTheme.palette.primary.dark] + "!important",
            },
          },
        },
        // notchedOutline: {
        //   borderColor: [portalTheme.palette.secondary.contrastText],
        // },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        adornedEnd: {
          paddingRight: "16px!important",
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -8,
        },
      },
    },
    
    MuiPaper: {
      styleOverrides: {
        root: {
          // background: [portalTheme.palette.secondary.contrastText],
          boxShadow: "0px 0px 10px 1px rgba(0, 0, 0, 0.1)",
          borderRadius: 5,
          "&.select-wrapper": {
            maxHeight: 220,
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "1px solid #4087FC!important",
          },
          "&:hover": {
            "&:before": {
              borderBottomColor:
                [portalTheme.palette.primary.main] + "!important",
            },
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },

    // Buttons
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "16px",
          lineHeight: "21px",
          fontWeight: 400,
          boxShadow: "none!important",
          "&.MuiButton-outlined" : {
            color:"#000"
          },
        },
        startIcon: {
          marginRight: 6,
          marginLeft: -2,
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "separate",
          border: "1px solid" + [portalTheme.palette.secondary.main],
          borderRadius: 5,
          marginBottom: 4,
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid",
          borderRight: "1px solid",
          borderColor: [portalTheme.palette.secondary.main],
          "& .MuiCheckbox-root": {
            marginLeft: -8,
            marginTop: -8,
            marginBottom: -8,
          },
        },
        head: {
          padding: "21px 22px",
          fontSize: "13px",
          lineHeight: "19.5px",
          fontWeight: 400,
          backgroundColor: [portalTheme.palette.secondary.light],
          color: [portalTheme.palette.text.primary],
          whiteSpace: "nowrap",
        },
        body: {
          padding: "21px 22px",
          fontSize: "13px",
          lineHeight: "19.5px",
          fontWeight: 400,
          color: [portalTheme.palette.text.primary],
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 151, 169, 0.2)",
          },
          "&:last-child": {
            "& .MuiTableCell-body": {
              borderBottom: 0,
            },
          },
        },
      },
    },

    //

    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: [portalTheme.palette.primary.light],
          borderRadius: 5,
          margin: 15,
          [portalTheme.breakpoints.up("md")]: {
            margin: 40,
          },
        },
        paperWidthSm: {
          maxWidth: 420,
          width: 420,
        },
        paperWidthMd: {
          maxWidth: 600,
          width: 600,
        },
        paperWidthLg: {
          maxWidth: 1056,
          width: 1056,
        },
        paperWidthXl: {
          maxWidth: 1400,
          width: 1400,
        },
      },
    },

    // Dialog
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "20px 0!important",
          background: "#f0f0f0",
          minHeight: 40,
          "&.no-action": {
            padding: "25px!important",
            [portalTheme.breakpoints.up("sm")]: {
              padding: "35px!important",
            },
          },
          "&.form-dialog-content": {
            padding: "20px 20px 16px!important",
            [portalTheme.breakpoints.up("sm")]: {
              padding: "20px!important",
            },
          },
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px 20px!important",
          flexWrap: "wrap",
          "&>:first-of-type": {
            margin: "4px 0",
          },
          "&>:not(:first-of-type)": {
            margin: "4px 0",
            marginLeft: 8,
            [portalTheme.breakpoints.up("sm")]: {
              marginLeft: 20,
            },
          },
          "&.manage-patient": {
            padding: "20px 0!important",
          },
          [portalTheme.breakpoints.up("sm")]: {
            padding: "20px!important",
            flexWrap: "normal",
          },
          "&.form-dialog-action": {
            padding: "16px 0 0!important",
            flexWrap: "wrap",
            [portalTheme.breakpoints.up("sm")]: {
              padding: "20px 0 0!important",
              flexWrap: "normal",
            },
          },
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 16px",
          backgroundColor: [portalTheme.palette.secondary.dark],
          color: [portalTheme.palette.primary.light],
          "& .MuiTypography-root": {
            fontSize: "1.2rem",
            lineHeight: 1.3333333,
          },
          "& .close-action": {
            minWidth: 24,
            margin: "-4px -4px -4px 0",
          },
        },
      },
    },
    //select
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: 13,
          paddingBottom: 13,
          paddingLeft: 13,
          height: 24,
          textAlign: "left",
        },
        icon: {
          width: 5,
          height: 5,
          borderBottom: "solid 1px #B4ADAD",
          borderRight: "solid 1px #B4ADAD",
          transform: "rotate(45deg)",
          right: 13,
          top: "calc(50% - 4px)",
          path: {
            opacity: 0,
          },
        },
      },
    },
    //Tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "10px 0",
          textTransform: "none",
          letterSpacing: 0,
          fontSize: 18,
          fontWeight: "500",
          lineHeight: "18px",
          minHeight: "auto",
          color: "#8A8888",
          [portalTheme.breakpoints.up("md")]: {
            fontSize: 18,
            minHeight: 52,
            width: "100%",
            marginTop: "25px",
          },
        },
      },
    },
  },
});

export default portalTheme;
