import React, { useState } from "react";
// import _ from "lodash";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  select: {
    "& .MuiOutlinedInput-root": {
      height: "38px",
      width: "62px",
      color: "#646464",
      border: "1px solid #B4ADAD",
    },
  },
}));
interface ISelectOption {
  title: string;
  value: any;
  disabled: boolean;
}

const FormikSelect = ({
  field: { ...fields },
  //   form: { touched, errors },
  ...props
}): React.ReactNode => {
  const classes = useStyles();

  const {
    customValues,
    customOption,
    defaultOption,
    sx,
    fullWidth = true,
    // hasObject = false,
    className,
    defaultOptionValue,
    ...rest
  } = props;

  const [customValue, setCustomValue] = useState(
    customValues ? customValues : 0
  );

  //   const error = Boolean(
  //     _.get(touched, fields.name) && _.get(errors, fields.name)
  //   );
  //   const getError = () => {
  //     let errorString = errors;
  //     if (hasObject)
  //       fields?.name?.split(".").map((name) => (errorString = errorString[name]));
  //     return errorString;
  //   };
  // console.log(props?.options,533)
  const { t } = useTranslation();

  return (
    <FormControl fullWidth={fullWidth} className={`${className}`}>
      {/* <Typography
        style={{
          marginBottom: "11px",
          color: "#8A92A6",
          fontSize: "16px",
          lineHeight: "24px",
        }}
      >
        {props?.label}
      </Typography> */}
                  
      <InputLabel  title={props.label}>{props.label}</InputLabel>
      <Select
        {...fields}
        {...rest}
        // error={error}
        inputProps={{ MenuProps: { disableScrollLock: true } }}
        MenuProps={{
          PaperProps: {
            className: "select-wrapper",
          },
        }}
        className={classes.select}
        sx={sx}
        // value={props.defaultValue}
      >
        {defaultOption && !customOption && (
          <MenuItem value={ defaultOptionValue ? defaultOptionValue : typeof fields.value === "string" ? "" : 0}>
            {" "}
            {t("-- select --")}{" "}
          </MenuItem>
        )}
        {customOption && (
          <Input
            className="w-100 pl-1"
            value={customValue}
            type="number"
            placeholder={t("Type discount value here...")}
            onChange={(e) => setCustomValue(e.target.value)}
          />
        )}
        {customOption && <MenuItem value={"0"}>{t("Select")}</MenuItem>}
        {customOption && (
          <MenuItem value={"-" + customValue}>{t("Custom")} {customValue} %</MenuItem>
        )}

        {customOption && (
          <MenuItem value={"F-" + customValue}>{t("Flat")} $ {customValue}</MenuItem>
        )}
        {props?.options?.map((option: ISelectOption) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option?.disabled ? option?.disabled : false}
          >
            {option.title}
          </MenuItem>
        ))}
      </Select>
      {/* {error && (
        <FormHelperText error>
          {error && (hasObject ? getError() : errors[fields?.name])}
        </FormHelperText>
      )} */}
    </FormControl>
  );
};

export default FormikSelect;
