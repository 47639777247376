import { createSlice } from '@reduxjs/toolkit'

export const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    
  },
  reducers: {
      setProps: (state, action, val) => {
        console.log("state",state,action, val)
        
      {state[action.payload] = action.payload}
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProps } = formDataSlice.actions

export default formDataSlice.reducer