import {
  Button,
  Grid,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Field, Formik } from "formik";
import React from "react";
import FormikInput from "../../shared/material-ui-formik/FormikInput";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { useTranslation } from "react-i18next";

const CleaningFees = () => {
  const { t, i18n } = useTranslation();
  const tableHeading = [
    {
      name: "FEE NAME",
    },
    {
      name: "PRICE",
    },
    {
      name: "VAT",
    },
    // {
    //   name: "ARTICLE NO.",
    // }
  ];

  return (
    <div className="d-block">
      <div className="d-block mb-3 p-3" style={{ background: "#fff" }}>
        {/* <div className='d-block'> */}
        <Formik>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel>{t("Has final cleaning fee")}</InputLabel>
              <Field
                // label="Email"
                type="text"
                name="userName"
                id="userName"
                autoComplete="off"
                maxLength={150}
                component={FormikSelect}
                // onClick={() => dispatch(setProps({"name":"nskn"}))}
              />
            </Grid>
          </Grid>
        </Formik>

        <h6 className="mt-2 mb-2">{t("Add cleaning fee")}</h6>

        <div >
          <div className=" pr-3">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell key="Action" className="text-center" width="50">
                    <Checkbox />
                  </TableCell> */}
                    {tableHeading.map((heading) => {
                      return (
                        <>
                          <TableCell
                            key={heading.name}
                            className="text-center"
                            maxWidth="10"
                          >
                            {t(heading.name)}
                          </TableCell>
                        </>
                      );
                    })}
                    <TableCell>
                      <h1>+</h1>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="text-center">
                      <Formik>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Field
                              // label="Email"
                              type="text"
                              name="userName"
                              id="userName"
                              autoComplete="off"
                              maxLength={150}
                              component={FormikInput}
                              // onClick={() => dispatch(setProps({"name":"nskn"}))}
                            />
                          </Grid>
                        </Grid>
                      </Formik>
                    </TableCell>
                    <TableCell className="text-center">
                      <Formik>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Field
                              // label="Email"
                              type="text"
                              name="userName"
                              id="userName"
                              autoComplete="off"
                              maxLength={150}
                              component={FormikInput}
                              // onClick={() => dispatch(setProps({"name":"nskn"}))}
                            />
                          </Grid>
                        </Grid>
                      </Formik>
                    </TableCell>
                    <TableCell className="text-center">
                      <Formik>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Field
                              // label="Email"
                              type="text"
                              name="userName"
                              id="userName"
                              autoComplete="off"
                              maxLength={150}
                              component={FormikSelect}
                              // onClick={() => dispatch(setProps({"name":"nskn"}))}
                            />
                          </Grid>
                        </Grid>
                      </Formik>
                    </TableCell>
                    {/* <TableCell  className="text-center">
                    <Formik>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            // label="Email"
                            type="text"
                            name="userName"
                            id="userName"
                            autoComplete="off"
                            maxLength={150}
                            component={FormikInput}
      
                          />
                        </Grid>
                      </Grid>
                    </Formik>  
                  </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* </div> */}
      </div>
      <div className=" d-flex justify-content-center">
        <Button
          className="mt-1 comman-cancel-button"
          size="large"
          variant="contained"
          disableElevation
          color="primary"
          // style={{
          //   justifyContent: "center",
          //   marginRight: "10px",
          //   width: "98px",
          //   height: "44px",
          //   textTransform: "capitalize",
          //   background: "gray",
          // }}
        >
          {t("Cancel")}
        </Button>
        <Button
          className="mt-1 ml-1 comman-button"
          size="large"
          variant="contained"
          disableElevation
          color="primary"
          // style={{
          //   justifyContent: "center",
          //   width: "98px",
          //   height: "44px",
          //   textTransform: "capitalize",
          //   background: "linear-gradient(180deg, #255480 0%, #173450 100%)",
          // }}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default CleaningFees;
