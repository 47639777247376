import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otaList: [],
 
};
const otaSlice = createSlice({
  name: "ota",
  initialState: initialState,
  reducers: {
    otaListSuccess: (state, action) => {
      state.otaList = action.payload;
    },
    otaListFailed: (state, action) => {
      state.roomsList = [];
    },
    
  },
});

export const {
  otaListSuccess,
  otaListFailed,

} = otaSlice.actions;
export default otaSlice.reducer;
