import { useTranslation } from "react-i18next";
import DialogForm from "../../../shared/components/DialogForm";



export const CancelationPolicyDialog = (props) => {
    let {
        setOpenCancelation,
        openCancelation,
        description,
        bgColor,
        color
    } = props;
    const { t } = useTranslation();

    return (
        <DialogForm
      scroll="paper"
      maxWidth="md"
      // maxWidth="mb"
      title={t("Cancelation Policy")}
      bgColor={bgColor}
      color={color}
      openDialog={openCancelation}
      handleDialogClose={() => setOpenCancelation(false)}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      bodyContent={
        <div className="p-2">
            {description}
        </div>
       }
        />
    )
}

