import { createSlice } from "@reduxjs/toolkit";

const localUserData = JSON.parse(localStorage.getItem("userDetails") || "{}");
// const localOwnerUserData = JSON.parse(localStorage.getItem("userOwnerDetails") || "{}");
const initialState = {
  userInfo: localUserData ? localUserData : null,
  // userOwnerInfo: localOwnerUserData ? localOwnerUserData : null,
  userToken: localUserData ? localUserData.token : "",

  permission: {},
  sidebarIn: false,
  loading: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.userInfo = action.payload;
      state.userToken = action.payload.token;
    },
    logOutSuccess: (state, action) => {
      state.userInfo = null;
      state.userToken = "";
    },
    // loginOwnerSuccess: (state, action) => {
    //   state.userOwnerInfo = action.payload;
    //   state.userToken = action.payload.token;
    // },
    // logOutOwnerSuccess: (state, action) => {
    //   state.userOwnerInfo = null;
    //   state.userToken = "";
    // },
    getPermissionSuccess: (state, action) => {
      state.permission = action.payload
    },
    getPermissionFailed: (state) => {
      state.permission = {}
    },
    getLoadingSuccess: (state) => {
      state.loading = true;
    },
    getLoadingFailed: (state) => {
      state.loading = false
    },
    getSidebarSuccess: (state) => {
      state.sidebarIn = true;
    },
    getSidebarFailed: (state) => {
      state.sidebarIn = false
    }
  },
});

export const { 
  loginSuccess, 
  logOutSuccess,
  // loginOwnerSuccess,
  // logOutOwnerSuccess,
  getPermissionSuccess,
  getPermissionFailed,
  getLoadingSuccess,
  getLoadingFailed,
  getSidebarSuccess,
  getSidebarFailed
} = authSlice.actions;
export default authSlice.reducer;
