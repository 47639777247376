import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../shared/components/ConfirmDialog";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import SearchIcon from "@mui/icons-material/Search";
import {
  deleteRecord,
  fetchList,
} from "../../../apis/services/CommonApiService";
import {
  roomsSeasonalRateListFailed,
  roomsSeasonalRateListSuccess,
} from "../../../Reducer/roomRateSlice";
import { rowsPerPageJsonData } from "../../../utils/JsonData";
import moment from "moment";
import Loader from "../../../layout/Loader";
import { useTranslation } from "react-i18next";
import { getLanguageCode } from "../../../utils/helper";

const SeasonalPricingList = (props) => {
  const {
    roomsSeasonalRateList,
    roomSeasonalRateListSuccess,
    roomSeasonalRateListFailed,
  } = props;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [datagridOptions, setDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });
  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    searchSpeciality: 0,
  });

  const updatedatagridOptions = (k, v) =>
    setDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const getRoomSeasonalRateList = useCallback(async () => {
    // console.log("Api Call");
    updatedatagridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.SEASONAL_ROOM_RATES_RESOURCE_ROUTE +
        `?page_no=${datagridOptions.page}&items_per_page=${datagridOptions.pageSize}&search_text=${searchFilters.searchText}`
    );
    if (result?.status === 200) {
      updatedatagridOptions("totalRows", result.data.totalRows);
      roomSeasonalRateListSuccess(result.data.rows);
      updatedatagridOptions("loading", false);
    } else {
      updatedatagridOptions("totalRows", 0);
      roomSeasonalRateListFailed();
      updatedatagridOptions("loading", false);
    }
  }, [
    datagridOptions.page,
    datagridOptions.pageSize,
    searchFilters.searchText,
  ]);

  useEffect(() => {
    getRoomSeasonalRateList();
  }, [getRoomSeasonalRateList]);

  const setDatagridRowsData = (data) => {
    const ratesDatagridRows = data?.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        start_date: moment(item.start_date, "YYYY-MM-DD").format("MM/DD/YYYY"),
        end_date: moment(item.end_date, "YYYY-MM-DD").format("MM/DD/YYYY"),
        rate_type: item.rateTypeDetail?.name,
        actions: "Actions",
      };
    });
    updatedatagridOptions("rows", ratesDatagridRows);
  };

  useEffect(() => {
    setDatagridRowsData(roomsSeasonalRateList);
  }, [roomsSeasonalRateList]);

  //delete room rate api
  const [loading, setLoading] = React.useState(false);
  const [openDeleteRecordDialog, setOpenDeleteRecordDialog] =
    React.useState(false);
  const handleCloseDeleteRecordDialog = () => setOpenDeleteRecordDialog(false);
  const [deleteRecordId, setDeleteRecordId] = useState(0);
  const handleConfirmDeleteRoomRateAction = async () => {
    setLoading(true);
    setOpenDeleteRecordDialog(false);
    const result = await deleteRecord(
      deleteRecordId,
      ApiEndPoints.SEASONAL_ROOM_RATES_RESOURCE_ROUTE
    );
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result.message));
      getRoomSeasonalRateList();
    } else if (result === undefined) {
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      toast.error(t(result.message));
    }
  };
  const deleteRate = React.useCallback(
    (id) => () => {
      setDeleteRecordId(id);
      setOpenDeleteRecordDialog(true);
    },
    []
  );

  const datagridColumns = [
    { field: "name", headerName: t("Rate Name"), flex: 1 },
    { field: "start_date", headerName: t("From"), flex: 1 },
    { field: "end_date", headerName: t("To"), flex: 1 },
    { field: "rate_type", headerName: t("Room Rate"), flex: 1 },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label={t("Edit")}
          onClick={() => navigate(`/edit-seasonal-room-rates/${params.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          label={t("Delete")}
          onClick={deleteRate(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.length > 3) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    } else if (value.length == 0) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  return (
    <div className="main-layout-background h-100vh w-100">
      {loading && <Loader />}
      <div
        className="d-flex mb-2  justify-content-between align-items-center"
        style={{ gap: "12px" }}
      ></div>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item lg={4} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <TextField
              label={t("Search Rate")}
              onChange={(event) => {
                handleSearch(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <div className="w-100">
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={datagridOptions.loading}
          rowCount={datagridOptions.totalRows}
          rowsPerPageOptions={datagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={datagridOptions.rows}
          columns={datagridColumns}
          page={datagridOptions.page - 1}
          pageSize={datagridOptions.pageSize}
          checkboxSelection={true}
          onPageChange={(newPage) => {
            setDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updatedatagridOptions("page", 1);
            updatedatagridOptions("pageSize", pageSize);
          }}
        />
        {/* </div> */}
      </div>

      {/* Delete Room Rate Alert */}
      <ConfirmDialog
        scroll="paper"
        maxWidth="md"
        title={t("Confirm The Action")}
        message={t("Do you really want to delete the seasonal room rate?")}
        cancelButtonText={t("Cancel")}
        confirmButtonText={t("Delete")}
        openDialog={openDeleteRecordDialog}
        handleDialogClose={handleCloseDeleteRecordDialog}
        handleDialogAction={handleConfirmDeleteRoomRateAction}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    roomsSeasonalRateList: state.roomRate.roomsSeasonalRateList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    roomSeasonalRateListSuccess: (data) =>
      dispatch(roomsSeasonalRateListSuccess(data)),
    roomSeasonalRateListFailed: () => dispatch(roomsSeasonalRateListFailed()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeasonalPricingList);
