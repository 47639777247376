export default {
  IcChecked: require("./ic-checked.svg").default,
  IcUnChecked: require("./ic-unchecked.svg").default,
  Search: require("./search-icon.svg").default,
  CloseWhite: require("./ic-close-white.svg").default,
  TrueGreen: require("./trueGreen.svg").default,
};
export const HiveLogo = require("./hive_logo.png");
export const HiveLogoBlack = require("./hive_logo_black.png");
export const WelcomeImage = require("./welcome_image.png");
export const Guest = require("./guests.png");
export const Accounting = require("./accounting.png");
export const Admin = require("./admin.png");
export const AdminIcon = require("./adminicon.png");
export const Pos = require("./pos.png");

export const NotificationIcon = require("./bell.png");
export const Calender = require("./calendar.png");
export const CashBooks = require("./cash-books.png");
export const Dashboard = require("./dashboard.png");
export const HiveLogoInside = require("./hive-logos-inside.png");
export const HouseKeeping = require("./housekeeping.png");
export const Login = require("./login.png");
export const Logout = require("./logout.png");
export const Messages = require("./messages.png");
export const Reports = require("./reports.png");
export const Reservation = require("./reservation.png");
export const RoomRates = require("./room-rates.png");
export const sparrowdown = require("./sp-arrow-down.png");
export const IcFlag = require("./ic-flag.png");
export const SideBarArrow = require("./sideBar-arrow.png");
export const SideBarArrowGreen = require("./sidebar-arrow-green.png");
export const Person1 = require("./person1.png");
export const Person2 = require("./person2.png");
export const Person3 = require("./person3.png");
export const room1 = require("./room1.png");
export const room2 = require("./room2.png");
export const room3 = require("./room3.png");
export const redaerrow = require("./redaerrow.png");
export const grayaerrow = require("./grayaerow.png");
export const greenaerrow = require("./greenarrow.png");
export const DashboardAerrow = require("./dashboardAero.png");
export const White = require("./white.PNG");
export const chat = require("./chat.png");
export const user = require("./user.png");
export const favicon = require("./favicon.png");
export const BackgroundHome = require("./backgroundHome.jpg");
export const Hivebanner = require("./hivebanner.png");
export const ourbanner = require("./ours.png");

export const HomeBackground = require("./homebackground.png");
export const Blue24 = require("./blue24.PNG");
export const SSLSecureLogo = require("./ssl-secure-logo.png");
export const PCILogo = require("./pci-logo-logo.png");
export const DirectBullet = require("./DirectBullet.png");
export const HouseBullet = require("./HouseBullet.png");
export const HouseBulletDone = require("./HouseBulletDone.png");
export const DirectCard = require("./DirectCard.png");
export const DirectCardPay = require("./direct-card-pay.png");
export const TableIconBottomR = require("./tableIconBottomR.png");
export const TableIconTopR = require("./tableIconTopR.png");
