import { CircularProgress } from "@mui/material";
import React from "react";

const Loader: React.FC = () => {
  return (
    <div id="loader" className="loader-wrapper">
      <CircularProgress color="primary" sx={{position:"fixed", top:"60%"}}  thickness={3} size={44} />
    </div>
  );
};

export default Loader;
