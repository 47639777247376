import { Button, Grid } from '@mui/material'
import { Field, Formik } from 'formik'
import React from 'react'
import FormikInput from '../../shared/material-ui-formik/FormikInput'
import FormikSelect from '../../shared/material-ui-formik/FormikSelect'
import { useTranslation } from 'react-i18next'

const AddVirtualRates = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className='p-3'>
        <Formik>
            <Grid container spacing={2} alignItems="end">
                <Grid item lg={6}>
                    <Field
                    label={t("Name")}
                    name="roleId"
                    id="roleId"
                    defaultOption
                    // options={data?.map((role) => ({
                    //     title: role.title,  
                    //     value: role.value,
                    // }))}
                    component={FormikInput}
                    />
                </Grid>
                <Grid item lg={3}>
                    <Field
                    label={t("Name")}
                    name="roleId"
                    id="roleId"
                    defaultOption
                    // options={data?.map((role) => ({
                    //     title: role.title,  
                    //     value: role.value,
                    // }))}
                    component={FormikSelect}
                    />
                </Grid>
                <Grid item lg={3}>
                    <Field
                    label={t("Name")}
                    name="roleId"
                    id="roleId"
                    defaultOption
                    // options={data?.map((role) => ({
                    //     title: role.title,  
                    //     value: role.value,
                    // }))}
                    component={FormikInput}
                    />
                </Grid>
            </Grid> 
        </Formik>

        <div className='w-100 d-flex' style={{justifyContent:"space-between"}}>
           <h6>{t("conditions")}</h6>
           <Button className="mt-1 comman-button"
              size="large"
              variant="contained"
              disableElevation
              color="primary"
            //   style={{
            //       justifyContent: "center",
            //       width: "188px",
            //       height: "44px",
            //       textTransform: "capitalize",
            //       background:
            //       "linear-gradient(180deg, #255480 0%, #173450 100%)",
            //   }}
              >
              {t("Add conditions")}
          </Button>
        </div>
        <div className='w-100 d-flex justify-content-center'>
          <Button className="mt-1 comman-cancel-button"
              size="large"
              variant="contained"
              disableElevation
              color="primary"
            //   style={{
            //       justifyContent: "center",
            //       marginRight:"10px",
            //       width: "98px",
            //       height: "44px",
            //       textTransform: "capitalize",
            //       background:
            //       "gray",
            //   }}
              >
              {t("Cancel")}
          </Button>
          <Button className="mt-1 ml-1 comman-button"
              size="large"
              variant="contained"
              disableElevation
              color="primary"
            //   style={{
            //       justifyContent: "center",
            //       width: "98px",
            //       height: "44px",
            //       textTransform: "capitalize",
            //       background:
            //       "linear-gradient(180deg, #255480 0%, #173450 100%)",
            //   }}
              >
              {t("Save")}
          </Button>
        </div>
    </div>
  )
}

export default AddVirtualRates