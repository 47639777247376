import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createHotel = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateHotel = async (data: object, hotelId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteHotelApiCall = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const fetchHotelList = async (pageNo, itemsPerPage, searchText) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.HOTEL_RESOURCE_ROUTE +
        `?page_no=${pageNo}&search_text=${searchText}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchDestinationForSelect = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.DESTINATION_LIST_FOR_SELECT,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHotelDetail = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.HOTEL_RESOURCE_ROUTE + `/${hotelId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

//GET_DIRECT_HOTEL_DETAIL

export const fetchDirectHotelDetail = async (hotelId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.GET_DIRECT_HOTEL_DETAIL+ `${hotelId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};