import React from "react";
import ReactDOM from "react-dom/client";
// import { Provider } from "react-redux";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Reducer/store";
import "./i18next";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

// const consoleError = console.error;
// console.error = (...args) => {
//   if (args.length > 0 && typeof args[0] === 'string' && args[0].includes('Warning:')) {
//     return;
//   }
//   consoleError(...args);
// };
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

console.clear();
console.error = () => {};
console.warn = () => {};



let persistor = persistStore(store);


root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>  
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
