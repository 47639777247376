import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, IconButton, MenuItem, useMediaQuery } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import portalTheme from "../themes/App.theme";
import {
  Admin,
  Calender,
  CashBooks,
  Dashboard,
  Guest,
  HiveLogoInside,
  HouseKeeping,
  Logout,
  Reports,
  Reservation,
  RoomRates,
  SideBarArrow,
  chat,
  AdminIcon,
  Pos,
  SideBarArrowGreen,
  user,
  Person1,
  favicon,
} from "../assets/images";
import Storage from "../utils/HandelLocalStorage";
import { connect, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  getSidebarFailed,
  getSidebarSuccess,
  logOutSuccess,
} from "../Reducer/authSlice";
import { useTranslation } from "react-i18next";
import { getRecord } from "../apis/services/CommonApiService";
import { ApiEndPoints } from "../apis/ApiEndPoints";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { ownerPath } from "../utils/JsonData";
import { Routing } from "../shared/constants/routing";
import GoogleTranslate from "./GoogleTranslate";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";

const useStyles = makeStyles(() => ({
  Navigation: {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    width: 260,
    zIndex: 1201,
    boxShadow: " inset -1px 0px 0px #DFE5F1",
    transition: "all 0.3s ease-in-out",
    // [portalTheme.breakpoints.down("md")]: {
    //   paddingTop: 0,
    //   zIndex: 5,
    // },
    "&.sidebar": {
      backgroundColor: [portalTheme.palette.primary.light],
      "& .hive-logo": {
        // padding: "15px",
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        margin: "10px",
        // padding: "35px 72px 0px 62px",
      },
      "& .sideBar-arrow": {
        top: "23px",
        position: "fixed",
        left: "245px",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        width: "30px",
      },
      // [portalTheme.breakpoints.down("md")]: {
      //   "& .sideBar-arrow": {
      //     left: "10px",
      //     cursor: "pointer",
      //     transform: " rotate(180deg)",
      //     transition: "all 0.3s ease-in-out",
      //   },
      // },
      "& .main-navigation": {
        overflow: "auto",
        maxHeight: "80%",
        height: "100%",
        // minHeight:"80%",
        "& .nav-link": {
          display: "block",
          padding: 0,
          "& .nav-item": {
            position: "relative",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            fontSize: 15,
            lineHeight: "19px",
            padding: "12px 12px",
            // color: [portalTheme.palette.secondary.light],
            "& .active-div": {
              background: "#1C3A66",
              width: "4px",
              height: "24px",
              position: "fixed",
              display: "none",
            },
            "& .nav-icon": {
              minWidth: 22,
              width: 22,
              height: 22,
              padding: "0px 19px 0 33px",
            },
            "& > span": {
              display: "inline-block",
              marginRight: 8,
              color: "#000",
              fontWeight: " 500 !important",
              fontSize: "16px",
              lineHeight: "24px",
              fontFamily: "Plus Jakarta Sans !important",
            },
          },
        },
      },
    },
  },
}));

export const menus = [
  {
    image: Dashboard,
    lable: "Dashboard",
    value: "dashboard",
  },
  {
    image: Calender,
    lable: "Calendar",
    value: "calendar",
  },
  {
    image: Reservation,
    lable: "Reservation",
    value: "reservation",
  },
  {
    image: chat,
    lable: "Chat",
    value: "chat",
  },
  {
    image: Reports,
    lable: "Report",
    items: [
      {
        lable: "Reports",
        value: "reports",
      },
      // {
      //   lable: "Night Reports",
      //   value: "night-reports",
      // },
      {
        lable: "Data Migration",
        value: "data-migration",
      },
    ],
  },
  {
    image: HouseKeeping,
    lable: "Housekeeping",
    // value: "housekeeping",
    value: "housekeepingnew",
  },

  {
    image: RoomRates,
    lable: "Room rates",
    value: "roomrates",
  },
  {
    image: AdminIcon,
    lable: "Admin",
    items: [
      {
        lable: "Admin Users",
        value: "admin-users",
      },
      {
        lable: "Roles",
        value: "roles",
      },
      {
        lable: "Shift",
        value: "shift",
      },
      {
        lable: "Popular Places",
        value: "popular-places",
      },
    ],
  },
  // {
  //   image: CashBooks,
  //   lable: "Shift",
  //   value: "shift",
  // },
  // {
  //   image: RoomRates,
  //   lable: "Roles",
  //   value: "roles",
  // },
  // {
  //   image: RoomRates,
  //   lable: "Admin Users",
  //   value: "admin-users",
  // },

  {
    image: Pos,
    lable: "POS",
    items: [
      {
        lable: "Kitchen Dashboard",
        value: "kitchen-dashboard",
      },
      {
        lable: "Sales List",
        value: "saleslist",
      },
      {
        lable: "Visitor Sales List",
        value: "visitorsaleslist",
      },
      // {
      //   lable: "Vendors",
      //   value: "vendors",
      // },
      {
        lable: "Products",
        value: "products",
      },
      {
        lable: "POS Reports",
        value: "pos-reports",
      },
      // {
      //   lable: "Tax",
      //   value:"tax"
      // }
    ],
  },
  // {
  //   image: Admin,
  //   lable: "Popular Places",
  //   value: "popular-places",
  // },
  {
    image: Reports,
    lable: "Management",
    items: [
      {
        lable: "Device Management",
        value: "lock",
      },
      {
        lable: "Channle Management",
        value: "channal-management",
      },
      {
        lable: "Connected OTAs",
        value: "iframe-call",
      },
    ],
  },
  {
    image: Guest,
    lable: "Master",
    items: [
      {
        lable: "Guests",
        value: "guest",
      },
      {
        lable: "Group Info",
        value: "group-information",
      },
      {
        lable: "Company Profile",
        value: "companyprofile",
      },
      {
        lable: "Agreement",
        value: "agreement/182",
      },
    ],
  },
  {
    image: Admin,
    lable: "Setting",
    value: "setting",
  },
];

const superAdminMenu = [
  {
    image: Reservation,
    lable: "Hotels",
    value: "hotels",
  },
  {
    image: HouseKeeping,
    lable: "Module",
    value: "module",
  },
  {
    image: Reports,
    lable: "Destinations",
    value: "destination",
  },
  {
    image: Guest,
    lable: "Owners",
    value: "owners",
  },
];

const OwnerMenu = [
  {
    image: Dashboard,
    lable: "Dashboard",
    value: "owner-dashboard",
    items: [],
  },
  {
    image: Reports,
    lable: "Reports",
    value: "owner-reports",
    items: [],
  },

  // {
  //   image: Reports,
  //   lable: "Management",
  //   // value: "lock",
  //   items: [
  //     {
  //       lable: "Device Management",
  //       value: "lockaa",
  //     },
  //     {
  //       lable: "Channle Management",
  //       value: "channal-managementaa",
  //     },
  //   ],
  // },
];

const Sidebar = (props) => {
  const classes = useStyles();
  const { authUserDetails, permissionList } = props;
  const { t } = useTranslation();
  // const authUserDetails = JSON.parse(
  //   localStorage.getItem("userDetails") || "{}"
  // );
  // const handleActiveMenuClick = (id: string) => {
  //   const activeDiv = document.getElementById(id);
  //   if (activeDiv.style.display === "flex") activeDiv.style.display = "none";
  //   else activeDiv.style.display = "flex";
  // };
  // const theme = useTheme();
  const isSmallScreen = useMediaQuery(portalTheme.breakpoints.down("md"));
  // console.log(isSmallScreen, 304);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [indexs, setindexs] = useState();
  const [permission, setpermission] = useState([]);
  const [userState, setUserState] = useState<any>({});

  const getRolesDetail = async () => {
    // const result = await getRecord(
    //   authUserDetails?.role_id,
    //   ApiEndPoints.ROLES_PERMISSION
    // );
    // if (result?.status === 200) {
    let result = permissionList;
    if (permissionList?.status === 200) {
      const menusItem = [];
      const roleList = [];
      for (let j in result.data) {
        for (let i in result.data[j]?.permissionsList) {
          if (result.data[j]?.permissionsList[i].type === 2) {
            if (result.data[j]?.permissionsList[i].hasPermission !== null) {
              // || result.data[j]?.module_name == "Tax"
              for (let y in menus) {
                for (let t in menus[y]?.items) {
                  if (
                    menus[y]?.items[t]?.lable === result.data[j]?.module_name
                  ) {
                    menusItem.push({
                      lable: menus[y]?.items[t]?.lable,
                      //  == "Iframe" ? "Connected OTAs" : menus[y]?.items[t]?.lable,
                      value: menus[y]?.items[t]?.value,
                    });
                  }
                }
              }
              roleList.push({
                id: result.data[j]?.id,
                module_name: result.data[j]?.module_name,
                permissionsList:
                  result.data[j]?.permissionsList[i].hasPermission,
              });
            }
          }
        }
      }
      // console.log(menusItem, roleList,result.data, 328)
      // setMenusItem(menusItem)
      setpermission(roleList);
    } else {
      setpermission([]);
    }
  };

  let ignore;
  useEffect(() => {
    if (!ignore && authUserDetails?.role_id) {
      getRolesDetail();
    }
    const data = localStorage.getItem("userDetails");
    if (data) {
      const userDetails = JSON.parse(data);
      setUserState(userDetails?.hotelDetail);
    }
    // console.log( 11)

    return () => {
      ignore = true;
    };
  }, [permissionList]);

  // console.log(permission, 365)

  function hasChildren(item: { items: any }) {
    const { items: children } = item;

    if (children === undefined) {
      return false;
    }

    if (children.constructor !== Array) {
      return false;
    }

    if (children.length === 0) {
      return false;
    }

    return true;
  }

  const coursesPage = (menu, index) => {
    setindexs(index);
    if (menu.value) {
      navigate(`/${menu.value}`);
      setOpen(false);
      Storage.clearItem("userHotelsDetails");
    } else {
      hasChildren(menu) ? setOpen((prev) => !prev) : console.log("SingleLevel");
    }
  };

  const location = useLocation();

  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logOutSuccess(null));
    Storage.clearItem("userDetails");
    Storage.clearItem("userOwnerDetails");
    Storage.clearItem("persist:root");
    Storage.clearItem("userHotelsDetails");

    localStorage.removeItem("lng");
    if (ownerPath?.includes(location?.pathname)) {
      navigate("/owner-login", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };

  React.useEffect(() => {
    // pathName =  window.location.pathname
    if (
      authUserDetails?.role_id &&
      ownerPath?.includes(location?.pathname)
      //  && location?.pathname !== Routing?.Reports
    ) {
      logOut();
    } else if (
      !authUserDetails?.role_id &&
      !ownerPath?.includes(location?.pathname)
    ) {
      logOut();
    }
  }, [location?.pathname]);

  // React.useEffect(() => {
  //   const updateWindowOnResize = () => {
  //     if (window.innerWidth < 900) {
  //       document.body.classList.remove("show-sidebar");
  //     }
  //   };
  //   window.addEventListener("resize", updateWindowOnResize);
  //   return () => window.removeEventListener("resize", updateWindowOnResize);
  // }, []);

  const clickSidebar = () => {
    if (isSmallScreen) {
      toggleSidebar();
    }
  };

  const [openSidebar, setOpenSidebar] = useState(true);

  const newToggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const toggleSidebar = () => {
    const sideBarArrow = document.getElementById("sideBar-arrow");

    if (document.body.classList.contains("show-sidebar")) {
      document.body.classList.remove("show-sidebar");
      dispatch(getSidebarFailed());

      // if (sideBarArrow) {
      //   // if (window.innerWidth < 900) {
      //   //   sideBarArrow.style.left = "10px";
      //   //   sideBarArrow.style.transform = " rotate(180deg)";
      //   //   sideBarArrow.style.transition = "all 0.3s ease-in-out";
      //   // } else {

      //   sideBarArrow.style.left = isSmallScreen ? "210px" : "245px";
      //   sideBarArrow.style.transform = " rotate(0deg)";
      //   sideBarArrow.style.transition = "all 0.3s ease-in-out";
      //   // }
      // }
    } else {
      document.body.classList.add("show-sidebar");
      dispatch(getSidebarSuccess());

      // if (sideBarArrow) {
      //   // if (window.innerWidth < 900) {
      //   //   sideBarArrow.style.left = "245px";
      //   //   sideBarArrow.style.transform = " rotate(0deg)";
      //   //   sideBarArrow.style.transition = "all 0.3s ease-in-out";
      //   // } else {

      //   sideBarArrow.style.left = "10px";
      //   sideBarArrow.style.transform = " rotate(180deg)";
      //   sideBarArrow.style.transition = "all 0.3s ease-in-out";
      //   // }
      // }
    }
  };

  useEffect(() => {
    if (
      (!document.body.classList.contains("show-sidebar") && isSmallScreen) ||
      (document.body.classList.contains("show-sidebar") && !isSmallScreen)
    ) {
      toggleSidebar();
    }
  }, [isSmallScreen]);

  return (
    <aside className={`sidebar ${classes.Navigation}`}>
      {/* <div className="d-flex justify-content-between"> */}

      <div className="d-flex justify-content-between mt-2 ">
        <div className="d-flex justify-content-start ml-3  mr-2">
          <img
            className="hive-logo"
            style={{
              borderRadius: userState ? "50%" : "0px",
              width: userState ? "40px" : "30px",
              height: userState ? "40px" : "30px",
            }}
            src={userState ? userState?.logo : favicon}
            alt="Logo"
          />
          <div style={{ display: "grid", alignContent: "center" }}>
            <div
              id="plus-jakarta"
              style={{ fontSize: "16px", wordBreak: "break-all" }}
            >
              {userState?.company_name}
            </div>
            <div
              id="plus-jakarta"
              style={{ fontSize: "14px", color: "737373" }}
            >
              {!authUserDetails.role_id && authUserDetails?.id
                ? t("Owner Desk")
                : t("Front Desk")}
            </div>
          </div>
        </div>
        <div className="mr-1">
          <IconButton
            onClick={toggleSidebar}
            sx={{
              backgroundColor: "#382DAE",
              color: "#ffffff",
              ":hover": { backgroundColor: "#382DAE" },
            }}
          >
            <MenuOpenOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <div>
        {/* {openSidebar && ( <CloseIcon
        style={{
          background: "#38E078",
          borderRadius: "100%",
          padding: "5px",
          width: "15px",
          height: "15px",
        }}
        onClick={()=>newToggleSidebar()}
        />)} */}
        {/* </div> */}
      </div>
      {/* <img
        className="sideBar-arrow"
        role="button"
        src={SideBarArrowGreen}
        alt="sideBar-arrow"
        id="sideBar-arrow"
        onClick={toggleSidebar}
      /> */}

      <ul className="main-navigation">
        {/* admin menu */}

        {authUserDetails &&
          authUserDetails.role_id &&
          menus?.map((menu, index) => {
            return (
              <React.Fragment key={menu.lable}>
                {permission.map(
                  (item, i) =>
                    item.module_name === menu.lable && (
                      <MenuItem
                        className={`nav-link "open"`}
                        key={item.id}
                        onClick={() => coursesPage(menu, index)}
                      >
                        <NavLink
                          style={{
                            pointerEvents: menu.value ? "auto" : "none",
                          }}
                          to={`/${menu.value}`}
                          onClick={clickSidebar}
                          // key={menu.value}
                          className={({ isActive }) =>
                            "nav-item" + (isActive ? " active" : "")
                          }
                          title={menu.lable}
                        >
                          <img
                            className="sidebar-icon"
                            src={menu.image}
                            alt="img"
                          />

                          <span style={{ fontFamily: "Plus Jakarta Sans " }}>
                            {t(menu.lable)}
                          </span>
                          {menu?.items &&
                            (indexs === index ? (
                              open ? (
                                <KeyboardArrowUpIcon
                                  key={item.module_name}
                                  sx={{ color: "#000" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  key={item.module_name}
                                  sx={{ color: "#000" }}
                                />
                              )
                            ) : (
                              <KeyboardArrowDownIcon
                                key={item.module_name}
                                sx={{ color: "#000" }}
                              />
                            ))}
                        </NavLink>
                      </MenuItem>
                    )
                )}
                {indexs === index && (
                  <>
                    {open === true
                      ? menu?.items?.map((child, key) => (
                          <React.Fragment key={child.lable}>
                            {permission.map((items) =>
                              items.module_name === child.lable ? (
                                <MenuItem
                                  style={{ marginLeft: "75px" }}
                                  className={`nav-link "open"`}
                                  key={items.id}
                                >
                                  <NavLink
                                    to={`/${child.value}`}
                                    // key={child.lable}
                                    onClick={clickSidebar}
                                    className={({ isActive }) =>
                                      "nav-item" + (isActive ? " active" : " ")
                                    }
                                    title={child.lable}
                                    // onClick={() => coursesPage(menu, index)}
                                  >
                                    <span>{t(child.lable)}</span>
                                  </NavLink>
                                </MenuItem>
                              ) : (
                                ""
                              )
                            )}
                          </React.Fragment>
                        ))
                      : ""}
                  </>
                )}
              </React.Fragment>
            );
          })}

        {/* superadmin menu */}
        {authUserDetails &&
          authUserDetails.role_id === 1 &&
          superAdminMenu?.map((menu, index) => {
            return (
              <MenuItem className={`nav-link "open"`} key={menu.lable}>
                <NavLink
                  //  <div
                  // className="nav-item"
                  to={`/${menu.value}`}
                  // activeClassName="active"
                  className={({ isActive }) =>
                    "nav-item" + (isActive ? " active" : "")
                  }
                  title={menu.lable}
                  onClick={() => coursesPage(menu, index)}
                >
                  {/* {menu.lable === "Dashboard" && pathName === "/dashboard" && (
                    <div className="active-div" id="active-div1"></div>
                  )}
                  {menu.lable === "Guests" && pathName==="/guest" && (
                    <div className="active-div" id="active-div1"></div>
                  )} */}
                  {/* <Avatar
                    className="nav-icon"
                    variant="square"
                    src={menu.image}
                  /> */}
                  <img className="sidebar-icon" src={menu.image} alt="icon" />
                  <span style={{ fontFamily: "Plus Jakarta Sans !important" }}>
                    {t(menu.lable)}
                  </span>
                  {/* </div> */}
                </NavLink>
              </MenuItem>
            );
          })}

        {/* Owner DAshbord */}
        {authUserDetails &&
          !authUserDetails.role_id &&
          authUserDetails?.id &&
          OwnerMenu?.map((menu, index) => {
            return (
              <React.Fragment key={menu.lable + index}>
                <MenuItem
                  className={`nav-link "open"`}
                  key={menu.lable}
                  onClick={() => coursesPage(menu, index)}
                >
                  <NavLink
                    style={{ pointerEvents: menu.value ? "auto" : "none" }}
                    to={`/${menu.value}`}
                    className={({ isActive }) =>
                      "nav-item" + (isActive ? " active" : "")
                    }
                    onClick={clickSidebar}
                    title={menu.lable}
                  >
                    <img
                      className="sidebar-icon"
                      src={menu?.image}
                      alt="icon"
                    />
                    <span
                      style={{ fontFamily: "Plus Jakarta Sans !important" }}
                    >
                      {t(menu?.lable)}
                    </span>
                    {menu?.items?.length > 0 &&
                      (indexs === index ? (
                        open ? (
                          <KeyboardArrowUpIcon sx={{ color: "#000" }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "#000" }} />
                        )
                      ) : (
                        <KeyboardArrowDownIcon sx={{ color: "#000" }} />
                      ))}
                  </NavLink>
                </MenuItem>
                {indexs === index && menu?.items?.length > 0 && (
                  <>
                    {open === true
                      ? menu?.items?.map((child, key) => (
                          <MenuItem
                            style={{ marginLeft: "75px" }}
                            className={`nav-link "open"`}
                            key={child.lable}
                          >
                            <NavLink
                              to={`/${child.value}`}
                              className={({ isActive }) =>
                                "nav-item" + (isActive ? " active" : "")
                              }
                              title={child.lable}
                              onClick={clickSidebar}
                            >
                              <span>{t(child.lable)}</span>
                            </NavLink>
                          </MenuItem>
                        ))
                      : ""}
                  </>
                )}
              </React.Fragment>
            );
          })}
        <Button
          size="large"
          variant="contained"
          disableElevation
          className="comman-button"
          color="primary"
          onClick={() => logOut()}
          style={{
            // display:"fixed",
            // bottom: "18px",
            marginBottom: "20px",
            left: "50px",
            width: "150px",
          }}
        >
          {t("Logout")}
        </Button>
        {/* <MenuItem className={`nav-link "open"`} onClick={() => logOut()}> */}
        {/* <NavLink */}
        {/* <div
            className="nav-item"
            // to={"#"}
            title={"Logout"}
          > */}
        {/* <img className="sidebar-icon" src={Logout} alt={"icon"} /> */}
        {/* <Avatar className="nav-icon" variant="square" src={Logout} /> */}
        {/* <span>{t("Logout")}</span> */}
        {/* </NavLink> */}
        {/* </div> */}
        {/* </MenuItem>  */}
      </ul>
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    authUserDetails: state.auth.userInfo,
    permissionList: state.auth.permission,
    sidebarIn: state.auth.sidebarIn,
  };
};

export default connect(mapStateToProps)(Sidebar);
