import React, { Suspense, lazy } from "react";
import { Routing } from "./constants/routing";
import {
  BrowserRouter as Router,
  Route,
  // Switch,
  // Redirect,
  Routes,
} from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import RoomRatesView from "../pages/RoomRates/RoomRatesView";
import { ToastContainer } from "react-toastify";
import Spinner from "../layout/Spinner";
import DirectLoader from "../layout/DirectLoader";

// import TaxList from "../pages/POS/Tax/TaxList";

const AverageDailyRateList = lazy(
  () => import("../pages/Reports/AverageDailyRateList")
);
const AddProduct = lazy(() => import("../pages/POS/Products/AddProduct"));
const EditProduct = lazy(() => import("../pages/POS/Products/EditProduct"));
const AddPOSAddOn = lazy(() => import("../pages/POS/AddOn/AddAddOn"));
const EditPOSAddOn = lazy(() => import("../pages/POS/AddOn/EditAddOn"));
const AddProductType = lazy(
  () => import("../pages/POS/ProductTypes/AddProductType")
);
const EditProductType = lazy(
  () => import("../pages/POS/ProductTypes/EditProductType")
);

const AddCompanyProfile = lazy(
  () => import("../pages/CompanyProfile/AddCompanyProfile")
);

const TaxList = lazy(() => import("../pages/POS/Tax/TaxList"));
const VendorListView = lazy(
  () => import("../pages/POS/VendorsTabs")
);
const AddVendor = lazy(() => import("../pages/POS/Vendors/AddVendor"));
const EditVendor = lazy(() => import("../pages/POS/Vendors/EditVendor"));
const AddPoOrder = lazy(() => import("../pages/POS/POorders/AddPOorder"));
const EditPoOrder = lazy(() => import("../pages/POS/POorders/EditPOorder"));
const AddBookingRestriction = lazy(
  () =>
    import("../pages/RoomRates/BookingRestrictions.js/AddBookingRestriction")
);
const EditBookingRestriction = lazy(
  () =>
    import("../pages/RoomRates/BookingRestrictions.js/EditBookingRestriction")
);

const ProductsAndTypesTab = lazy(
  () => import("../pages/POS/ProductsAndTypesTab")
);

const CancellationList = lazy(
  () => import("../pages/Reports/CancellationList")
);
const InHouseList = lazy(() => import("../pages/Reports/InHouseList"));
const InHouseListOld = lazy(() => import("../pages/Reports/InHouseListOld"));
const NoShowList = lazy(() => import("../pages/Reports/NoShowList"));
const GuestList = lazy(() => import("../pages/Reports/GuestList"));
const RevenueByRoomTypeList = lazy(
  () => import("../pages/Reports/RevenueByRoomTypeList")
);
const RevenueByRoomTypeListNew = lazy(
  () => import("../pages/Reports/RevenueByRoomTypeListNew")
);
const ForecastList = lazy(() => import("../pages/Reports/ForecastList"));
const ADRRevparList = lazy(() => import("../pages/Reports/ADRRevparList"));
const ArrivalList = lazy(() => import("../pages/Reports/ArrivalList"));
const CheckInList = lazy(() => import("../pages/Reports/CheckInList"));
const RoomAvailabilityList = lazy(
  () => import("../pages/Reports/RoomAvailabilityList")
);
const HouseKeepingList = lazy(
  () => import("../pages/Reports/HousekeepingList")
);
const GuestProfileList = lazy(
  () => import("../pages/Reports/GuestProfileList")
);
const RevenueBySourceList = lazy(
  () => import("../pages/Reports/RevenueBySourceList")
);
const RateList = lazy(() => import("../pages/Reports/RateList"));
const CheckOutList = lazy(() => import("../pages/Reports/CheckOutList"));
const DepartureList = lazy(() => import("../pages/Reports/DepartureList"));
const AddSeasonalPricing = lazy(
  () => import("../pages/RoomRates/SeasonalPricing.js/AddSeasonalPricing")
);
const EditSeasonalPricing = lazy(
  () => import("../pages/RoomRates/SeasonalPricing.js/EditSeasonalPricing")
);

/* Import lazy routes */
const PublicLayout = lazy(() => import("../layout/PublicLayout"));
const Login = lazy(() => import("../pages/Login/Login"));
const OwnerLogin = lazy(() => import("../pages/Owner/OwnerLogin/OwnerLogin"));

const Terms = lazy(() => import("../pages/About/Terms"));
const Privacy = lazy(() => import("../pages/About/Privacy"));
const Contact = lazy(() => import("../pages/About/Contact"));

const LoginHotelDetails = lazy(
  () => import("../pages/Login/hotel-details/LoginHotelDetails")
);
const Dashboard = lazy(() => import("../pages/Dashboard/DashBoard"));
const Reservation = lazy(() => import("../pages/Reservation/Reservation"));
const ReservationGuestView = lazy(
  () => import("../pages/Reservation/ReservationGuestView")
);
const NewBooking = lazy(() => import("../pages/NewBooking/NewBooking"));
const SellInvoice = lazy(() => import("../pages/POS/Sales/SellInvoice"));
const TraditionalSellInvoice = lazy(() => import("../pages/POS/TraditionalPos/TraditionalSellInvoice"));
// const PosMain = lazy(() => import("../pages/POS/PosMain"));
const TraditionalPos = lazy(() => import("../pages/POS/TraditionalPos/TraditionalPos"));
const POSReports = lazy(() => import("../pages/POS/POSReport/POSReport"));

const ViewSales = lazy(() => import("../pages/POS/Sales/ViewSales"));
const VisitorViewSales = lazy(
  () => import("../pages/POS/Sales/VisitorSales/ViewVisitorSales")
);
const KitchenDashboard = lazy(
  () => import("../pages/POS/KitchenDashboard/KitchenDashboard")
);
const PrintPOSsales = lazy(() => import("../pages/POS/Sales/PrintInvoice"));
const ViewReservation = lazy(
  () => import("../pages/Reservation/ViewReservation")
);
const Guest = lazy(() => import("../pages/Guest/Guest"));
const Calendar = lazy(() => import("../pages/Calendar/Calendar"));
const NonAssignedRooms = lazy(
  () => import("../pages/Calendar/NonAssignedRooms")
);

const Report = lazy(() => import("../pages/Reports/Report"));
const VacantRoomList = lazy(() => import("../pages/Reports/VacantRoomList"));
const CashBook = lazy(() => import("../pages/CashBooks/CashBook"));
const HouseKeeping = lazy(() => import("../pages/HouseKeeping/HouseKeeping"));
const HouseKeepingNew = lazy(() => import("../pages/HouseKeeping/HouseKeepingNew"));
const HouseKeeper = lazy(() => import("../pages/HouseKeeping/HouseKeeper/HouseKeeper"));
const HouseKeeperCleanRoom = lazy(() => import("../pages/HouseKeeping/HouseKeeper/HouseKeeperCleanRoom"));
const SalesList = lazy(() => import("../pages/POS/Sales/SalesList"));
const VisitorSalesList = lazy(
  () => import("../pages/POS/Sales/VisitorSales/VisitorSalesList")
);

const Admin = lazy(() => import("../pages/Admin/Admin"));
const Accounting = lazy(() => import("../pages/Accounting/Accounting"));
const Setting = lazy(() => import("../pages/Setting/RoomTypeAndRooms"));
const CreateHotel = lazy(() => import("../pages/SuperAdmin/Hotel/CreateHotel"));
const EditHotel = lazy(() => import("../pages/SuperAdmin/Hotel/EditHotel"));

const ViewHotel = lazy(() => import("../pages/SuperAdmin/Hotel/ViewHotel"));
const ViewLoginHotel = lazy(() => import("../pages/Dashboard/ViewLoginHotel"));
const HotelList = lazy(() => import("../pages/SuperAdmin/Hotel/HotelList"));
const Module = lazy(() => import("../pages/SuperAdmin/Modules/Module"));
const NewModule = lazy(
  () => import("../pages/SuperAdmin/Modules/CreateModule")
);

const EditModule = lazy(() => import("../pages/SuperAdmin/Modules/EditModule"));
const NewGuest = lazy(() => import("../pages/Guest/AddGuest"));
const EditGuest = lazy(() => import("../pages/Guest/EditGuest"));
const OwnerDashBoard = lazy(
  () => import("../pages/Owner/OwnerDashboard/OwnerDashBoard")
);
const OwnerReport = lazy(
  () => import("../pages/Owner/OwnerReport/OwnerReport")
);
const OwnerIframeReport = lazy(
  () => import("../pages/Owner/OwnerReport/OwnerIframeReport")
);
const HotelWiseOwnerReport = lazy(
  () => import("../pages/Owner/HotelWiseReport/HotelWiseOwnerReport")
);

const GroupBooking = lazy(() => import("../pages/GroupBooking/GroupBooking"));

const AddRoom = lazy(() => import("../pages/Setting/AddRoom"));
const EditRoom = lazy(() => import("../pages/Setting/EditRoom"));
const EditRoomCategory = lazy(
  () => import("../pages/Setting/RoomCategory/EditRoomCategory")
);
const EditRoomRate = lazy(() => import("../pages/RoomRates/EditRoomRate"));
const EditRoomRateDateWise = lazy(
  () => import("../pages/RoomRates/EditRoomRatesDateWise")
);
const AddRoomTypes = lazy(
  () => import("../pages/Setting/RoomCategory/AddRoomTypes")
);

const AddRoomRate = lazy(() => import("../pages/RoomRates/AddRoomRate"));
const CompanyProfileView = lazy(
  () => import("../pages/CompanyProfile//CompanyProfileView")
);
const EditCompanyProfile = lazy(
  () => import("../pages/CompanyProfile//EditCompanyProfile")
);
const DiscountedRate = lazy(
  () => import("../pages/RoomRates/discountRates/DiscountedRate")
);
const EditDiscountedRate = lazy(
  () => import("../pages/RoomRates/discountRates/EditDiscountedRate")
);

const AddDiscountedRate = lazy(
  () => import("../pages/RoomRates/discountRates/AddDiscountRate")
);
const GroupInformation = lazy(
  () => import("../pages/GroupInformation/GroupInformation")
);
const AddGroupInformation = lazy(
  () => import("../pages/GroupInformation/AddGroupInformation")
);
const EditGroupInformation = lazy(
  () => import("../pages/GroupInformation/EditGroupInformation")
);
const HotalStatisticsList = lazy(
  () => import("../pages/Reports/HotalStatisticsList")
);
const FlashList = lazy(() => import("../pages/Reports/FlashList"));

const Lock = lazy(() => import("../pages/Lock/Lock"));
const ViewLock = lazy(() => import("../pages/Lock/ViewLock"));
const LockRecords = lazy(() => import("../pages/Lock/LockRecords"));
const ViewFolio = lazy(() => import("../pages/Reservation/ViewFolio"));
const EditReservation = lazy(
  () => import("../pages/Reservation/EditReservation")
);

const NightReports = lazy(() => import("../pages/NightReports/NightReports"));
const NightReportsList = lazy(
  () => import("../pages/NightReports/NightReportList")
);
const HotelJournalSummary = lazy(
  () =>
    import("../pages/NightReports/HotelJournalSummary/HotelJournalSummaryView")
);
const ShiftReconciliationCloseout = lazy(
  () =>
    import(
      "../pages/NightReports/ShiftReconciliationCloseout/ShiftReconciliationCloseout"
    )
);
const InHouseListNew = lazy(
  () => import("../pages/NightReports/InHouseList/InHouseList")
);

const FlashNightReport = lazy(
  () => import("../pages/NightReports/FlashNightReport")
);

const FinalTransactionCloseout = lazy(
  () =>
    import(
      "../pages/NightReports/FinalTransactionCloseout/FinalTransactionCloseout"
    )
);
const LedgerSummary = lazy(
  () => import("../pages/NightReports/LedgerSummary/LedgerSummary")
);
const NightAuditHotelStatastics = lazy(
  () =>
    import(
      "../pages/NightReports/NightAuditHotelStatastics/NightAuditHotelStatastics"
    )
);

const CheckInNightReport = lazy(
  () => import("../pages/NightReports/CheckInNightReport")
);

const CheckOutNightReport = lazy(
  () => import("../pages/NightReports/CheckOutNightReport")
);

const NightAuditNoShowReport = lazy(
  () => import("../pages/NightReports/NightAuditNoShow/NightAuditNoShow")
);

const TaxExemptReportStats = lazy(
  () =>
    import("../pages/NightReports/TaxExemptStatsReport/TaxExemptStatsReport")
);
const PrePaidReservations = lazy(
  () => import("../pages/NightReports/PrePaidReservations/PrePaidReservations")
);

const ChannelManagement = lazy(
  () => import("../pages/ChannelManagement/ChannelTab")
);
const NewProperty = lazy(
  () => import("../pages/ChannelManagement/Property/CreateProperty")
);
const RoomRate = lazy(() => import("../pages/ChannelManagement/RoomRate"));
const Availability = lazy(
  () => import("../pages/ChannelManagement/Availability")
);
const IframeCall = lazy(() => import("../pages/ChannelManagement/IframeCall"));

const AddOta = lazy(() => import("../pages/ConnectedOtas/CreateOta"));
const EditOta = lazy(() => import("../pages/ConnectedOtas/EditOta"));
const RoomType = lazy(() => import("../pages/Content/RoomType"));
const CreateRoomType = lazy(() => import("../pages/Content/CreateRoomType"));
const RatePlan = lazy(() => import("../pages/Content/RatePlan"));
const CreateRatePlan = lazy(() => import("../pages/Content/CreateRatePlan"));
const CreateRatesAndAvailability = lazy(
  () => import("../pages/Content/CreateRatesAndAvailability")
);

const NewTax = lazy(() => import("../pages/POS/Tax/AddTax"));
const EditTax = lazy(() => import("../pages/POS/Tax/EditTax"));
const NewAddOn = lazy(() => import("../pages/Setting/AddOn/AddAddOn"));
const EditAddOn = lazy(() => import("../pages/Setting/AddOn/EditAddOn"));
const NewOccCri = lazy(
  () => import("../pages/Setting/OccupancyCriteria/AddOccCri")
);
const EditOccCri = lazy(
  () => import("../pages/Setting/OccupancyCriteria/EditOccCri")
);

const NewSpeCri = lazy(
  () => import("../pages/Setting/SpecialCriteria/AddSpeCri")
);
const EditSpeCri = lazy(
  () => import("../pages/Setting/SpecialCriteria/EditSpeCri")
);

const EditMinStayCri = lazy(
  () => import("../pages/Setting/MinStayCriteria/EditMinStayCri")
);
const NewMinStayCri = lazy(
  () => import("../pages/Setting/MinStayCriteria/AddMinStayCri")
);
const EditBookingEngine = lazy(
  () => import("../pages/Setting/BookingEngine/EditBookingEngine")
);
const NewBookingEngine = lazy(
  () => import("../pages/Setting/BookingEngine/AddBookingEngine")
);
const EditCouponCode = lazy(
  () => import("../pages/Setting/CoupenCode/EditCouponCode")
);
const NewCouponCode = lazy(
  () => import("../pages/Setting/CoupenCode/AddCouponCode")
);
const EditEmailTemplate = lazy(
  () => import("../pages/Setting/CommunicationTemplates/EditEmailTemplate")
);
const EditSmsTemplate = lazy(
  () => import("../pages/Setting/CommunicationTemplates/EditSmsTemplate")
);

const Roles = lazy(() => import("../pages/Roles/Roles"));
const Agreement = lazy(() => import("../pages/Agreement/Agreement"));
const SuccessAgreement = lazy(() => import("../pages/Agreement/InfoPage"));
const SuccessBoking = lazy(
  () => import("../pages/DirectBooking/BookingSuccess")
);

const ViewGuest = lazy(() => import("../pages/Guest/ViewGuest"));

const Popular_Places = lazy(
  () => import("../pages/PopulerPlaces/PopulerPlaces")
);
const NewPopular_Places = lazy(
  () => import("../pages/PopulerPlaces/NewPopularPlaces")
);
const EditPopular_Places = lazy(
  () => import("../pages/PopulerPlaces/EditPopularPlaces")
);

const Destination = lazy(
  () => import("../pages/SuperAdmin/Destinations/Destinations")
);

const NewDestination = lazy(
  () => import("../pages/SuperAdmin/Destinations/NewDestination")
);
const EditDestination = lazy(
  () => import("../pages/SuperAdmin/Destinations/EditDestination")
);

const Owners = lazy(() => import("../pages/SuperAdmin/Owners/Owners"));

const NewOwners = lazy(() => import("../pages/SuperAdmin/Owners/NewOwners"));
const EditOwners = lazy(() => import("../pages/SuperAdmin/Owners/EditOwners"));

const AdminUsers = lazy(() => import("../pages/AdminUsers/ListAdminUser"));
const AddAdminUsers = lazy(() => import("../pages/AdminUsers/AddAdminUser"));
const EditAdminUsers = lazy(() => import("../pages/AdminUsers/EditAdminUser"));

const Shift = lazy(() => import("../pages/Shift/ShiftList"));
const NewShift = lazy(() => import("../pages/Shift/AddShift"));
const EditShift = lazy(() => import("../pages/Shift/EditShift"));

const Chat = lazy(() => import("../pages/Chat/Chat"));
const DirectChat = lazy(() => import("../pages/Chat/DirectChat/DirectChat"));
const DirectBooking = lazy(
  () => import("../pages/DirectBooking/DirectBooking")
);
const DirectBookingNew = lazy(
  () => import("../pages/DirectBookingNew/DirectBookingNew")
);
const DirectCheckIn = lazy(
  () => import("../pages/DirectCheckIn/DirectCheckIn")
);
const Invoice = lazy(() => import("../pages/Invoice/invoice"));
const DataMigration = lazy(
  () => import("../pages/DataMigration/DataMigration")
);
const OtaOverview = lazy(() => import("../pages/RoomRates/OtaOverviewNew"));
// const Home = lazy(() => import("../pages/About/Home"));

interface IRoute {
  id: number;
  path: string;
  component: any;
  isPrivateRoute: boolean;
}

const routes: IRoute[] = [
  {
    id: 1,
    path: Routing.Initial,
    component: Login,
    isPrivateRoute: false,
  },
  {
    id: 2,
    path: Routing.Login,
    component: Login,
    isPrivateRoute: false,
  },
  {
    id: 3,
    path: Routing.Terms,
    component: Terms,
    isPrivateRoute: false,
  },
  {
    id: 4,
    path: Routing.Privacy,
    component: Privacy,
    isPrivateRoute: false,
  },
  {
    id: 5,
    path: Routing.Contact,
    component: Contact,
    isPrivateRoute: false,
  },
  {
    id: 6,
    path: Routing.LoginHotelDetails,
    component: LoginHotelDetails,
    isPrivateRoute: false,
  },
  {
    id: 7,
    path: Routing.Dashboard,
    component: Dashboard,
    isPrivateRoute: true,
  },
  {
    id: 8,
    path: Routing.Roles,
    component: Roles,
    isPrivateRoute: true,
  },
  {
    id: 9,
    path: Routing.Reservation,
    component: Reservation,
    isPrivateRoute: true,
  },
  {
    id: 10,
    path: Routing.RoomRates,
    component: RoomRatesView,
    isPrivateRoute: true,
  },
  {
    id: 11,
    path: Routing.Guest,
    component: Guest,
    isPrivateRoute: true,
  },
  {
    id: 12,
    path: Routing.Calendar,
    component: Calendar,
    isPrivateRoute: true,
  },
  {
    id: 13,
    path: Routing.Reports,
    component: Report,
    isPrivateRoute: true,
  },
  {
    id: 14,
    path: Routing.VacantRoomList,
    component: VacantRoomList,
    isPrivateRoute: true,
  },
  {
    id: 15,
    path: Routing.ArrivalList,
    component: ArrivalList,
    isPrivateRoute: true,
  },
  {
    id: 16,
    path: Routing.DepartureList,
    component: DepartureList,
    isPrivateRoute: true,
  },
  {
    id: 17,
    path: Routing.CancellationList,
    component: CancellationList,
    isPrivateRoute: true,
  },
  {
    id: 18,
    path: Routing.InHouseList,
    component: InHouseList,
    isPrivateRoute: true,
  },
  {
    id: 19,
    path: Routing.NoShowList,
    component: NoShowList,
    isPrivateRoute: true,
  },
  {
    id: 20,
    path: Routing.GuestsList,
    component: GuestList,
    isPrivateRoute: true,
  },
  {
    id: 21,
    path: Routing.RevenueByRoomTypesList,
    component: RevenueByRoomTypeList,
    isPrivateRoute: true,
  },
  {
    id: 22,
    path: Routing.AverageDailyRateList,
    component: AverageDailyRateList,
    isPrivateRoute: true,
  },
  {
    id: 23,
    path: Routing.HouseKeeping,
    component: HouseKeeping,
    isPrivateRoute: true,
  },
  {
    id: 24,
    path: Routing.CashBook,
    component: CashBook,
    isPrivateRoute: true,
  },
  {
    id: 25,
    path: Routing.Admin,
    component: Admin,
    isPrivateRoute: true,
  },
  {
    id: 26,
    path: Routing.Accounting,
    component: Accounting,
    isPrivateRoute: true,
  },
  {
    id: 27,
    path: Routing.Setting,
    component: Setting,
    isPrivateRoute: true,
  },
  {
    id: 28,
    path: Routing.CreateHotel,
    component: CreateHotel,
    isPrivateRoute: true,
  },
  {
    id: 29,
    path: Routing.HotelList,
    component: HotelList,
    isPrivateRoute: true,
  },
  {
    id: 30,
    path: Routing.Module,
    component: Module,
    isPrivateRoute: true,
  },
  {
    id: 31,
    path: Routing.NewModule,
    component: NewModule,
    isPrivateRoute: true,
  },
  {
    id: 32,
    path: Routing.EditModule,
    component: EditModule,
    isPrivateRoute: true,
  },
  {
    id: 33,
    path: Routing.NewGuest,
    component: NewGuest,
    isPrivateRoute: true,
  },
  {
    id: 34,
    path: Routing.EditGuest,
    component: EditGuest,
    isPrivateRoute: true,
  },
  {
    id: 35,
    path: Routing.EditHotel,
    component: EditHotel,
    isPrivateRoute: true,
  },
  {
    id: 36,
    path: Routing.ViewHotel,
    component: ViewHotel,
    isPrivateRoute: true,
  },
  {
    id: 37,
    path: Routing.ViewLoginHotel,
    component: ViewLoginHotel,
    isPrivateRoute: true,
  },
  {
    id: 38,
    path: Routing.AddRoomCategory,
    component: AddRoomTypes,
    isPrivateRoute: true,
  },
  {
    id: 39,
    path: Routing.EditRoomCategory,
    component: EditRoomCategory,
    isPrivateRoute: true,
  },
  {
    id: 40,
    path: Routing.AddRoom,
    component: AddRoom,
    isPrivateRoute: true,
  },
  {
    id: 41,
    path: Routing.EditRoom,
    component: EditRoom,
    isPrivateRoute: true,
  },
  {
    id: 42,
    path: Routing.CreateRoomRates,
    component: AddRoomRate,
    isPrivateRoute: true,
  },
  {
    id: 43,
    path: Routing.CreateSeasonalRates,
    component: AddSeasonalPricing,
    isPrivateRoute: true,
  },
  {
    id: 44,
    path: Routing.EditSeasonalRoomRates,
    component: EditSeasonalPricing,
    isPrivateRoute: true,
  },
  {
    id: 45,
    path: Routing.CreateBookingRestriction,
    component: AddBookingRestriction,
    isPrivateRoute: true,
  },
  {
    id: 46,
    path: Routing.EditBookingRestriction,
    component: EditBookingRestriction,
    isPrivateRoute: true,
  },

  {
    id: 47,
    path: Routing.EditRoomRatesDateWise,
    component: EditRoomRateDateWise,
    isPrivateRoute: true,
  },

  {
    id: 48,
    path: Routing.EditRoomRates,
    component: EditRoomRate,
    isPrivateRoute: true,
  },

  {
    id: 49,
    path: Routing.Products,
    component: ProductsAndTypesTab,
    isPrivateRoute: true,
  },
  {
    id: 50,
    path: Routing.NewProducts,
    component: AddProduct,
    isPrivateRoute: true,
  },
  {
    id: 51,
    path: Routing.EditProducts,
    component: EditProduct,
    isPrivateRoute: true,
  },
  {
    id: 52,
    path: Routing.ProductTypes,
    component: ProductsAndTypesTab,
    isPrivateRoute: true,
  },
  {
    id: 53,
    path: Routing.NewProductTypes,
    component: AddProductType,
    isPrivateRoute: true,
  },
  {
    id: 54,
    path: Routing.EditProductTypes,
    component: EditProductType,
    isPrivateRoute: true,
  },
  {
    id: 55,
    path: Routing.Vendors,
    component: VendorListView,
    isPrivateRoute: true,
  },
  {
    id: 56,
    path: Routing.NewVendor,
    component: AddVendor,
    isPrivateRoute: true,
  },
  {
    id: 57,
    path: Routing.EditVendor,
    component: EditVendor,
    isPrivateRoute: true,
  },
  {
    id: 58,
    path: Routing.NewBooking,
    component: NewBooking,
    isPrivateRoute: true,
  },
  {
    id: 59,
    path: Routing.WalkIn,
    component: NewBooking,
    isPrivateRoute: true,
  },
  {
    id: 60,
    path: Routing.SellInvoice,
    component: SellInvoice,
    isPrivateRoute: true,
  },
  // {
  //   id: 60,
  //   path: Routing.Pos,
  //   component: PosMain,
  //   isPrivateRoute: true,
  // },
  {
    id: 61,
    path: Routing.SalesList,
    component: SalesList,
    isPrivateRoute: true,
  },
  {
    id: 62,
    path: Routing.ViewSales,
    component: ViewSales,
    isPrivateRoute: true,
  },
  {
    id: 63,
    path: Routing.PrintPOSsales,
    component: PrintPOSsales,
    isPrivateRoute: true,
  },
  {
    id: 64,
    path: Routing.ViewReservation,
    component: ViewReservation,
    isPrivateRoute: true,
  },
  {
    id: 65,
    path: Routing.ViewReservationFolio,
    component: ViewFolio,
    isPrivateRoute: true,
  },
  {
    id: 66,
    path: Routing.EditReservation,
    component: EditReservation,
    isPrivateRoute: true,
  },
  {
    id: 67,
    path: Routing.GroupBooking,
    component: GroupBooking,
    isPrivateRoute: true,
  },
  {
    id: 68,
    path: Routing.CompanyProfile,
    component: CompanyProfileView,
    isPrivateRoute: true,
  },
  {
    id: 69,
    path: Routing.NewCompanyProfile,
    component: AddCompanyProfile,
    isPrivateRoute: true,
  },
  {
    id: 70,
    path: Routing.EditCompanyProfile,
    component: EditCompanyProfile,
    isPrivateRoute: true,
  },
  {
    id: 71,
    path: Routing.DiscountedRate,
    component: DiscountedRate,
    isPrivateRoute: true,
  },
  {
    id: 72,
    path: Routing.EditDiscountedRate,
    component: EditDiscountedRate,
    isPrivateRoute: true,
  },
  {
    id: 73,
    path: Routing.AddDiscountedRate,
    component: AddDiscountedRate,
    isPrivateRoute: true,
  },
  {
    id: 74,
    path: Routing.GroupInformation,
    component: GroupInformation,
    isPrivateRoute: true,
  },
  {
    id: 75,
    path: Routing.AddGroupInformation,
    component: AddGroupInformation,
    isPrivateRoute: true,
  },
  {
    id: 76,
    path: Routing.EditGroupInformation,
    component: EditGroupInformation,
    isPrivateRoute: true,
  },
  {
    id: 77,
    path: Routing.HotalStatisticsList,
    component: HotalStatisticsList,
    isPrivateRoute: true,
  },
  {
    id: 78,
    path: Routing.FlashList,
    component: FlashList,
    isPrivateRoute: true,
  },
  {
    id: 79,
    path: Routing.Lock,
    component: Lock,
    isPrivateRoute: true,
  },
  {
    id: 80,
    path: Routing.ViewLock,
    component: ViewLock,
    isPrivateRoute: true,
  },
  {
    id: 81,
    path: Routing.ViewLockRecords,
    component: LockRecords,
    isPrivateRoute: true,
  },
  {
    id: 82,
    path: Routing.NightReports,
    component: NightReports,
    isPrivateRoute: true,
  },
  {
    id: 83,
    path: Routing.DataMigration,
    component: DataMigration,
    isPrivateRoute: true,
  },
  {
    id: 84,
    path: Routing.NightReportsList,
    component: NightReportsList,
    isPrivateRoute: true,
  },
  {
    id: 85,
    path: Routing.HotelJournalSummary,
    component: HotelJournalSummary,
    isPrivateRoute: true,
  },
  {
    id: 86,
    path: Routing.ShiftReconciliationCloseout,
    component: ShiftReconciliationCloseout,
    isPrivateRoute: true,
  },
  {
    id: 87,
    path: Routing.NightAuditHotelStatastics,
    component: NightAuditHotelStatastics,
    isPrivateRoute: true,
  },
  {
    id: 88,
    path: Routing.LedgerSummary,
    component: LedgerSummary,
    isPrivateRoute: true,
  },
  {
    id: 89,
    path: Routing.FinalTransactionCloseout,
    component: FinalTransactionCloseout,
    isPrivateRoute: true,
  },
  {
    id: 90,
    path: Routing.InHouseListNew,
    component: InHouseListNew,
    isPrivateRoute: true,
  },
  {
    id: 91,
    path: Routing.NightAuditNoShowReport,
    component: NightAuditNoShowReport,
    isPrivateRoute: true,
  },
  {
    id: 92,
    path: Routing.PrePaidReservations,
    component: PrePaidReservations,
    isPrivateRoute: true,
  },
  {
    id: 93,
    path: Routing.TaxExemptReportStats,
    component: TaxExemptReportStats,
    isPrivateRoute: true,
  },
  {
    id: 94,
    path: Routing.ChannelManagement,
    component: ChannelManagement,
    isPrivateRoute: true,
  },
  {
    id: 95,
    path: Routing.NewProperty,
    component: NewProperty,
    isPrivateRoute: true,
  },
  {
    id: 96,
    path: Routing.RoomRate,
    component: RoomRate,
    isPrivateRoute: true,
  },
  {
    id: 97,
    path: Routing.Availability,
    component: Availability,
    isPrivateRoute: true,
  },
  {
    id: 98,
    path: Routing.IframeCall,
    component: IframeCall,
    isPrivateRoute: true,
  },
  {
    id: 99,
    path: Routing.AddOta,
    component: AddOta,
    isPrivateRoute: true,
  },
  {
    id: 100,
    path: Routing.EditOta,
    component: EditOta,
    isPrivateRoute: true,
  },
  {
    id: 101,
    path: Routing.NewTax,
    component: NewTax,
    isPrivateRoute: true,
  },
  {
    id: 102,
    path: Routing.Tax,
    component: TaxList,
    isPrivateRoute: true,
  },
  {
    id: 103,
    path: Routing.EditTax,
    component: EditTax,
    isPrivateRoute: true,
  },
  {
    id: 104,
    path: Routing.NewAddOn,
    component: NewAddOn,
    isPrivateRoute: true,
  },
  {
    id: 105,
    path: Routing.EditAddOn,
    component: EditAddOn,
    isPrivateRoute: true,
  },
  {
    id: 106,
    path: Routing.ViewGuest,
    component: ViewGuest,
    isPrivateRoute: true,
  },
  {
    id: 107,
    path: Routing.Agreement,
    component: Agreement,
    isPrivateRoute: false,
  },
  {
    id: 108,
    path: Routing.SuccessAgreement,
    component: SuccessAgreement,
    isPrivateRoute: false,
  },
  {
    id: 109,
    path: Routing.OwnerDashBoard,
    component: OwnerDashBoard,
    isPrivateRoute: true,
  },
  {
    id: 110,
    path: Routing.Popular_Places,
    component: Popular_Places,
    isPrivateRoute: true,
  },
  {
    id: 111,
    path: Routing.NewPopular_Places,
    component: NewPopular_Places,
    isPrivateRoute: true,
  },
  {
    id: 112,
    path: Routing.EditPopular_Places,
    component: EditPopular_Places,
    isPrivateRoute: true,
  },
  {
    id: 113,
    path: Routing.Destination,
    component: Destination,
    isPrivateRoute: true,
  },
  {
    id: 114,
    path: Routing.NewDestination,
    component: NewDestination,
    isPrivateRoute: true,
  },
  {
    id: 115,
    path: Routing.EditDestination,
    component: EditDestination,
    isPrivateRoute: true,
  },
  {
    id: 116,
    path: Routing.RoomType,
    component: RoomType,
    isPrivateRoute: true,
  },
  {
    id: 117,
    path: Routing.CreateRoomType,
    component: CreateRoomType,
    isPrivateRoute: true,
  },
  {
    id: 118,
    path: Routing.RatePlan,
    component: RatePlan,
    isPrivateRoute: true,
  },
  {
    id: 119,
    path: Routing.CreateRatePlan,
    component: CreateRatePlan,
    isPrivateRoute: true,
  },
  {
    id: 120,
    path: Routing.CreateRatesAndAvailability,
    component: CreateRatesAndAvailability,
    isPrivateRoute: true,
  },
  {
    id: 121,
    path: Routing.AdminUsers,
    component: AdminUsers,
    isPrivateRoute: true,
  },
  {
    id: 122,
    path: Routing.AddAdminUsers,
    component: AddAdminUsers,
    isPrivateRoute: true,
  },
  {
    id: 123,
    path: Routing.EditAdminUsers,
    component: EditAdminUsers,
    isPrivateRoute: true,
  },
  {
    id: 124,
    path: Routing.Shift,
    component: Shift,
    isPrivateRoute: true,
  },
  {
    id: 125,
    path: Routing.NewShift,
    component: NewShift,
    isPrivateRoute: true,
  },
  {
    id: 126,
    path: Routing.EditShift,
    component: EditShift,
    isPrivateRoute: true,
  },
  {
    id: 127,
    path: Routing.Chat,
    component: Chat,
    isPrivateRoute: true,
  },
  {
    id: 128,
    path: Routing.DirectBooking,
    component: DirectBooking,
    isPrivateRoute: false,
  },
  {
    id: 129,
    path: Routing.Invoice,
    component: Invoice,
    isPrivateRoute: false,
  },
  {
    id: 130,
    path: Routing.CheckInList,
    component: CheckInList,
    isPrivateRoute: true,
  },
  {
    id: 131,
    path: Routing.CheckOutList,
    component: CheckOutList,
    isPrivateRoute: true,
  },
  {
    id: 132,
    path: Routing.RoomAvailabilityList,
    component: RoomAvailabilityList,
    isPrivateRoute: true,
  },
  {
    id: 133,
    path: Routing.GuestProfileList,
    component: GuestProfileList,
    isPrivateRoute: true,
  },
  {
    id: 134,
    path: Routing.RevenueBySourceList,
    component: RevenueBySourceList,
    isPrivateRoute: true,
  },
  {
    id: 135,
    path: Routing.RateList,
    component: RateList,
    isPrivateRoute: true,
  },
  {
    id: 136,
    path: Routing.InHouseListOld,
    component: InHouseListOld,
    isPrivateRoute: true,
  },
  {
    id: 137,
    path: Routing.ADRRevparList,
    component: ADRRevparList,
    isPrivateRoute: true,
  },
  {
    id: 138,
    path: Routing.ForecastList,
    component: ForecastList,
    isPrivateRoute: true,
  },
  {
    id: 139,
    path: Routing.HouseKeepingList,
    component: HouseKeepingList,
    isPrivateRoute: true,
  },
  {
    id: 140,
    path: Routing.RevenueByRoomTypesListNew,
    component: RevenueByRoomTypeListNew,
    isPrivateRoute: true,
  },
  {
    id: 141,
    path: Routing.ReservationGuestView,
    component: ReservationGuestView,
    isPrivateRoute: true,
  },
  {
    id: 142,
    path: Routing.DirectCheckIn,
    component: DirectCheckIn,
    isPrivateRoute: false,
  },
  {
    id: 143,
    path: Routing.OtaOverviewNew,
    component: OtaOverview,
    isPrivateRoute: true,
  },
  {
    id: 145,
    path: Routing.NewOccCri,
    component: NewOccCri,
    isPrivateRoute: true,
  },
  {
    id: 146,
    path: Routing.EditOccCri,
    component: EditOccCri,
    isPrivateRoute: true,
  },
  {
    id: 147,
    path: Routing.CheckInNightReport,
    component: CheckInNightReport,
    isPrivateRoute: true,
  },
  {
    id: 147,
    path: Routing.CheckOutNightReport,
    component: CheckOutNightReport,
    isPrivateRoute: true,
  },
  {
    id: 148,
    path: Routing.FlashNightList,
    component: FlashNightReport,
    isPrivateRoute: true,
  },
  {
    id: 149,
    path: Routing.NewSpeCri,
    component: NewSpeCri,
    isPrivateRoute: true,
  },
  {
    id: 150,
    path: Routing.EditSpeCri,
    component: EditSpeCri,
    isPrivateRoute: true,
  },
  {
    id: 151,
    path: Routing.NewMinStayCri,
    component: NewMinStayCri,
    isPrivateRoute: true,
  },
  {
    id: 152,
    path: Routing.EditMinStayCri,
    component: EditMinStayCri,
    isPrivateRoute: true,
  },
  {
    id: 153,
    path: Routing.NewBookingEngine,
    component: NewBookingEngine,
    isPrivateRoute: true,
  },
  {
    id: 154,
    path: Routing.EditBookingEngine,
    component: EditBookingEngine,
    isPrivateRoute: true,
  },
  {
    id: 155,
    path: Routing.EditPOSAddOn,
    component: EditPOSAddOn,
    isPrivateRoute: true,
  },
  {
    id: 156,
    path: Routing.NewPOSAddOn,
    component: AddPOSAddOn,
    isPrivateRoute: true,
  },
  {
    id: 157,
    path: Routing.NonAssignedRooms,
    component: NonAssignedRooms,
    isPrivateRoute: true,
  },
  {
    id: 158,
    path: Routing.VisitorSalesList,
    component: VisitorSalesList,
    isPrivateRoute: true,
  },
  {
    id: 159,
    path: Routing.VisitorViewSales,
    component: VisitorViewSales,
    isPrivateRoute: true,
  },
  {
    id: 160,
    path: Routing.KitchenDashboard,
    component: KitchenDashboard,
    isPrivateRoute: true,
  },
  {
    id: 161,
    path: Routing.NewCouponCode,
    component: NewCouponCode,
    isPrivateRoute: true,
  },
  {
    id: 162,
    path: Routing.EditCouponCode,
    component: EditCouponCode,
    isPrivateRoute: true,
  },
  {
    id: 163,
    path: Routing.SuccessBoking,
    component: SuccessBoking,
    isPrivateRoute: false,
  },
  {
    id: 164,
    path: Routing.Owners,
    component: Owners,
    isPrivateRoute: true,
  },
  {
    id: 165,
    path: Routing.NewOwners,
    component: NewOwners,
    isPrivateRoute: true,
  },
  {
    id: 166,
    path: Routing.EditOwners,
    component: EditOwners,
    isPrivateRoute: true,
  },

  {
    id: 167,
    path: Routing.OwnerLogin,
    component: OwnerLogin,
    isPrivateRoute: false,
  },
  {
    id: 167,
    path: Routing.OwnerReport,
    component: OwnerReport,
    isPrivateRoute: true,
  },
  {
    id: 168,
    path: Routing.DirectChat,
    component: DirectChat,
    isPrivateRoute: false,
  },
  {
    id: 169,
    path: Routing.HotelWiseOwnerReport,
    component: HotelWiseOwnerReport,
    isPrivateRoute: true,
  },
  {
    id: 169,
    path: Routing.OwnerIframeReport,
    component: OwnerIframeReport,
    isPrivateRoute: true,
  },
  {
    id: 170,
    path: Routing.EditEmailTemplate,
    component: EditEmailTemplate,
    isPrivateRoute: true,
  },
  {
    id: 171,
    path: Routing.EditSmsTemplate,
    component: EditSmsTemplate,
    isPrivateRoute: true,
  },
  {
    id: 172,
    path: Routing.DirectBookingNew,
    component: DirectBookingNew,
    isPrivateRoute: false,
  },
  {
    id: 173,
    path: Routing.HouseKeepingNew,
    component: HouseKeepingNew,
    isPrivateRoute: true,
  },
  {
    id: 174,
    path: Routing.HouseKeeper,
    component: HouseKeeper,
    isPrivateRoute: true,
  },
  {
    id: 175,
    path: Routing.HouseKeeperCleanRoom,
    component: HouseKeeperCleanRoom,
    isPrivateRoute: true,
  },
  {
    id: 176,
    path: Routing.Pos,
    component: TraditionalPos,
    isPrivateRoute: true,
  },
  {
    id: 177,
    path: Routing.TraditionalSellInvoice,
    component: TraditionalSellInvoice,
    isPrivateRoute: true,
  },
  {
    id: 178,
    path: Routing.POSReports,
    component: POSReports,
    isPrivateRoute: true,
  },
  {
    id: 179,
    path: Routing.NewPoOrder,
    component: AddPoOrder,
    isPrivateRoute: true,
  },
  {
    id: 180,
    path: Routing.EditPoOrder,
    component: EditPoOrder,
    isPrivateRoute: true,
  },
];

export const AppRouting: React.FC = () => {
  React.useEffect(() => {
    const welcomeImage = document.getElementById("welcome-image");
    if (welcomeImage) welcomeImage.style.display = "block";
  }, []);
  return (
    <Router >
      <ToastContainer key={1} className="toaster" theme="dark" />
      {/* <ToastContainer limit={3} className="toaster" hideProgressBar={true} /> */}

      <Routes>
        {routes
          // .filter((route) => !route.isPrivateRoute)
          .map((route, index) => {
            return (
              <React.Fragment key={route.id}>
                {route?.isPrivateRoute ? (
                  <Route
                    path={route.path}
                    element={( route.path === Routing.HouseKeeper ||
                      route.path === Routing.HouseKeeperCleanRoom ) ?
                      <Suspense fallback={<DirectLoader colors={"#384850"} />}>
                        <route.component />
                      </Suspense>
                      :
                      <MainLayout>
                        <route.component />
                      </MainLayout>
                    }
                  />
                ) : (
                  <Route
                    path={route.path}
                    element={
                      // route.path === `/` ||
                      route.path === `/agreement/:BookingId` ||
                      route.path === `/direct-booking/:hotel_id` ||
                      route.path === `/guest-checkin/:hotel_id` ||
                      route.path === Routing.DirectChat ||
                      route.path === Routing.SuccessAgreement ||
                      
                      route.path === Routing.DirectBookingNew ||
                      route.path === Routing.SuccessBoking ||
                      route.path === `/create-invoice/:invoice_id` ? (
                      
                        <Suspense fallback={ (route.path === `/agreement/:BookingId` ||
                      route.path === `/direct-booking/:hotel_id`)?
                      <DirectLoader colors={"#384850"} />:<Spinner />}>  
                        <route.component />
                       {(route.path === `/direct-booking/:hotel_id` ) &&  (
                       <noscript>
                          <iframe title="google-tag" src="https://www.googletagmanager.com/ns.html?id=GTM-W7X8BRXK"
                          height="0" width="0" style={{display:'none',visibility:'hidden'}}></iframe>
                        </noscript>)}
                        </Suspense>
                               
                      ) : (
                        <PublicLayout>
                          {" "}
                          <route.component />{" "}
                        </PublicLayout>
                      )
                    }
                  />
                )}
              </React.Fragment>
            );
          })}
        {/* <Route path="*" element={<PublicLayout> <NoteExit404 /> </PublicLayout>} /> */}
      </Routes>
      {/* 
  <Routes>
    <Route path="/print" element={<PrintAgreement />} />
  </Routes> */}
      {/* <Routes>
        {routes
          .filter((route) => route.isPrivateRoute)
          .map((route, index) => {
            return (
              <Route
                key={route?.path}
                path={route.path}
                element={
                  <MainLayout>
                    <route.component />
                  </MainLayout>
                }
              />
            );
          })}

      </Routes> */}
      {/* <Route path="*" element={<NoteExit404 />} /> */}

      {/* <Redirect to={{ pathname: Routing.Login }} /> */}
      {/* <Route>404 Not Found</Route> */}
    </Router>
  );
};
