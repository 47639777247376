import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import bookingSlice from "./bookingSlice";
import dataSlice from "./dataSlice";
import formDataSlice from "./formData";
import hotelSlice from "./hotelSlice";
import posSlice from "./posSlice";
import reportSlice from "./reportSlice";
import roomCategory from "./roomCategory";
import roomRateSlice from "./roomRateSlice";
import roomSlice from "./roomSlice";
import otaSlice from "./otaSlice";
import FilterSlice from "./FilterSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import ownerReportSlice from "./ownerReportSlice";

// export default configureStore({
//   reducer: {
//     filters: FilterSlice,
//     auth: authSlice,
//     data: dataSlice,
//     formData: formDataSlice,
//     hotel: hotelSlice,
//     roomCategory: roomCategory,
//     room: roomSlice,
//     roomRate: roomRateSlice,
//     booking: bookingSlice,
//     pos: posSlice,
//     report: reportSlice,
//     ota: otaSlice,
//   },
// });

const reducers = combineReducers({
  filters: FilterSlice,
  auth: authSlice,
  data: dataSlice,
  formData: formDataSlice,
  hotel: hotelSlice,
  roomCategory: roomCategory,
  room: roomSlice,
  roomRate: roomRateSlice,
  booking: bookingSlice,
  pos: posSlice,
  report: reportSlice,
  ota: otaSlice,
  ownerReport: ownerReportSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['filters']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

// const reducers = combineReducers({
//   filters: persistReducer(filtersPersistConfig, FilterSlice),
//   auth: authSlice,
//   data: dataSlice,
//   formData: formDataSlice,
//   hotel: hotelSlice,
//   roomCategory: roomCategory,
//   room: roomSlice,
//   roomRate: roomRateSlice,
//   booking: bookingSlice,
//   pos: posSlice,
//   report: reportSlice,
//   ota: otaSlice,
// });

// const persistedReducer = persistReducer(rootPersistConfig, reducers);

// // const persistedReducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: persistedReducer,
//   // devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk],
// });

export default store;
