import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categoryList: [],
  categoryDetail: null,
  bedList: [],
  roomMasterTypes: [],
  mattressList: [],
  facilitySubFacilityList: [],
  subFacilityList: [],
  roomCategoryTypes: [],
};
const roomCategorySlice = createSlice({
  name: "hotel",
  initialState: initialState,
  reducers: {
    roomCategoryListSuccess: (state, action) => {
      state.categoryList = action.payload;
    },
    roomCategoryListFailed: (state, action) => {
      state.categoryList = [];
    },
    roomCategoryDetailSuccess: (state, action) => {
      state.categoryDetail = action.payload;
    },
    roomCategoryDetailFailed: (state, action) => {
      state.categoryDetail = null;
    },
    bedListSuccess: (state, action) => {
      state.bedList = action.payload;
    },
    bedListFailed: (state, action) => {
      state.bedList = null;
    },
    roomMasterTypesSuccess: (state, action) => {
      state.roomMasterTypes = action.payload;
    },
    roomMasterTypesFailed: (state, action) => {
      state.roomMasterTypes = null;
    },
    mattressListSuccess: (state, action) => {
      state.mattressList = action.payload;
    },
    mattressListFailed: (state, action) => {
      state.mattressList = null;
    },
    facilitySubFacilityListSuccess: (state, action) => {
      state.facilitySubFacilityList = action.payload;
    },
    facilitySubFacilityListFailed: (state, action) => {
      state.facilitySubFacilityList = [];
    },
    subFacilityListSuccess: (state, action) => {
      state.subFacilityList = action.payload;
    },
    subFacilityListFailed: (state, action) => {
      state.facilitySubFacilityList = [];
    },
    roomCategoryTypesSuccess: (state, action) => {
      state.roomCategoryTypes = action.payload;
    },
    roomCategoryTypesFailed: (state, action) => {
      state.roomCategoryTypes = [];
    },
  },
});

export const {
  roomCategoryListSuccess,
  roomCategoryListFailed,
  roomCategoryDetailSuccess,
  roomCategoryDetailFailed,
  bedListSuccess,
  bedListFailed,
  roomMasterTypesSuccess,
  roomMasterTypesFailed,
  mattressListSuccess,
  mattressListFailed,
  facilitySubFacilityListSuccess,
  facilitySubFacilityListFailed,
  roomCategoryTypesSuccess,
  roomCategoryTypesFailed,
  subFacilityListSuccess,
  subFacilityListFailed,
} = roomCategorySlice.actions;
export default roomCategorySlice.reducer;
