import React from 'react'
import "./noteExist.css";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotExist = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div style={{
            margin: "0",
            padding: "0",
            textAlign: "center"
        }}>
            <div className="section">
                <h1 className="error">403</h1>
                <div className="page">{t("Ooops!!! The page you are looking for is Forbidden")}</div>
                <button onClick={() => navigate(-1)} className="back-home">{t("Back")}</button>
            </div>
        </div>
    )
}

export default NotExist