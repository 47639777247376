import { Button, Checkbox, Grid, InputLabel, Stack, Switch, Typography } from "@mui/material";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import FormikInput from "../../shared/material-ui-formik/FormikInput";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { useTranslation } from "react-i18next";
import { EditCancellationPolicyValidator } from "../../shared/validations/AdminValidator";
import { getRecord, updateRecord } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { toast } from "react-toastify";
import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
import ShowInputError from "../../shared/components/ShowInputError";
import Loader from "../../layout/Loader";
import { refreshPolicySuccess } from "../../Reducer/roomSlice";
import { useDispatch } from "react-redux";

const EditCancellation = (props) => {
  const { t, i18n } = useTranslation();
  const criteriasObj = {
    time_period: 1,
    time_period_value: 0,
    refund_type:1,
    refund_type_value:0,
  };
  const [initialState, setInitialState] = useState({
    name: "",
    description: "",
    cancellation_policy_criterias: [criteriasObj]
  });

  const [loading, setLoading] = useState(false);
  const [cancellationCri, setCancellationCri] = useState(false);


  const getPolicyDetail = useCallback(
    async (rateId) => {
      setLoading(true);
      const result = await getRecord(
        props?.id,
        ApiEndPoints.GET_CANCELLATION_POLICY
      );
      if (result?.status === 200) {
        if(result?.data?.policyCriteria?.length > 0){
          setCancellationCri(true);
        }
        setInitialState({
          name: result?.data?.name,
          description: result?.data?.description,
          cancellation_policy_criterias: result?.data?.policyCriteria?.length > 0 ? result?.data?.policyCriteria?.map((item)=>({
            time_period:item?.time_period,
            time_period_value:item?.time_period_value,
            refund_type:item?.refund_type,
            refund_type_value:item?.refund_type_value,
          })) : [criteriasObj],
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [props?.id]
  );

  useEffect(() => {
    if (props?.id) {
      getPolicyDetail(props?.id);
    }
  }, [props?.id]);

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);
    let bodyFormData = {
      name: values?.name,
      description: values?.description,
      cancellation_policy_criterias: cancellationCri? values?.cancellation_policy_criterias : [],
    };
    const result = await updateRecord(
      bodyFormData,
      props?.id,
      ApiEndPoints.GET_CANCELLATION_POLICY
    );
    if (result?.status === 200) {
      toast.success(t(result.message));
      props?.handleCloseDialog();
      dispatch(refreshPolicySuccess());
      setLoading(false);
    } else {
      toast.error(t(result.message));
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="p-2">
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={()=>EditCancellationPolicyValidator(t)}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <Field
                      label={t("Name")}
                      name="name"
                      id="name"
                      component={FormikInput1}
                    />
                    <ErrorMessage name="name" component={ShowInputError} />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Field
                      label={t("Description")}
                      name="description"
                      id="description"
                      type="textarea"
                      component={FormikInput1}
                      multiline
                      borderNone
                      noMaxLength
                      rows={4}
                    />
                    <ErrorMessage
                      name="description"
                      component={ShowInputError}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      <Typography
                      id="font-600"
                        style={{
                          marginBottom: "11px",
                          color: "rgb(30,30,30)",
                          fontSize: "16px",
                        }}
                      >
                        {t("Cancellation Criteria")}
                      </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Switch
                  // {...label}
                  checked={cancellationCri}
                  onChange={(e)=>e.target.checked?setCancellationCri(true):setCancellationCri(false)}
                />
                <Typography>
                  {cancellationCri ? t("Active") : t("Not Active (100% Refund)")}
                </Typography>
              </Stack>
            </Grid>
                  {cancellationCri && (<FieldArray name="cancellation_policy_criterias">
                      {({push, remove}) => (
                        <>
                        {
                          props?.values?.cancellation_policy_criterias?.map((_, index)=>(
                            <React.Fragment key={index}>
                              {index !== 0 && (
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginRight: "20px",
                                        marginBottom: "20px",
                                        borderTop: "2px solid",
                                        marginTop: "20px",
                                      }}
                                    ></div>
                                  </Grid>
                                )}
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                     <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Time Period")}
                                    </Typography>
                                    <Field
                                    label={t("Time Period")}
                                    name={`cancellation_policy_criterias.${index}.time_period`}
                                    id={`cancellation_policy_criterias.${index}.time_period`}
                                    component={FormikSelect}
                                    options={[
                                      {
                                      title: t("Hours"),
                                      value: 1,
                                    },
                                      {
                                      title: t("Days"),
                                      value: 2,
                                    },
                                  ]}
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.time_period`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                    <Field
                                    label={props?.values?.cancellation_policy_criterias[index]?.time_period === 1 ? t("Hours") : t("Days")}  
                                    name={`cancellation_policy_criterias.${index}.time_period_value`}
                                    id={`cancellation_policy_criterias.${index}.time_period_value`}
                                    component={FormikInput1}
                                    type="number"
                                  
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.time_period_value`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                     <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Refund Type")}
                                    </Typography>
                                    <Field
                                    label={t("Refund Type")}
                                    name={`cancellation_policy_criterias.${index}.refund_type`}
                                    id={`cancellation_policy_criterias.${index}.refund_type`}
                                    component={FormikSelect}
                                    options={[
                                      {
                                      title: t("In Percentage"),
                                      value: 1,
                                    },
                                      {
                                      title: t("By Nights"),
                                      value: 2,
                                    },
                                  ]}
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.refund_type`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                  <Grid
                                  item
                                  lg={6} xs={12} md={6}>
                                    <Field
                                    label={props?.values?.cancellation_policy_criterias[index]?.refund_type === 1 ? t("Refund Value(In Percentage)") : t("Refund Value(By Nights)")}
                                    name={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    id={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    component={FormikInput1}
                                    type="number"
                                  />
                                  <ErrorMessage
                                    name={`cancellation_policy_criterias.${index}.refund_type_value`}
                                    component={ShowInputError}
                                  />
                                  </Grid>
                                  <Grid item lg={12} xs={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      marginRight: "20px",
                                      // marginBottom: "20px",
                                      // borderTop: "2px solid",
                                      // marginTop: "20px",
                                    }}
                                  >
                                    {index ===
                                      props?.values?.cancellation_policy_criterias?.length - 1 && (
                                      <Button
                                        className="mt-1 comman-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => push(criteriasObj)}
                                        color="primary"
                                      >
                                        {t("Add")}
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        className="mt-1 ml-1 comman-cancel-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => remove(index)}
                                        color="primary"
                                      >
                                        {t("Remove")}
                                      </Button>
                                    )}
                                  </div>
                                </Grid>
                            </React.Fragment>
                          ))
                        }
                        </>
                      )}
                      </FieldArray>)}
                  <Grid item xs={12} lg={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginRight: "20px",
                        marginBottom: "20px",
                        borderTop: "2px solid",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        className="mt-1 comman-button"
                        size="large"
                        variant="contained"
                        disableElevation
                        type="submit"
                        color="primary"
                      >
                        {t("Save")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            );
          }}
          {/* ( <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel>{t("Name")}</InputLabel>
            <Field
              // label="Email"
              type="email"
              name="userName"
              id="userName"
              maxLength={150}
              component={FormikInput}
              // onClick={() => dispatch(setProps({"name":"nskn"}))}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel>{t("Prepayment Option")}</InputLabel>
            <Field
              // label="Email"
              type="email"
              name="userName"
              id="userName"
              maxLength={150}
              component={FormikSelect}
              // onClick={() => dispatch(setProps({"name":"nskn"}))}
            />
          </Grid>
          <Grid item xs={6}>
            <Checkbox />
            <InputLabel>
              {t(
                "Is There A period when the guest Can cancel A Reservation  without any charge ?"
              )}
            </InputLabel>
          </Grid>
        </Grid>) */}
        </Formik>
      </div>
    </>
  );
};

export default EditCancellation;
