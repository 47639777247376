import instance from "../ApiConfig";
import { ApiEndPoints } from "../ApiEndPoints";

export const createRoomCategory = async (data: object) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_CATEGORY_RESOURCE_ROUTE,
      method: "POST",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const updateRoomCategory = async (data: object, roomCatId: number) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_CATEGORY_RESOURCE_ROUTE + `/${roomCatId}`,
      method: "PUT",
      data,
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomCategoryList = async (pageNo, itemsPerPage) => {
  try {
    const result = await instance({
      url:
        ApiEndPoints.ROOM_CATEGORY_RESOURCE_ROUTE +
        `?page_no=${pageNo}&items_per_page=${itemsPerPage}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteRoomCategoryApiCall = async (roomCatId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_CATEGORY_RESOURCE_ROUTE + `/${roomCatId}`,
      method: "DELETE",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomCategoryDetail = async (roomCatId) => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_CATEGORY_RESOURCE_ROUTE + `/${roomCatId}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBedList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.BED_LIST,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomMasterTypes = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_MASTER_TYPES,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const fetchMatressList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.MATRESS_LIST,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFacilitySubFacilityList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.FACILITY_SUB_FACILITY_LIST,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSubFacilityListByType = async (type) => {
  try {
    const result = await instance({
      url: ApiEndPoints.SUB_FACILITY_LIST + `/${type}`,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const fetchRoomCategorySelectList = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.ROOM_CATEGORY_SELECT_LIST,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const fetchRatesForBooking = async () => {
  try {
    const result = await instance({
      url: ApiEndPoints.GET_RATE_FOR_BOOKING,
      method: "GET",
    });
    // return result;
    return result.data;
  } catch (error) {
    throw error;
  }
};
